<template>
  <div>
    <div class="display-title-page">
      <div class="ml-2">
        <div class="title mb-1">Listagem de manutenções</div>
        <div class="caption mb-3">
          Listagem com todas as manutenções independente do status dela
        </div>
      </div>
      <div class="mr-2">
        <v-btn
          raised
          color="primary"
          class="ml-1"
          @click="$refs.drawerManCriar.show()"
          ><v-icon left>mdi-plus</v-icon>Gerar O.S.</v-btn
        >
      </div>
    </div>

    <TabelaManutencao ref="tabelaManutencao" class="mx-1 mt-2" />
    <NavigationDrawerManutencaoCriar
      ref="drawerManCriar"
      @reload="$refs.tabelaManutencao.getItens()"
    />
  </div>
</template>
<script lang="ts">
import NavigationDrawerManutencaoCriar from "../navigationDrawer/NavigationDrawerManutencaoCriar.vue";
import TabelaManutencao from "../tables/TabelaManutencao.vue";

export default {
  name: "IziManTabManutencaoTabela",
  title: "Tabela de manutenções",
  components: { TabelaManutencao, NavigationDrawerManutencaoCriar },
  data() {
    return {
      tabs: 0,
    };
  },
};
</script>
