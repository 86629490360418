<template>
  <v-snackbar :color="color" :timeout="timer" v-model="showSnackbar" bottom>
    <v-icon left>{{ icon }}</v-icon>{{ message }}
  </v-snackbar>
</template>

<script>
export default {
  name: "IziManGlobalMessage",
  data() {
    return {
      showSnackbar: false,
      message: '',
      color: 'success',
      icon: 'mdi-check',
      timer: 3000
    }
  },
  methods: {
    show(data) {
      this.message = data.message || 'missing "message".'
      this.color = data.color || 'success'
      this.timer = data.timer || 3000
      this.icon = data.icon || 'mdi-check'
      this.showSnackbar = true
    },

    success(message) {
      this.message = message
      this.color = 'success'
      this.timer = 3000
      this.icon = 'mdi-check'
      this.showSnackbar = true
    },

    error(message) {
      this.message = message
      this.color = 'red'
      this.timer = 3000
      this.icon = 'mdi-close-octagon'
      this.showSnackbar = true
    },

    info(message) {
      this.message = message
      this.color = 'blue'
      this.timer = 3000
      this.icon = 'mdi-information'
      this.showSnackbar = true
    },

    warning(message) {
      this.message = message
      this.color = 'orange'
      this.timer = 3000
      this.icon = 'mdi-alert'
      this.showSnackbar = true
    }
  }
}
</script>