<template>
  <div>
    <div class="display-title-page">
      <div class="ml-2">
        <div class="title mb-1">Livro caixa</div>
        <div class="caption mb-3">
          Listagem com todas as entradas e saidas do sistema, sejam elas
          oriundas de manutenções ou não
        </div>
      </div>
      <div class="mr-2">
        <v-btn
          raised
          color="primary"
          class="ml-1"
          @click="$refs.navigationDrawerLancamento.show()"
          ><v-icon left>mdi-plus</v-icon>Inserir registro</v-btn
        >
      </div>
    </div>

    <v-row no-gutters>
      <v-col class="my-1" cols="12" sm="12">
        <TabelaLivroCaixa ref="tabelaLivroCaixa" />
      </v-col>
    </v-row>

    <NavigationDrawerLancamento
      ref="navigationDrawerLancamento"
      @salvo="$refs.tabelaLivroCaixa.reset()"
    />
  </div>
</template>

<script>
import NavigationDrawerLancamento from "./components/navigationDrawer/NavigationDrawerLancamento.vue";
import TabelaLivroCaixa from "./components/tables/TabelaLivroCaixa.vue";

export default {
  name: "IziManLivroCaixa",
  title: "Livro caixa",
  data() {
    return {
      list: [],
    };
  },
  methods: {},
  components: { TabelaLivroCaixa, NavigationDrawerLancamento },
};
</script>
