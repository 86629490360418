<template>
  <div>
    
  </div>
</template>

<script lang="ts">
export default {
  name: 'IziManRelatorio',
  data() {
    return {
      
    }
  },
}
</script>
