import { render, staticRenderFns } from "./NavigationDrawerCobranca.vue?vue&type=template&id=3232b3a0&scoped=true&"
import script from "./NavigationDrawerCobranca.vue?vue&type=script&lang=ts&"
export * from "./NavigationDrawerCobranca.vue?vue&type=script&lang=ts&"
import style0 from "./NavigationDrawerCobranca.vue?vue&type=style&index=0&id=3232b3a0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3232b3a0",
  null
  
)

export default component.exports