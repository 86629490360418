<template>
  <v-navigation-drawer v-model="drawer" @input="onClose" right app temporary width="600" class="pa-4">
    <p class="title" @click="close()">
      Criação de ordem de serviço
      <v-btn icon class="float-right">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </p>
    <v-divider class="mb-4"></v-divider>
    <v-stepper v-model="steps" vertical>
      <v-stepper-step :complete="steps > 1" step="1" :rules="[() => true]">
        Informe o tipo de serviço
        <small v-if="false">Alert message</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <FormularioTipoServico ref="formularioTipoServico" @next="this.next" @before="this.close" />
      </v-stepper-content>

      <v-stepper-step :complete="steps > 2" step="2">
        Informe os dados basicos de uma OS
      </v-stepper-step>

      <v-stepper-content step="2">
        <FormularioCliente :service_type="form.fields.service_type" ref="formularioCliente" @next="this.next"
          @before="this.before" />
      </v-stepper-content>

      <Notificacao ref="notificacao" />
    </v-stepper>
  </v-navigation-drawer>
</template>

<script lang="ts">
import FormularioCliente from "../forms/FormularioCliente.vue";
import FormularioTipoServico from "../forms/FormularioTipoServico.vue";
import Notificacao from "../../../../components/Notificacao.vue";
import  { api } from "../../../../api";

export default {
  name: "IziManNavigationDrawerManutencaoCriar",
  components: { FormularioTipoServico, FormularioCliente, Notificacao },
  emits: ["reload"],
  data() {
    return {
      steps: 1,
      drawer: false,
      form: {
        fields: {
          ref_os: null,
          priority: null,
          service_type: null,
          client: null,
          equipment: null,
          issue_reported: null,
        },
      },
    };
  },
  methods: {
    show() {
      this.drawer = true;
    },

    close() {
      this.drawer = false;
    },

    onClose(event) {
      if (!event) {
        this.$refs.formularioTipoServico.$refs.form.reset();
        this.$refs.formularioCliente.$refs.form.reset();
        this.$refs.formularioCliente.resetarFormulario();
        this.steps = 1;
      }
    },

    async next(fields) {
      this.form.fields = Object.assign(this.form.fields, fields);
      if (this.steps === 2) {
        try {
          this.loading = true;
          const response = await api.post("/maintenance", this.form.fields);
          if (response.data) {
            this.$root.GlobalMessage.success("Operação realizada com sucesso!");
            this.$emit("reload");
            this.close();
          } else
            this.$root.GlobalMessage.error(
              "Erro ao cadastrar criar manutenção!"
            );

          this.loading = false;
        } catch (err) {
          this.$root.GlobalMessage.error(
            "Erro ao realizar requisição, entre em contato com o administrador!"
          );
          this.loading = false;
        }
      }

      if (this.steps !== 2) this.steps++;
    },
    before() {
      this.steps--;
    },
  },
};
</script>

<style scoped>
.v-stepper {
  box-shadow: none !important;
}
</style>
