<template>
  <div>
    <v-form ref="formAutoCompleteUsuario" v-model="valid"> </v-form>
  </div>
</template>

<script lang="ts">
export default {
  name: "IziManFormularioFiltroRelatorioManutencao",
  data() {
    return {
      valid: true,
      form: {
        fields: {},
        rules: {},
      },
    };
  },
};
</script>
