<template>
  <div>
    <v-form ref="form" v-model="valid">
      <AutoCompletePeca
        ref="autoCompletePeca"
        @change="form.fields.item = $event"
      />

      <TabelaPecas
        ref="tabelaPeca"
        :pecas="form.fields.item"
        @plus="this.plus"
        @minus="this.minus"
      />

      <v-textarea
        clearable
        outlined
        name="input-7-4"
        label="Informe o problema encontrado"
        v-model="form.fields.issue_found"
        :rules="form.rules.issue_foundRules"
      >
      </v-textarea>

      <v-chip-group active-class="primary--text" class="mb-4" column>
        <v-chip
          v-for="problema in problemas_encontrado"
          :key="problema.name"
          @click="form.fields.issue_found = problema.content"
        >
          {{ problema.name }}
        </v-chip>
      </v-chip-group>

      <v-textarea
        clearable
        outlined
        name="input-7-4"
        label="Atividades realizadas"
        v-model="form.fields.executed_activity"
        :rules="form.rules.executed_activityRules"
      >
      </v-textarea>

      <v-chip-group active-class="primary--text" class="mb-4" column>
        <v-chip
          v-for="problema in atividades_realizada"
          :key="problema.name"
          @click="form.fields.executed_activity = problema.content"
        >
          {{ problema.name }}
        </v-chip>
      </v-chip-group>

      <v-btn color="primary" outlined @click="this.next" :show-current="false">
        Avançar
      </v-btn>
    </v-form>
  </div>
</template>

<script lang="ts">
import { api } from "../../../../api";
import { EnumTipoAuxiliarFiltro } from "../../../../enums/TipoAuxiliares";
import AutoCompletePeca from "@/pages/peca/components/autoComplete/AutoCompletePeca.vue";
import TabelaPecas from "../tables/TabelaPecas.vue";

export default {
  name: "ResFormularioExecucao",
  components: { AutoCompletePeca, TabelaPecas },
  data() {
    return {
      valid: true,
      problemas_encontrado: [],
      atividades_realizada: [],
      form: {
        fields: {
          item: null,
          issue_found: null,
          executed_activity: null,
        },
        rules: {
          issue_foundRules: [
            (v) =>
              !!v ||
              "Necessário preencher o campo de informar o problema encontrado",
          ],
          executed_activityRules: [
            (v) =>
              !!v || "Necessário preencher o campo de atividades realizadas",
          ],
        },
      },
    };
  },
  methods: {
    changeItem() {},

    next() {
      if (this.$refs.form.validate()) {
        this.$emit("next", this.form.fields);
      }
    },

    plus(item) {
      this.form.fields.item.find((x) => item._id == x._id).quantity += 1;
      this.form.fields.item = Object.assign([], this.form.fields.item);
    },

    minus(item) {
      this.form.fields.item.find((x) => item._id == x._id).quantity -= 1;

      if (this.form.fields.item.find((x) => item._id == x._id).quantity === 0)
        this.form.fields.item.splice(
          this.form.fields.item.findIndex((x) => item._id == x._id),
          1
        );

      this.form.fields.item = Object.assign([], this.form.fields.item);
    },
    async carregaAuxiliares() {
      const response = await api.get("/helper");

      if (response.data) {
        this.problemas_encontrado = response.data
          .filter(
            (auxiliar) =>
              auxiliar.type == EnumTipoAuxiliarFiltro.PROBLEMA_ENCONTRADO
          )
          .map((auxiliar) => ({
            name: auxiliar.name,
            content: auxiliar.content,
          }));

        this.atividades_realizada = response.data
          .filter(
            (auxiliar) =>
              auxiliar.type == EnumTipoAuxiliarFiltro.ATIVIDADES_REALIZADAS
          )
          .map((auxiliar) => ({
            name: auxiliar.name,
            content: auxiliar.content,
          }));
      }
    },
  },
  beforeMount() {
    this.carregaAuxiliares();
  },
};
</script>
