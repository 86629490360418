<template>
  <div>
    <v-card
      :hover="true"
      :disabled="manutencao.cancelled"
      v-if="manutencao"
      :class="[manutencao.step, manutencao.cancelled ? 'CANCELADO' : '']"
      @click="onClick"
    >
      <div class="ml-4 pt-2 bloco-identificacao">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              v-if="manutencao.priority"
              color="red"
              >mdi-clipboard-alert</v-icon
            >
          </template>
          Está O.S. tem prioridade
        </v-tooltip>
        <span class="text-overline">
          {{ manutencao.number_os_format }}
        </span>
      </div>

      <div class="pb-2 mx-4 pt-0 pb-0 text-truncate">
        <span class="text-truncate">
          {{ manutencao.client.fantasy_name }}
        </span>
      </div>
      <v-divider
        class="mb-2"
        v-if="manutencao?.responsables?.length"
      ></v-divider>
      <v-card-text class="pt-0" v-if="manutencao?.responsables?.length">
        <p class="mb-1 mt-2 text-truncate" v-if="manutencao?.responsables?.length">
          <b>Responsável:</b>
          {{ manutencao.responsables[0].name }}
        </p>
        <p class="mt-2 mb-0">
          <span v-if="Boolean(manutencao?.expected_maintenance_date)">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  left
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  v-if="isLate(manutencao)"
                  class="mr-2"
                  >mdi-alert-circle</v-icon
                >
              </template>
              <span>Ordem de serviço esta atrasada</span>
            </v-tooltip>

            <b>Data Prevista Manuteção:</b>
            {{
              $moment(manutencao.expected_maintenance_date).format(
                "DD/MM/YYYY hh:mm"
              )
            }}
          </span>
        </p>
      </v-card-text>
    </v-card>
    <NavigationDrawerManutencaoDetalhe
      ref="drawerManDetalhe"
      @reload="$emit('reload')"
    />
  </div>
</template>

<script lang="ts">
import moment from "moment-timezone";
import EnumStep from "../../../../enums/StepManutencao";
import NavigationDrawerManutencaoDetalhe from "@/pages/manutencao/components/navigationDrawer/NavigationDrawerManutencaoDetalhe.vue";

export default {
  name: "IziManCardManutencaoSimplificado",
  components: {
    NavigationDrawerManutencaoDetalhe,
  },
  emits: ["reload"],
  props: ["manutencao", "detalhado"],
  data() {
    return {
      EnumStep,
    };
  },
  methods: {
    isLate(manutencao) {
      const executouServico = Boolean(manutencao?.executed_activity);
      const atrasado = moment().isAfter(manutencao?.expected_maintenance_date);
      if (!executouServico && atrasado) return true;
      return false;
    },
    onClick() {
      if (
        this.manutencao.maintenanceImpeditive?.filter((x) => x.active).length
      ) {
        this.$root.GlobalMessage.warning(
          "Essa O.S. possui bloqueios e por conta disso não será possivel dar continuidade a mesma"
        );
        return;
      }
      this.$refs.drawerManDetalhe.show(this.manutencao._id);
    },

    cancelShow() {
      return true;
    },
  },
};
</script>

<style scoped>
.FINALIZADO {
  border-left: 2px solid #607d8b !important;
}

.COBRANÇA {
  border-left: 2px solid #3f51b5 !important;
}

.PAGAR {
  border-left: 2px solid #cddc39 !important;
}

.REVISÃO {
  border-left: 2px solid #ffc107 !important;
}

.EXECUÇÃO {
  border-left: 2px solid #00bcd4 !important;
}

.DATA {
  border-left: 2px solid #4caf50 !important;
}

.CANCELADO {
  border-left: 2px solid #f44336 !important;
}
</style>
