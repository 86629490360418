<template>
  <v-form class="pt-2" ref="form" v-model="valid" @submit="salvar()">
    <v-row>
      <v-col class="py-0" cols="12" sm="12" md="8">

        <v-text-field v-model="form.fields.name" :rules="form.rules.nameRules" outlined label="Nome da peça" required>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="12" md="4">
        <v-currency-field v-model="form.fields.value" :rules="form.rules.valueRules" outlined label="Valor" required>
        </v-currency-field>

      </v-col>
      <v-col class="py-0" cols="12">
        <v-btn :loading="loading" :disabled="loading" @click="salvar()" color="primary" outlined>Salvar</v-btn>
      </v-col>
    </v-row>


  </v-form>
</template>

<script>
import { api } from "../../../../api";

export default {
  name: "IziManFormularioGerenciarPeca",
  props: ["id"],
  data() {
    return {
      valid: true,
      loading: false,
      form: {
        fields: {
          name: "",
          value: "",
        },
        rules: {
          nameRules: [(v) => !!v || "Necessário preencher o campo de nome"],
          valueRules: [(v) => !!v || "Necessário preencher o campo de valor"],
        },
      },
    };
  },
  methods: {
    async getItem(id) {
      const response = await api.get(`item/${id}`);

      if (response.data) {
        this.form.fields = response.data;
      } else this.$refs.GlobalMessage.error("Erro ao encontrar item");
    },

    salvar() {
      if (this.$refs.form.validate()) {
        this.$emit("salvar", this.form.fields);
      }
    },
  },

  watch: {
    id(value) {
      if (value) this.getItem(value);
    },
  },
};
</script>
