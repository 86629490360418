<template>
  <div>
    <v-app-bar
      v-if="$localStorage.hasKey('user')"
      :hide-on-scroll="this.$device.mobile"
      app
    >
      <v-app-bar-nav-icon
        class="d-flex"
        @click="$emit('drawer', true)"
      ></v-app-bar-nav-icon>

      <v-app-bar-title>IziMaintenance</v-app-bar-title>

      <v-spacer></v-spacer>

      <div v-if="!this.$device.mobile" class="list-action">
        <v-menu
          offset-y
          min-width="400"
          max-width="450"
          nudge-bottom="8"
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-4" v-bind="attrs" v-on="on">
              <v-badge
                :content="notifications.filter((n) => !n.visualized).length"
                :value="notifications.filter((n) => !n.visualized).length"
                color="blue"
              >
                <v-icon>mdi-bell</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <v-card class="py-2">
            <span class="overline ml-3 mt-3">Notificações</span>
            <v-divider class="mt-2"></v-divider>
            <v-list two-line class="pt-0">
              <v-list-item-group>
                <template v-for="(item, index) in notifications">
                  <v-list-item
                    :key="index"
                    :disabled="item.visualized"
                    @click="visualizaNotificacao(item._id)"
                  >
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.title"
                        ></v-list-item-title>

                        <v-list-item-subtitle
                          class="text--primary"
                          v-html="item.content"
                        ></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text>
                          {{ getTimeDifference(item.created_at) }}
                        </v-list-item-action-text>

                        <v-icon v-if="item.visualized" color="grey lighten-1">
                          mdi-eye
                        </v-icon>

                        <v-icon v-else color="purple darken-3">
                          mdi-eye-off
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider :key="index"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
            <v-btn text block>
              Ver mais
              <v-icon right>mdi-dots-horizontal</v-icon>
            </v-btn>
          </v-card>
        </v-menu>

        <v-menu offset-y min-width="400" nudge-bottom="8">
          <template v-slot:activator="{ on, attrs }">
            <div class="item-avatar mr-4" v-bind="attrs" v-on="on">
              <v-img
                src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745"
                max-height="40"
                max-width="40"
                class="mr-4"
              >
              </v-img>
              <p style="margin: 0">
                {{ $localStorage.get("user").name }} <br />
                <small>{{ $localStorage.get("user").profile.name }}</small>
              </p>
            </div>
          </template>

          <v-card class="pa-3">
            <div class="item-avatar">
              <v-img
                src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745"
                max-height="40"
                max-width="40"
                class="mr-4"
              >
              </v-img>
              <p style="margin: 0">
                {{ $localStorage.get("user").name }} <br />
                <small>{{ $localStorage.get("user").profile.name }}</small>
              </p>
            </div>

            <v-divider class="mt-3"></v-divider>

            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item :to="'/usuario'">
                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Perfil</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-lock</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Resetar senha</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-help</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Ajuda</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-divider class="mt-2 mb-3"></v-divider>

            <v-btn text block color="red" @click="logout()">
              <v-icon left>mdi-logout</v-icon> Logout
            </v-btn>
          </v-card>
        </v-menu>

        <v-btn icon @click="$refs.navigationDrawerConfiguracaoSistema.show()">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </div>
      <template
        v-slot:extension
        v-if="
          $route.path === '/cobrancas' ||
          $route.path === '/manutencao-listagem' ||
          $route.path.startsWith('/cliente-detalhe/')
        "
      >
        <v-tabs
          align-with-title
          @change="selecionouTabCobranca"
          v-if="$route.path === '/cobrancas'"
        >
          <v-tab v-for="tab in tabsCobrancas" :key="tab">{{ tab }}</v-tab>
        </v-tabs>
        <v-tabs
          align-with-title
          @change="selecionouTabManutencao"
          v-if="$route.path === '/manutencao-listagem'"
        >
          <v-tab v-for="tab in tabsManutencoes" :key="tab">{{ tab }}</v-tab>
        </v-tabs>
        <v-tabs
          align-with-title
          @change="selecionouTabCliente"
          v-if="$route.path.startsWith('/cliente-detalhe/')"
        >
          <v-tab v-for="tab in tabsCliente" :key="tab">{{ tab }}</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <NavigationDrawerConfiguracaoSistema
      ref="navigationDrawerConfiguracaoSistema"
    />
  </div>
</template>

<script lang="ts">
import { api } from "../api";
import NavigationDrawerConfiguracaoSistema from "./NavigationDrawerConfiguracaoSistema.vue";
import io from "socket.io-client";
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";

export default {
  name: "IziManBarraSuperior",
  components: { NavigationDrawerConfiguracaoSistema },
  data() {
    return {
      user: null,
      notifications: [],
      tabsCobrancas: [
        "Gerar cobranças",
        "Mensalistas",
        "Cobranças geradas",
        "Tabela de cobranças",
      ],
      tabsManutencoes: ["Manutenções em andamento ", "Tabela de manutenções"],
      tabsCliente: ["Manutenções", "Cobranças"],
      items: [
        {
          action: "15 min atrás",
          headline: "Leonardo Damasceno Augusto",
          subtitle: `Finalizou a manutenção #0002/2023`,
          title: "Manutenção #0002/2023",
        },
        {
          action: "2 hr atrás",
          headline: "Leonardo Damasceno Augusto",
          subtitle: `Finalizou a manutenção #0003/2023`,
          title: "Manutenção #0003/2023",
        },
        {
          action: "2 dias atrás",
          headline: "Leonardo Damasceno Augusto",
          subtitle: "Criou a manutenção #0020/2023",
          title: "Manutenção #0020/2023",
        },
      ],
    };
  },
  methods: {
    async carregaNotificacoesIniciais() {
      const notificacoes = await api.get("/notification");
      this.notifications = notificacoes.data;
    },

    async visualizaNotificacao(id) {
      const response = await api.put(`/notification/${id}/visualize`);

      if (response.status === 200) {
        this.notifications = [];
        this.carregaNotificacoesIniciais();
      }
    },

    selecionouTabCobranca(tab) {
      EventBus.$emit("tab-cobranca-mudou", tab);
    },

    selecionouTabManutencao(tab) {
      EventBus.$emit("tab-manutencao-mudou", tab);
    },

    selecionouTabCliente(tab) {
      EventBus.$emit("tab-cliente-mudou", tab);
    },

    getTimeDifference(created_at) {
      const currentTime = moment();
      const createdAtTime = moment(created_at);
      const duration = moment.duration(currentTime.diff(createdAtTime));

      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();

      let timeDifference = "";
      if (days > 0) {
        timeDifference += `${days}d `;
      }
      if (hours > 0) {
        timeDifference += `${hours}h `;
      }
      if (minutes > 0) {
        timeDifference += `${minutes}m`;
      }

      return timeDifference;
    },

    logout() {
      this.$localStorage.remove("LoggedUser");
      this.$router.push({ name: "Login" });
    },
  },
  created() {
    this.carregaNotificacoesIniciais();

    // Conectando ao servidor WebSocket do backend
    this.socket = io("http://localhost:3000");

    // Recebendo notificações em tempo real
    this.socket.on("newNotification", (notification) => {
      this.notifications.push(notification);
      // Aqui você pode adicionar a lógica para exibir a notificação para o usuário.
    });
  },
  beforeDestroy() {
    // Desconectando o socket quando o componente for destruído
    this.socket.disconnect();
  },
};
</script>
<style scoped>
.item-avatar {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
}

.list-action {
  display: inherit;
}
</style>
