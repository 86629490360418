<template>
  <div class="center">
    <v-card class="pa-4" style="max-width: 500px">
      <div class="text-center">
        <img src="../../assets/logo_izicompany.png" alt="logo" width="60%" />
      </div>
      <h4 class="my-4">Acesse sua conta</h4>
      <v-text-field
        v-model="form.email"
        outlined
        type="email"
        label="E-mail"
      ></v-text-field>
      <v-text-field
        v-model="form.password"
        outlined
        type="password"
        label="Senha"
        :hide-details="true"
      ></v-text-field>
      <v-checkbox
        v-model="mantenhaConectado"
        label="Mantenha-me conectado"
      ></v-checkbox>
      <v-btn block raised color="primary" @click="acessar()">Acessar</v-btn>
      <p class="text-center mt-4">Ou acesse com</p>
      <v-btn class="mt-4" block outlined color="red">
        <v-icon left> mdi-google </v-icon>
        Google
      </v-btn>
      <v-divider class="my-4"></v-divider>
      <v-btn text> Esqueceu a senha? </v-btn>
    </v-card>

    <ResetaSenha ref="resetaSenha" />
  </div>
</template>

<script lang="ts">
import ResetaSenha from "@/components/ResetaSenha.vue";
import  { api } from "../../api";

export default {
  title: "Login",
  name: "IziManLogin",
  data() {
    return {
      mantenhaConectado: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async acessar() {
      try {
        const responseToken = await api.get("/login", { params: this.form });
        if (responseToken.data) {
          this.$localStorage.set("LoggedUser", responseToken.data);
          localStorage.setItem("LoggedUser", responseToken.data);
          const responseUser = await api.get("/user/byToken");
          if (responseUser.data && !responseUser.data.first_login) {
            this.$localStorage.set("user", responseUser.data);
            this.$router.push({ name: "PaginaInicial" });
            return;
          }
          if (responseUser.data && responseUser.data.first_login) {
            this.$refs.resetaSenha.show();
            return;
          }
        } else {
          this.$root.GlobalMessage.error("Ocorreu um erro!");
        }
      } catch (error) {
        this.$root.GlobalMessage.error("Email ou senha invalidos");
      }
    },
  },
  components: { ResetaSenha },
};
</script>
<style scoped>
.center {
  display: inline-flex;
  justify-content: center;
  width: 100%;
}
</style>
