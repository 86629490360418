<template>
    <v-navigation-drawer v-model="dialog" right app temporary width="600" class="pa-4">
        <p class="title" @click="close()">
            Cadastro de assinatura do usuário
            <v-btn icon class="float-right">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </p>
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
            <p class="mb-6" v-if="user.signature">Este usuário já possui assinatura digital, caso você salve será sobrescrito a assinatura desse usuário</p>
            <VueSignaturePad width="100%" height="200px" class="signature" ref="signaturePad" />
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green" text @click="exec">
                Salvar
            </v-btn>
        </v-card-actions>
    </v-navigation-drawer>
</template>
  
<script>
import { api } from "../../../../api";

export default {
    name: "IziManDialogAssinatura",
    data() {
        return {
            signature: null,
            user: null,
            dialog: false,
        };
    },
    methods: {
        show(user = null) {
            this.user = user;
            this.dialog = true;
        },

        close() {
            this.dialog = false;
        },

        async exec() {
            try {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                const body = { signature: data };

                if (isEmpty) {
                    this.$root.GlobalMessage.error('Informe a assinatura do responsavel!')
                    return;
                }


                const response = await api.put(
                    `/user/${this.user._id}/signature`,
                    body
                );

                if (response.data) {
                    this.$root.GlobalMessage.success("Operação realizada com sucesso!");
                    this.$emit("salvo");
                    this.close();
                } else this.$root.GlobalMessage.error("Erro ao cadastrar assinatura do Usuário!");
            } catch (err) {
                this.$root.GlobalMessage.error(
                    "Erro ao realizar requisição, entre em contato com o administrador!"
                );
            }
        },
    },
};
</script>
  
<style scoped>
.signature {
    border: 1px solid black;
}
</style>
  