<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-row class="mt-2">
        <v-col class="py-0" cols="12" sm="12" md="6">
          <v-currency-field outlined label="Visita" prepend-inner-icon="mdi-currency-usd" v-model="form.fields.values.visit"
            :rules="form.rules.visitRules" :readonly="!permiteAlterar"></v-currency-field>
        </v-col>
        <v-col class="py-0" cols="12" sm="12" md="6">
          <v-currency-field outlined label="Mão de Obra" prepend-inner-icon="mdi-currency-usd"
            v-model="form.fields.values.labour" :rules="form.rules.labourRules"
            :readonly="!permiteAlterar"></v-currency-field>
        </v-col>
        <v-col class="py-0" cols="12" sm="12" md="6">
          <v-currency-field outlined label="Despesas" prepend-inner-icon="mdi-currency-usd"
            v-model="form.fields.values.expenses" :rules="form.rules.expensesRules"
            :readonly="!permiteAlterar"></v-currency-field>
        </v-col>
        <v-col class="py-0" cols="12" sm="12" md="6">
          <v-currency-field outlined label="Materiais" prepend-inner-icon="mdi-currency-usd"
            v-model="form.fields.values.material" :rules="form.rules.materialRules"
            :readonly="!permiteAlterar"></v-currency-field>
        </v-col>
        <v-col class="py-0" cols="12" sm="12" md="6">
          <v-currency-field outlined label="Desconto" prepend-inner-icon="mdi-currency-usd"
            v-model="form.fields.values.discount" :rules="form.rules.discountRules"
            :readonly="!permiteAlterar"></v-currency-field>
        </v-col>
        <v-col class="py-0" cols="12" sm="12" md="6">
          <v-currency-field outlined label="Total" prepend-inner-icon="mdi-currency-usd" v-model="form.fields.values.total"
            :rules="form.rules.totalRules" :readonly="!permiteAlterar" disabled></v-currency-field>
        </v-col>
      </v-row>

      <v-btn color="primary" :disabled="!permiteAlterar" outlined @click="this.salvar">
        Salvar
      </v-btn>
    </v-form>
  </div>
</template>

<script lang="ts">
import { api } from "../../../../api";
export default {
  name: "IziManFormularioValores",
  props: ["values", "id", "permiteAlterar"],
  emits: ["valoresAlterados"],
  data() {
    return {
      valid: true,
      form: {
        fields: {
          values: {
            visit: 0,
            labour: 0,
            expenses: 0,
            material: 0,
            discount: 0,
            total: 0,
          },
        },
        rules: {
          visitRules: [(v) => !!v || "Necessário preencher o campo de visita"],
          labourRules: [
            (v) => !!v || "Necessário preencher o campo de mão de obra",
          ],
          expensesRules: [
            (v) => !!v || "Necessário preencher o campo de despesas",
          ],
          materialRules: [
            (v) => !!v || "Necessário preencher o campo de material",
          ],
          discountRules: [
            (v) => !!v || "Necessário preencher o campo de desconto",
          ],
          totalRules: [(v) => !!v || "Necessário preencher o campo de total"],
        },
      },
    };
  },
  methods: {
    calc() {
      this.form.fields.values.total =
        this.form.fields.values.visit +
        this.form.fields.values.labour +
        this.form.fields.values.expenses +
        this.form.fields.values.material -
        this.form.fields.values.discount;
    },
    async salvar() {
      if (this.$refs.form.validate()) {
        const response = await api.put(
          "/maintenance/" + this.id + "/updateValues",
          this.form.fields
        );

        if (response.data) {
          this.$root.GlobalMessage.success("Operação realizada com sucesso!");

          this.$emit("valoresAlterados");
        }
      }
    },
  },
  mounted() {
    this.form.fields.values = Object.assign({}, this.values);

    this.$watch(
      (vm) => [
        vm.form.fields.values.visit,
        vm.form.fields.values.labour,
        vm.form.fields.values.expenses,
        vm.form.fields.values.material,
        vm.form.fields.values.discount,
      ],
      this.calc
    );
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
