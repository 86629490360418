<template>
  <div>
    <VueSignaturePad
      width="100%"
      height="200px"
      class="signature"
      ref="signaturePad"
    />

    <div class="mt-4">
      <v-btn color="orange darken-3" outlined @click="this.limparAssinatura">
        Limpar Assinatura
      </v-btn>
    </div>

    <div class="mt-4">
      <v-btn color="primary" outlined @click="this.next"> Finalizar </v-btn>
      <v-btn text @click="$emit('before')"> Voltar </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "ResFormularioAssinaturaDigital",
  data() {
    return {
      valid: true,
      form: {
        fields: {
          signature: null,
        },
      },
    };
  },
  methods: {
    next() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.form.fields.signature = data;

      if (!isEmpty) this.$emit("next", this.form.fields);
      else
        this.$root.GlobalMessage.error("Informe a assinatura do responsavel!");
    },

    limparAssinatura() {
      const signaturePad = this.$refs.signaturePad;

      signaturePad.clearSignature();
    },
  },
};
</script>
<style scoped>
.signature {
  border: 1px solid black;
}
</style>
