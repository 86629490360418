<template>
  <v-navigation-drawer
    v-model="drawer"
    right
    app
    temporary
    width="600"
    class="pa-4"
  >
    <p class="title" @click="close()">
      Configuração na utilização do sistema
      <v-btn icon class="float-right">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </p>
    <v-divider class="mb-4"></v-divider>

    <p class="subtitle">Configuração da empresa</p>

    <div class="ma-2 mb-4">
      <v-switch
        v-model="company.config.send_to_cash_book"
        @change="this.changeCompanyConfig"
        :hint="`Você ${
          company.config.send_to_cash_book ? '' : 'não'
        } está enviando as cobranças finalizadas para o seu Livro Caixa`"
        label="Enviar cobranças para o Livro Caixa?"
        persistent-hint
      ></v-switch>
    </div>

    <div class="ma-2 mt-6">
      <v-text-field
        type="number"
        v-model="company.config.assurance_days"
        :rules="rules.assurance_daysRules"
        @input="handleSearch"
        outlined
        label="Dias de garantia"
      ></v-text-field>
    </div>

    <p class="subtitle">Configuração do sistema</p>
    <div class="ma-2">
      <v-switch
        v-model="$vuetify.theme.dark"
        @change="this.changeTema"
        :hint="`Você esta com o seu tema escuro ${
          $vuetify.theme.dark ? 'ATIVO' : 'INATIVO'
        }`"
        label="Tema escuro"
        persistent-hint
      ></v-switch>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { api } from "../api";
import { debounce } from "lodash";

export default {
  name: "IziManNavigationDrawerConfiguracaoSistema",
  data() {
    return {
      id: null,
      drawer: false,
      company: {
        config: {
          send_to_cash_book: true,
          assurance_days: 0,
        },
      },
      rules: {
        assurance_daysRules: [
          (v) => !!v || "Necessário preencher o campo dias de garantia",
        ],
      },
    };
  },
  methods: {
    show() {
      this.getCompany();
      this.drawer = true;
    },
    close() {
      this.drawer = false;
    },

    changeTema(event) {
      this.$cookies.set("theme", event);
    },

    async getCompany() {
      const response = await api.get("/company/token");

      if (response.data) {
        this.company = response.data;
      }
    },

    async changeCompanyConfig() {
      const response = await api.put(`/company/${this.company._id}`, {
        config: this.company.config,
      });

      if (response.data) {
        this.$root.GlobalMessage.success("Operação realizada com sucesso!");
        this.close();
      }
    },

    handleSearch: debounce(function () {
      this.changeCompanyConfig();
    }, 1000),
  },
};
</script>
