<template>
  <div>

    <div class="display-title-page">
      <div class="ml-2">
        <div class="title mb-1">Listagem das cobranças geradas a partir de manutenções</div>
        <div class="caption mb-3">
          Listagem com todas as cobranças geradas que estão pendentes há serem pagas
        </div>
      </div>
    </div>

    <div class="ml-2 mt-4">

      <div>
        <p class="subtitle mb-1"><v-icon class="mr-2" color="red">mdi-calendar-alert</v-icon>Cobranças atrasadas</p>
        <v-divider class="mb-6"></v-divider>

        <v-row class="mb-4">
          <v-col v-for="(charge, index) in chargesLate" :key="index" cols="12" sm="12" md="3">
            <CardCobranca ref="cardCobrancaAtrasado" @reload="reset()" :cobranca="charge" />
          </v-col>
        </v-row>

        <p v-if="!chargesLate.length" class="mb-6">Nenhum cobrança encontrada</p>

      </div>
      <div>
        <p class="subtitle mb-1"><v-icon class="mr-2" color="blue">mdi-calendar</v-icon>Cobranças mensais</p>
        <v-divider class="mb-6"></v-divider>
        <v-row class="mb-4">
          <v-col v-for="(charge, index) in chargesMonthly" :key="index" cols="12" sm="12" md="3">
            <CardCobranca ref="cardCobrancaMensal" @reload="reset()" :cobranca="charge" />
          </v-col>
        </v-row>
        <p v-if="!chargesMonthly.length" class="mb-6">Nenhum cobrança encontrada</p>
      </div>
      <div>
        <p class="subtitle mb-1"><v-icon class="mr-2" color="primary">mdi-hammer-screwdriver</v-icon>Cobranças de
          manutenções</p>
        <v-divider class="mb-6"></v-divider>
        <v-row class="mb-4">
          <v-col v-for="(charge, index) in chargesMaintenance" :key="index" cols="12" sm="12" md="3">
            <CardCobranca ref="cardCobrancaManutencao" @reload="reset()" :cobranca="charge" />
          </v-col>
        </v-row>
        <p v-if="!chargesMaintenance.length" class="mb-6">Nenhum cobrança encontrada</p>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { api } from "../../../../api";
import moment from "moment-timezone";
import CardCobranca from "../card/CardCobranca.vue";

export default {
  name: "IziManCobrancas",
  components: { CardCobranca },
  data() {
    return {
      charges: [],
      chargesLate: [],
      chargesMonthly: [],
      chargesMaintenance: [],
    };
  },
  methods: {
    reset() {
      this.charges = [];
      this.chargesLate = [];
      this.chargesMonthly = [];
      this.chargesMaintenance = [];

      this.getCharges()
    },
    async getCharges() {

      const query = {
        payed: false,
        cancelled: false,
      };

      const charges = await api.get("/charge", { params: { ...query } });

      if (charges.data) {

        this.charges = charges.data;
        this.chargesLate = charges.data.filter((charge) => moment().isAfter(charge.payment_date));
        this.chargesMonthly = charges.data.filter((charge) => charge?.monthlyCharge);
        this.chargesMaintenance = charges.data.filter((charge) => moment().isBefore(charge.payment_date));
      }
    },
  },
  beforeMount() {
    this.getCharges();
  },
};
</script>