<template>
  <v-navigation-drawer
    v-model="drawer"
    @input="onClose"
    right
    app
    temporary
    width="600"
    class="pa-4"
  >
    <p class="title" @click="close()">
      {{ id ? "Alteração" : "Cadastro" }} de equipamento
      <v-btn icon class="float-right">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </p>
    <v-divider class="mb-4"></v-divider>

    <FormularioGerenciarEquipamento
      ref="formularioGerenciarEquipamento"
      @salvar="this.salvar"
      :id="id"
    />
  </v-navigation-drawer>
</template>

<script>
import  { api } from "../../../../api";
import FormularioGerenciarEquipamento from "../forms/FormularioGerenciarEquipamento.vue";

export default {
  name: "IziManNavigationDrawerGerenciarEquipamento",
  components: { FormularioGerenciarEquipamento },
  data() {
    return {
      id: null,
      drawer: false,
    };
  },
  methods: {
    show(id = null) {
      this.id = id;
      this.drawer = true;
    },
    close() {
      this.drawer = false;
    },

    onClose(event) {
      !event && this.$refs.formularioGerenciarEquipamento.$refs.form.reset();
    },
    async salvar(body) {
      try {
        const response = !this.id
          ? await api.post("/equipment", body)
          : await api.put(`/equipment/${this.id}`, body);
        if (response.data) {
          this.$root.GlobalMessage.success("Operação realizada com sucesso!");
          this.$refs.formularioGerenciarEquipamento.$refs.form.reset();
          this.$emit("salvo", response.data);
          this.close();
        } else this.$root.GlobalMessage.error("Erro ao cadastrar Equipamento!");
      } catch (err) {
        this.$root.GlobalMessage.error(
          "Erro ao realizar requisição, entre em contato com o administrador!"
        );
      }
    },
  },
};
</script>
