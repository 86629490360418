<template>
  <div>
    <v-stepper v-model="steps" vertical>
      <v-stepper-step :complete="steps > 1" step="1">
        Informa os dados recolhidos durante a execução da OS
      </v-stepper-step>

      <v-stepper-content step="1">
        <FormularioExecucao @next="this.next" />
      </v-stepper-content>

      <v-stepper-step :complete="steps > 2" step="2">
        Informa os valores da OS
      </v-stepper-step>

      <v-stepper-content step="2">
        <FormularioValores
          ref="formularioValores"
          @next="this.next"
          @before="this.before"
        />
      </v-stepper-content>

      <v-stepper-step :complete="steps > 3" step="3">
        Informa a assinatura do responsável
      </v-stepper-step>

      <v-stepper-content step="3">
        <FormularioAssinaturaDigital
          ref="formularioAssinatura"
          @next="this.next"
          @before="this.before"
        />
      </v-stepper-content>
    </v-stepper>
    <Notificacao ref="notificacao" />
  </div>
</template>

<script lang="ts">
import EnumStep from "../../../../enums/StepManutencao";
import FormularioExecucao from "../forms/FormularioExecucao.vue";
import FormularioValores from "../forms/FormularioValores.vue";
import FormularioAssinaturaDigital from "../forms/FormularioAssinaturaDigital.vue";
import Notificacao from "../../../../components/Notificacao.vue";
import { api } from "../../../../api";

export default {
  name: "IziManStepperExecucao",
  components: {
    FormularioExecucao,
    FormularioValores,
    FormularioAssinaturaDigital,
    Notificacao,
  },
  emits: ["save"],
  props: ["id", "service_type"],
  data() {
    return {
      EnumStep,
      steps: 1,
      form: {
        fields: {
          item: null,
          issue_found: null,
          executed_activity: null,
          values: {
            visit: null,
            labour: null,
            expenses: null,
            material: null,
            discount: null,
            total: null,
          },
          signature: null,
        },
      },
    };
  },
  methods: {
    async next(fields) {
      this.form.fields = Object.assign(this.form.fields, fields);
      if (this.steps === 3) {
        try {
          this.loading = true;
          const response = await api.put(
            "/maintenance/" + this.id + "/finish",
            {
              ...this.form.fields,
              step:
                this.service_type === "ORÇAMENTO"
                  ? EnumStep.FINALIZADO
                  : EnumStep.PENDENTE_REVISAO,
              maintenance_date: new Date(),
            }
          );
          if (response.data) {
            this.$root.GlobalMessage.success("Operação realizada com sucesso!");
            this.$emit("save");
          } else
            this.$root.GlobalMessage.error("Erro ao finalizar manutenção!");

          this.loading = false;
        } catch (err) {
          this.$root.GlobalMessage.error(
            "Erro ao realizar requisição, entre em contato com o administrador!"
          );
          this.loading = false;
        }
      }

      if (this.steps !== 3) {
        this.steps++;
        this.$refs.formularioValores.getItemValues(this.form.fields.item);
      }
    },
    before() {
      this.steps--;
    },
    async getItem(id) {
      const response = await api.get(`maintenance/${id}`);

      if (response.data) {
        this.manutencao = response.data;
      } else this.$root.GlobalMessage.error("Erro ao encontrar item");
    },
  },
};
</script>

<style scoped>
.v-stepper {
  box-shadow: none !important;
}
</style>
