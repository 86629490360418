<template>
  <div>

    <v-snackbar v-model="show" :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="show = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">

export default {
  name: 'IziManNotificacao',
  data() {
    return {
      show: false,
      color: 'primary',
      text: '',
    }
  },
  methods: {
    showSuccess: function(text) {
      this.text = text;
      this.color = 'success';
      this.show = true
    },
    showWarning: function(text) {
      this.text = text;
      this.color = 'warning';
      this.show = true
    },
    showError: function(text) {
      this.text = text;
      this.color = 'red';
      this.show = true;
    },
    showNotification: function(text) {
      this.text = text;
      this.color = 'info';
      this.show = true
    },
  }
}
</script>

st