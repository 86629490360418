<template>
  <div>
    <v-form ref="form" v-model="valid">
      <p class="body-2" v-if="client_monthly">
        <v-icon small color="blue">mdi-information</v-icon>
        Este cliente é um cliente mensal, logo a manutenção será cobrada somente
        uma vez no mês subsequente, após a conclusão da O.S.
      </p>

      <AutoCompleteManutencao
        ref="autoCompleteManutencao"
        v-if="service_type === 'GARANTIA'"
        :query="queryAutoCompleteManutencao"
        @change="changeManutencao"
      />

      <v-alert
        color="primary"
        dark
        prominent
        border="left"
        :value="!!this.manutencao"
        transition="scale-transition"
      >
        <p class="ma-0">
          O.S.:
          <b>{{ this.manutencao?.number_os_format }}</b>
        </p>
        <p class="ma-0">
          Cliente: <b>{{ this.manutencao?.client?.fantasy_name }}</b>
        </p>
        <p class="ma-0">
          Endereco do cliente:
          <b>{{ this.manutencao?.client?.address?.street }}</b>
        </p>
        <p class="ma-0">
          Equipamentos:
          <b>{{ this.manutencao?.equipment.map((x) => x.name).join(" | ") }}</b>
        </p>
      </v-alert>

      <AutoCompleteCliente
        ref="autoCompleteCliente"
        v-if="service_type !== 'GARANTIA'"
        @change="form.fields.client = $event"
        @monthly="client_monthly = $event"
      />

      <AutoCompleteEquipamento
        ref="autoCompleteEquipamento"
        v-if="service_type !== 'GARANTIA'"
        @change="form.fields.equipment = $event"
      />

      <v-textarea
        clearable
        outlined
        name="input-7-4"
        label="Informe o problema alegado"
        v-model="form.fields.issue_reported"
        :rules="form.rules.issue_reportedRules"
      >
      </v-textarea>

      <v-chip-group active-class="primary--text" class="mb-4" column>
        <v-chip
          v-for="problema in problemas_alegados"
          :key="problema.name"
          @click="form.fields.issue_reported = problema.content"
        >
          {{ problema.name }}
        </v-chip>
      </v-chip-group>

      <v-switch
        class="ml-3 mb-5"
        :messages="[
          'Realizando a marcação será prioritária a execução dessa ordem de serviço',
        ]"
        v-model="form.fields.priority"
        color="red"
        label="Prioridade"
      ></v-switch>

      <v-btn color="primary" outlined @click="this.next"> Gerar O.S. </v-btn>
      <v-btn text @click="$emit('before')"> Voltar </v-btn>
    </v-form>
  </div>
</template>

<script lang="ts">
import { api } from "../../../../api";
import { EnumTipoAuxiliarFiltro } from "../../../../enums/TipoAuxiliares";
import AutoCompleteEquipamento from "@/pages/equipamento/components/autoComplete/AutoCompleteEquipamento.vue";
import AutoCompleteCliente from "@/pages/cliente/components/autoComplete/AutoCompleteCliente.vue";
import AutoCompleteManutencao from "../autoComplete/AutoCompleteManutencao.vue";

export default {
  name: "IziManFormularioCliente",
  components: {
    AutoCompleteCliente,
    AutoCompleteEquipamento,
    AutoCompleteManutencao,
  },
  props: ["service_type"],
  data: () => ({
    valid: true,
    client_monthly: false,
    manutencao: null,
    EnumTipoAuxiliarFiltro,
    queryAutoCompleteManutencao: {
      hasAssurance: true,
    },
    form: {
      fields: {
        ref_os: null,
        client: null,
        equipment: null,
        issue_reported: null,
        priority: null,
      },
      rules: {
        issue_reportedRules: [
          (v) =>
            !!v || "Necessário preencher o campo de tipo de problema alegado",
        ],
      },
    },
    problemas_alegados: [],
  }),
  methods: {
    resetarFormulario() {
      if (this.service_type === "GARANTIA") {
        this.$refs.autoCompleteManutencao.$refs.formAutoCompleteManutencao.reset();
        this.manutencao = null;
      } else {
        this.client_monthly = false;
        this.$refs.autoCompleteCliente.$refs.formAutoCompleteCliente.reset();
        this.$refs.autoCompleteEquipamento.$refs.formAutoCompleteEquipamento.reset();
      }
      this.$refs.form.reset();
    },

    next() {
      if (this.service_type === "GARANTIA") {
        if (
          this.$refs.autoCompleteManutencao.$refs.formAutoCompleteManutencao.validate() &&
          this.$refs.form.validate()
        ) {
          this.$emit("next", this.form.fields);
        }
      } else {
        if (
          this.$refs.autoCompleteCliente.$refs.formAutoCompleteCliente.validate() &&
          this.$refs.autoCompleteEquipamento.$refs.formAutoCompleteEquipamento.validate() &&
          this.$refs.form.validate()
        ) {
          this.$emit("next", this.form.fields);
        }
      }
    },

    changeManutencao(event) {
      this.form.fields.ref_os = event?._id;
      this.manutencao = event;
    },

    async carregaAuxiliares() {
      const response = await api.get("/helper", {
        params: { type: EnumTipoAuxiliarFiltro.PROBLEMA_ALEGADO },
      });

      if (response.data) {
        this.problemas_alegados = response.data.map((auxiliar) => ({
          name: auxiliar.name,
          content: auxiliar.content,
        }));
      }
    },
  },
  beforeMount() {
    this.carregaAuxiliares();
  },
};
</script>
