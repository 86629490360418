<template>
  <div>
    <v-card>
      <v-card-title v-if="!loading">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
          hide-details></v-text-field>
      </v-card-title>
      <v-data-table item-key="name" :items="list" :headers="headers" :loading="loading"
        :custom-filter="filterOnlyCapsText" :search="search" loading-text="Carregando... aguarde">
        <template v-slot:[`item.active`]="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on" v-if="item.active" color="green">mdi-clipboard-alert</v-icon>
            </template>
            Ativo
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on" v-if="!item.active" color="red">mdi-clipboard-alert</v-icon>
            </template>
            Inativo
          </v-tooltip>

        </template>
        <template v-slot:[`item.monthly`]="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on" v-if="item.monthly" color="blue">mdi-calendar</v-icon>
            </template>
            Cliente mensal
          </v-tooltip>

        </template>
        <template v-slot:[`item.cancelled`]="{ item }">
          <v-chip v-if="item.cancelled" small color="red" outlined label>
            CANCELADO
          </v-chip>
        </template>
        <template v-slot:[`item.service_type`]="{ item }">
          <v-chip small color="primary" outlined label>
            {{ item.service_type }}
          </v-chip>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | moment("DD/MM/YYYY hh:mm") }}
        </template>
        <template v-slot:[`item.step`]="{ item }">
          <v-chip small outlined :color="color[item.step]" label>
            {{ item.step }}
          </v-chip>
        </template>
        <template v-slot:[`item.responsables`]="{ item }">
          {{ item.responsables ? item?.responsables[0]?.name : "" }}
        </template>
        <template v-slot:[`item.expected_maintenance_date`]="{ item }">
          {{ item.expected_maintenance_date | moment("DD/MM/YYYY hh:mm") }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="primary" v-bind="attrs" v-on="on" :to="'/cliente-detalhe/' + item._id">
                <v-icon small>mdi-text-box-search</v-icon>
              </v-btn>
            </template>
            Detalhe do cliente
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { api } from "../../../../api";

export default {
  name: "IziManTabelaCliente",
  components: {},
  data() {
    return {
      search: "",
      list: [],
      headers: [
        { text: "Ativo", value: "active" },
        { text: "Mensal", value: "monthly" },
        { text: "Nome fantasia", value: "fantasy_name" },
        { text: "Responsável", value: "responsable_name" },
        { text: "Telefone", value: "phone_number" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      loading: false,
    };
  },
  methods: {
    async getItens(fields = null) {
      this.list = [];
      this.loading = true;
      const response = await api.get("/client", { params: { ...fields } });

      if (response.data) {
        this.list = response.data;
      } else this.$root.GlobalMessage.error("Erro ao encontrar clientes");
      this.loading = false;
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
  },

  beforeMount() {
    this.getItens();
  },
};
</script>
