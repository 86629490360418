<template>
  <v-simple-table v-if="pecas?.length" class="mb-4">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Nome</th>
          <th class="text-left">Valor</th>
          <th class="text-left">Quantidade</th>
          <th class="text-left" v-if="!hiddenAction">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in pecas" :key="item.name">
          <td>{{ item.name }}</td>
          <td>{{ item.value | currency }}</td>
          <td>{{ item.quantity }}</td>
          <td width="150" v-if="!hiddenAction">
            <v-btn class="mx-2" fab dark x-small color="warning" @click="$emit('minus', item)">
              <v-icon dark> mdi-minus </v-icon>
            </v-btn>
            <v-btn class="mx-2" fab dark x-small color="success" @click="$emit('plus', item)">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script lang="ts">
export default {
  name: "IziManTabelaPecas",
  emits: ['minus', 'plus'],
  props: ["pecas", "hiddenAction"],
  data() {
    return {};
  },
  methods: {},
};
</script>
