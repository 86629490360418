<template>
  <div>
    <v-card
      class="text-center py-2"
      :class="
        orcamento.approved
          ? 'APROVADO'
          : orcamento.approved == false
          ? 'REPROVADO'
          : 'DEFAULT'
      "
    >
      <div class="float-left mt-2 pl-2">
        <v-icon x-large v-if="orcamento.approved_by" color="green"
          >mdi-check-circle</v-icon
        >
        <v-icon x-large v-if="orcamento.refused_by" color="red"
          >mdi-close-circle</v-icon
        >
      </div>
      <p class="text-overline ma-0">
        <v-btn
          text
          class="my-2 px-0 my-text-button"
          color="primary"
          @click="
            $refs.navigationDrawerManutencaoDetalhe.show(
              orcamento.maintenance._id
            )
          "
        >
          {{ orcamento.maintenance.number_os_format }}
        </v-btn>
      </p>
      <p class="text-title mb-2">
        {{ orcamento.maintenance.client.fantasy_name }}
      </p>
      <v-card-title class="pa-0" style="width: 100% !important">
        <p class="ma-0"></p>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6"
            >Visita:
            <b>{{ orcamento.maintenance.values.visit | currency }}</b></v-col
          >
          <v-col cols="12" sm="6"
            >Mão de obra:
            <b>{{ orcamento.maintenance.values.labour | currency }}</b></v-col
          >
          <v-col cols="12" sm="6"
            >Despesas:
            <b>{{ orcamento.maintenance.values.expenses | currency }}</b></v-col
          >
          <v-col cols="12" sm="6"
            >Materiais:
            <b>{{ orcamento.maintenance.values.material | currency }}</b></v-col
          >
          <v-col cols="12" sm="6"
            >Descontos:
            <b>{{ orcamento.maintenance.values.discount | currency }}</b></v-col
          >
          <v-col cols="12" sm="6"
            >Total:
            <b>{{ orcamento.maintenance.values.total | currency }}</b></v-col
          >
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="text-left">
        <div>
          Orçamento gerado em:
          <b>{{ orcamento.created_at | moment("DD/MM/YYYY hh:mm") }}</b>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text v-if="orcamento.approved_by || orcamento.refused_by">
        <div class="text-left" v-if="orcamento.approved">
          <div>
            Aprovado por: <b>{{ orcamento.approved_by.name }}</b>
          </div>
          <div>
            Aprovado em:
            <b>{{ orcamento.approved_date | moment("DD/MM/YYYY hh:mm") }}</b>
          </div>
          <div>
            O.S. gerada a partir desse orçamento:
            <v-btn
              text
              class="pa-0 my-text-button"
              color="primary"
              @click="
                $refs.navigationDrawerManutencaoDetalhe.show(
                  orcamento.maintenance_generated._id
                )
              "
            >
              {{
                orcamento.maintenance_generated.number_os_format
              }}
            </v-btn>
          </div>
        </div>
        <div class="text-left" v-else>
          <div>
            Reprovado por: <b>{{ orcamento.refused_by.name }}</b>
          </div>
          <div>
            Reprovado em:
            <b>{{ orcamento.refused_date | moment("DD/MM/YYYY hh:mm") }}</b>
          </div>
          <div>
            Motivo da reprovação: <b>{{ orcamento.refused_motive }}</b>
          </div>
        </div>
      </v-card-text>
      <v-card-actions
        class="mt-1 pa-2"
        v-if="!orcamento.approved_by && !orcamento.refused_by"
      >
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-btn
              outlined
              block
              color="error"
              @click="$refs.dialogRecusarOrcamento.show(orcamento)"
              >Reprovar</v-btn
            >
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn
              outlined
              block
              color="success"
              @click="$refs.dialogAprovarOrcamento.show(orcamento)"
              >Aprovar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <DialogAprovarOrcamento
      ref="dialogAprovarOrcamento"
      @approved="$emit('reload')"
    />
    <DialogRecusarOrcamento
      ref="dialogRecusarOrcamento"
      @refused="$emit('reload')"
    />
    <NavigationDrawerManutencaoDetalhe
      ref="navigationDrawerManutencaoDetalhe"
    />
  </div>
</template>

<script>
import DialogRecusarOrcamento from "../dialogs/DialogRecusarOrcamento.vue";
import DialogAprovarOrcamento from "../dialogs/DialogAprovarOrcamento.vue";
import NavigationDrawerManutencaoDetalhe from "../navigationDrawer/NavigationDrawerManutencaoDetalhe.vue";

export default {
  name: "IziManCardOrcamento",
  props: ["orcamento"],
  emits: ["reload"],
  components: {
    DialogRecusarOrcamento,
    NavigationDrawerManutencaoDetalhe,
    DialogAprovarOrcamento,
  },
};
</script>

<style scoped>
.APROVADO {
  border-top: 4px solid #4caf50 !important;
}

.REPROVADO {
  border-top: 4px solid #f44336 !important;
}

.DEFAULT {
  border-top: 4px solid #607d8b !important;
}

.my-text-button.v-btn {
  height: 17px !important;
}
</style>
