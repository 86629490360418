<template>
  <div>
    <v-card hover>
      <v-card-text class="pa-1 text-center">
        <span class="text-overline">
          {{
            cobranca?.maintenance
              ? cobranca.maintenance.number_os_format
              : cobranca.monthlyChargeDate
          }}
        </span>
        <br />
        <p class="text-truncate mb-1">
          {{ cobranca.client.fantasy_name }}
        </p>
        <v-rating
          :value="cobranca.installment"
          :length="cobranca.installments"
          color="green"
          dense
          readonly
          size="12"
        >
          <template v-slot:item="props">
            <v-icon
              :color="
                cobranca.installment == props.index + 1
                  ? 'blue'
                  : props.isFilled
                  ? 'green'
                  : 'grey lighten-1'
              "
              small
            >
              {{ props.isFilled ? "mdi-currency-usd" : "mdi-currency-usd-off" }}
            </v-icon>
          </template>
        </v-rating>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="pa-2">
        <span
          >Parcela: {{ cobranca.installment }} /
          {{ cobranca.installments }}</span
        ><br />
        <span>
          Data de vencimento:
          {{ cobranca.payment_date | moment("DD/MM/YYYY") }} </span
        ><br />
        <span>Valor da cobrança: {{ cobranca.payment_value | currency }}</span>
      </v-card-text>
      <v-divider class="mb-2"></v-divider>
      <v-card-actions v-if="!cobranca.cancelled && !cobranca.payed">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-btn
              color="red darken-1"
              outlined
              block
              @click="$refs.dialogCancelarCobranca.show(cobranca)"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn
              color="green darken-1"
              outlined
              block
              @click="confirmCharge(cobranca)"
            >
              Pagar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-text v-else>
        <div class="text-left" v-if="cobranca.payed">
          <div>
            PAGO
          </div>
          <div>
            Aprovado em:
            <b>{{ cobranca.payed_date | moment("DD/MM/YYYY hh:mm") }}</b>
          </div>
        </div>
        <div class="text-left" v-if="cobranca.cancelled">
          <div>
            CANCELADO
          </div>
          <div>
            Aprovado em:
            <b>{{ cobranca.cancelled_date | moment("DD/MM/YYYY hh:mm") }}</b>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <DialogCancelarCobranca
      ref="dialogCancelarCobranca"
      @cancel="$emit('reload')"
    />
  </div>
</template>

<script lang="ts">
import { api } from "../../../../api";
import DialogCancelarCobranca from "../dialogs/DialogCancelarCobranca.vue";

export default {
  name: "IziManCardMensalista",
  components: { DialogCancelarCobranca },
  emits: ["reload"],
  props: ["cobranca"],
  data() {
    return {};
  },
  methods: {
    async confirmCharge(charge) {
      const response = await api.put(`/charge/${charge._id}/confirm`);

      if (response.data) {
        this.$root.GlobalMessage.success("Operação realizada com sucesso!");

        this.$emit("reload");
      }
    },
  },
};
</script>

<style scoped>
.item-avatar {
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
</style>
