<template>
    <div>
        <RelatorioOS></RelatorioOS>
    </div>
</template>

<script>
import RelatorioOS from '@/components/RelatoriosOsV2.vue';

export default {
    name: "ResOS",
    components: { RelatorioOS },
    data() {
        return {
            maintenance: null
        };
    },
    methods: {
        name() {
        }
    },
    beforeMount() {
        this.name();
    },
}
</script>

<style lang="css" scoped>

</style>
