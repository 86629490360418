const EnumTipoAuxiliar = {
  PROBLEMA_ALEGADO: "Problema alegado",
  PROBLEMA_ENCONTRADO: "Problema encontrado",
  ATIVIDADES_REALIZADAS: "Atividades realizadas",
};

const EnumTipoAuxiliarFiltro = {
  PROBLEMA_ALEGADO: "PROBLEMA_ALEGADO",
  PROBLEMA_ENCONTRADO: "PROBLEMA_ENCONTRADO",
  ATIVIDADES_REALIZADAS: "ATIVIDADES_REALIZADAS",
};

export { EnumTipoAuxiliar, EnumTipoAuxiliarFiltro };