<template>
  <div>
    <div class="display-title-page">
      <div class="ml-2">
        <div class="title mb-1">Listagem de clientes</div>
        <div class="caption mb-3">
          Listagem com todos os clientes da sua empresa
        </div>
      </div>
      <div class="mr-2" style="display: inline-flex; align-items: center">
        <v-btn raised color="primary" class="ml-1" @click="$refs.navigationDrawerGerenciarCliente.show()"><v-icon
            left>mdi-plus</v-icon>Cadastrar cliente</v-btn>

        <v-btn raised color="info" class="ml-1"
          @click="$refs.dialogFiltroCliente.show()"><v-icon>mdi-filter</v-icon></v-btn>
      </div>
    </div>

    <TabelaCliente ref="tabelaCliente" />

    <NavigationDrawerGerenciarCliente ref="navigationDrawerGerenciarCliente" @salvo="$refs.tabelaCliente.getItens()" />
    <DialogFiltroCliente ref="dialogFiltroCliente" @filtrar="$refs.tabelaCliente.getItens($event)" />
  </div>
</template>

<script lang="ts">
// import { api } from "../../api";
import NavigationDrawerGerenciarCliente from "./components/navigationDrawer/NavigationDrawerGerenciarCliente.vue";
import DialogFiltroCliente from "./components/dialog/DialogFiltroCliente.vue";
// import CardCliente from "@/pages/cliente/components/card/CardCliente.vue";
import TabelaCliente from '@/pages/cliente/components/tables/TabelaCliente.vue'

export default {
  name: "IziManCliente",

  title: "Cliente",
  components: {
    NavigationDrawerGerenciarCliente,
    // CardCliente,
    TabelaCliente,
    DialogFiltroCliente,
  },
  data() {
    return {
      fab: false,
      loading: false,
      attrs: {
        class: "ma-1",
        elevation: 2,
      },
      list: [],
    };
  },
  methods: {
    // async getClients(fields = null) {
    //   this.list = [];
    //   this.loading = true;
    //   const response = await api.get("/client", { params: { ...fields } });

    //   if (response.data) {
    //     this.list = response.data;
    //   } else this.$root.GlobalMessage.error("Erro ao encontrar clientes");
    //   this.loading = false;
    // },
  },
  beforeMount() {
    // this.getClients();
  },
};
</script>

<style scoped>
/* This is for documentation purposes and will not be needed in your application */

.valores {
  width: 100%;
  display: inline-flex;
  justify-content: space-evenly;
}

.inside-valores {
  display: inline-flex;
}

.v-card__title {
  justify-content: center;
}

.v-list {
  padding: 0;
}

.display-title-page {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>
