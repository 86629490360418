<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card v-if="user">
      <v-card-title class="text-h6">
        Deseja realmente resetar a senha do usuário?
      </v-card-title>
      <v-card-text>
        Ao resetar a senha do <b>{{ user.nick_name }}</b> a mesma será enviado para o
        e-mail <b>{{ user.email }}</b> que esta no cadastro do usuário e após
        realizar o login ele deverá redefini-la.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" text @click="exec"> Resetar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import  { api } from "../../../../api";

export default {
  name: "IziManDialogResetarSenha",
  data() {
    return {
      user: null,
      dialog: false,
    };
  },
  methods: {
    show(user = null) {
      this.user = user;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    async exec() {
      try {
        const response = await api.put(`/user/${this.user._id}/resetPassword`, { email: this.user.email});

        if (response.data) {
          this.$root.GlobalMessage.success("Senha resetada com sucesso!");
          this.$emit("salvo");
          this.close();
        } else this.$root.GlobalMessage.error("Erro ao cadastrar Usuário!");
      } catch (err) {
        this.$root.GlobalMessage.error(
          "Erro ao realizar requisição, entre em contato com o administrador!"
        );
      }
    },
  },
};
</script>
