<template>
  <div>
    <v-card>
      <v-card-title v-if="!loading">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        item-key="name"
        :items="list"
        :headers="headers"
        :custom-filter="filterOnlyCapsText"
        :search="search"
        :loading="loading"
        loading-text="Carregando... aguarde"
      >
        <template v-slot:[`item.maintenance`]="{ item }">
          {{
            item.maintenance
              ? item.maintenance.number_os_format
              : item.monthlyChargeDate
          }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            label
            :color="
              ['ATRASADO', 'CANCELADO'].includes(item.status)
                ? 'red'
                : item.status == 'PAGO'
                ? 'green'
                : 'blue'
            "
            text-color=" white"
            small
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:[`item.charges`]="{ item }">
          {{ `${item.installment}/${item.installments}` }}
          <v-progress-linear
            :value="(100 * item.installment) / item.installments"
          ></v-progress-linear>
        </template>
        <template v-slot:[`item.payment_value`]="{ item }">
          {{ item.payment_value | currency }}
        </template>
        <template v-slot:[`item.payment_date`]="{ item }">
          {{ item.payment_date | moment("DD/MM/YYYY hh:mm") }}
        </template>
        <template v-slot:[`item.payed_date`]="{ item }">
          {{ item.payed_date | moment("DD/MM/YYYY hh:mm") }}
        </template>
        <template v-slot:[`item.cancelled_date`]="{ item }">
          {{ item.cancelled_date | moment("DD/MM/YYYY hh:mm") }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="item.status !== 'PENDENTE'"
                icon
                color="blue"
                v-bind="attrs"
                v-on="on"
                @click="confirmCharge(item)"
              >
                <v-icon small>mdi-cash-check</v-icon>
              </v-btn>
            </template>
            Realizar pagamento
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="item.status !== 'PENDENTE'"
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
                @click="$refs.dialogCancelarCobranca.show(item)"
              >
                <v-icon small>mdi-cash-off</v-icon>
              </v-btn>
            </template>
            Cancelar pagamento
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <DialogCancelarCobranca
      ref="dialogCancelarCobranca"
      @cancel="getAllCharge()"
    />
  </div>
</template>

<script lang="ts">
import { api } from "../../../../api";
import moment from "moment-timezone";
import DialogCancelarCobranca from "../dialogs/DialogCancelarCobranca.vue";

export default {
  name: "IziManTabelaCobranca",
  components: { DialogCancelarCobranca },
  data() {
    return {
      loading: false,
      search: "",
      list: [],
      headers: [
        { text: "Cliente", value: "client.fantasy_name" },
        { text: "Manutenção", value: "maintenance" },
        { text: "Situação", value: "status" },
        { text: "Parcelas", value: "charges" },
        { text: "Valor", value: "payment_value" },
        { text: "Data pag. prevista", value: "payment_date" },
        { text: "Data pagamento", value: "payed_date" },
        { text: "Data cancelamento", value: "cancelled_date" },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    getAllData() {
      this.getAllCharge();
    },
    async getAllCharge() {
      const charge = await api.get("/charge");
      this.list = charge.data.map((data) => {
        if (data?.payed) data.status = "PAGO";
        if (data?.cancelled) data.status = "CANCELADO";
        if (!data?.payed && !data?.cancelled) {
          if (moment().isAfter(data.payment_date)) data.status = "ATRASADO";
          else data.status = "PENDENTE";
        }
        return data;
      });
    },
    async confirmCharge(charge) {
      const response = await api.put(`/charge/${charge._id}/confirm`);
      if (response.data) {
        this.$root.GlobalMessage.success("Operação realizada com sucesso!");
        this.getAllCharge();
      }
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
  },
  beforeMount() {
    this.getAllData();
  },
};
</script>
