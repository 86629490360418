<template>
  <v-dialog persistent v-model="dialog" max-width="500">
    <v-card>
      <v-card-text>
        <div class="ma-8 pt-8">
          <v-img src="../assets/lock_screen.svg"></v-img>
        </div>
        <p>
          Você perdeu a sessão para retomarmos ela precisa que você insira a
          senha novamente.
        </p>
        <v-text-field
          class="mt-2"
          v-model="form.password"
          outlined
          type="password"
          label="Senha"
          :hide-details="true"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" text @click="exec"> Autenticar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import  { api } from "../api";

export default {
  name: "IziManLockScreen",
  data() {
    return {
      dialog: false,
      form: {
        password: "",
      },
    };
  },
  methods: {
    show() {
      if (this.$route.path !== "/") this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    async exec() {
      try {
        const response = await api.post(`/user/byLockScreen`, this.form);

        if (response.data) {
          this.$localStorage.set("user", response.data);
          this.close();
        } else this.$root.GlobalMessage.error("Erro ao autenticar!");
      } catch (err) {
        this.$root.GlobalMessage.error(
          "Erro ao realizar requisição, entre em contato com o administrador!"
        );
      }
    },
  },
};
</script>
