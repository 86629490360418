<template>
  <div>
    <v-navigation-drawer
      v-model="dialog"
      @input="onClose"
      right
      app
      temporary
      width="600"
      class="pa-4"
    >
      <p class="title" @click="closeModal()">
        Gerar parcelas
        <v-btn icon class="float-right">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </p>
      <v-divider class="mb-4"></v-divider>

      <v-alert
        outlined
        type="info"
        prominent
        border="left"
        v-if="maintenance?.values.total === 0"
      >
        Notamos que essa manutenção possui um valor zerado, logo será possivel
        simplesmente finalizar a O.S. sem a geração de nenhuma cobrança clicando
        no botão <b>Finalizar O.S.</b>
      </v-alert>

      <v-form ref="form" v-model="valid">
        <div
          class="py-2 my-2 text-center"
          v-if="maintenance?.values.total !== 0"
        >
          <v-select
            :items="picker_installments"
            v-model="installments"
            outlined
            label="Quantidade parcelas"
            @change="generateForm()"
          ></v-select>

          <v-row no-gutters>
            <v-col
              v-for="(item, index) in form.fields.charge.payments"
              :key="index"
              cols="12"
              sm="12"
              md="6"
            >
              <v-card class="mx-2 mb-4 px-2 py-2">
                <p>Parcela: {{ index + 1 }}</p>
                <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  :return-value.sync="item.payment_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="item.payment_date_format"
                      label="Data da parcela"
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      :rules="form.rules.date"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.payment_date"
                    locale="pt-BR"
                    no-title
                    scrollable
                    @input="$refs.menu[index].save(item.payment_date)"
                    @change="changeDate(item, index)"
                  >
                  </v-date-picker>
                </v-menu>

                <v-currency-field
                  outlined
                  label="Valor da parcela"
                  prepend-inner-icon="mdi-currency-usd"
                  @change="recalculateTotal()"
                  v-model="item.payment_value"
                  :rules="form.rules.value"
                ></v-currency-field>
              </v-card> </v-col
          ></v-row>
        </div>

        <div
          class="subtitle"
          :class="
            total_value <= 0 ? 'green--text darken-3' : 'red--text darken-3'
          "
        >
          Valor total: {{ total_value | currency }}
          <v-btn
            class="float-right"
            color="primary"
            outlined
            @click="generate()"
            v-if="maintenance?.values.total !== 0"
            :disabled="!installments"
          >
            Salvar
          </v-btn>
          <v-btn
            class="float-right mr-2"
            color="info"
            outlined
            v-if="maintenance?.values.total === 0"
            @click="finishWithOutCharge()"
          >
            Finalizar O.S.
          </v-btn>
        </div>
      </v-form>
    </v-navigation-drawer>

    <v-dialog v-model="dialogConfirm" persistent max-width="300">
      <v-card>
        <v-card-title class="text-h5 text-center mb-1">
          Deseja realmente gerar a(s) parcela(s)?
        </v-card-title>
        <v-card-text>
          <p>
            Notamos que você informou o valor final de cobraça diferente do
            valor de serviço.
          </p>
          <p>
            Valor do serviço: <b>{{ maintenance?.values?.total | currency }}</b>
          </p>
          <p>
            Valor total da cobrança: <b>{{ charge_value | currency }}</b>
          </p>
          <p>Deseja continuar?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialogConfirm = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="generateCharge()">
            Gerar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { api } from "../../../../api";
import moment from "moment-timezone";

export default {
  name: "IziManModalGerarCobranca",
  emits: ["charge"],
  data() {
    return {
      dialog: false,
      dialogConfirm: false,
      valid: true,
      id: null,
      maintenance: null,
      picker_installments: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      installments: 0,
      charge_value: 0,
      total_value: 0,
      no_value: false,
      form: {
        fields: {
          charge: {
            client: null,
            maintenance: null,
            total_installments: null,
            payments: [],
          },
        },
        rules: {
          value: [(v) => !!v || "Necessário preencher o campo de valor"],
          date: [
            (v) => !!v || "Necessário preencher o campo de data da parcela",
          ],
        },
      },
    };
  },
  methods: {
    onClose(event) {
      !event && this.closeModal();
    },

    async show(id) {
      this.id = id;
      const manutencao = await api.get("/maintenance/" + id);

      this.maintenance = Object.assign({}, manutencao.data);

      this.total_value = this.maintenance.values.total;

      this.dialog = true;
    },

    generate() {
      if (!!this.installments && this.$refs.form.validate())
        if (this.total_value != 0) this.dialogConfirm = true;
        else this.generateCharge();
    },

    async generateCharge() {
      const client = this.maintenance.client._id;
      const maintenance = this.id;
      const installments = this.installments;

      const body = this.form.fields.charge.payments.map((payment) => {
        return { ...payment, client, maintenance, installments };
      });

      const response = await api.post("/charge", body);

      if (response.data) {
        this.$root.GlobalMessage.success("Operação realizada com sucesso!");

        this.closeModal();
        this.$emit("charge");
      }
    },

    async finishWithOutCharge() {
      const response = await api.put(
        `/maintenance/${this.id}/finishWithOutCharge`
      );

      if (response.data) {
        this.$root.GlobalMessage.success(
          "Manutenção foi finalizada a nenhum cobrança foi gerada!"
        );

        this.closeModal();
        this.$emit("charge");
      }
    },

    generateForm() {
      this.form.fields.charge.payments = [];
      this.total_value = 0;
      const automatic_payment_value =
        this.maintenance.values.total / this.installments;
      for (let index = 0; index < this.installments; index++) {
        const obj = {
          installment: index + 1,
          payment_date: moment(new Date())
            .add(index, "months")
            .format("YYYY-MM-DD"),
          payment_date_format: null,
          payment_value: automatic_payment_value,
        };

        obj.payment_date_format = this.formatDate(obj.payment_date);

        this.form.fields.charge.payments.push(obj);
      }
    },

    changeDate(item, index) {
      this.form.fields.charge.payments[index].payment_date_format =
        this.formatDate(item.payment_date);
    },

    recalculateTotal() {
      this.total_value = this.maintenance.values.total;
      this.charge_value = 0;

      this.form.fields.charge.payments.forEach((element) => {
        this.charge_value += element.payment_value;
      });

      this.total_value -= this.charge_value;
    },

    closeModal() {
      this.dialog = false;
      this.dialogConfirm = false;
      this.installments = 0;
      this.form.fields.charge.payments = [];
    },

    formatDate(date) {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style scoped>
.custom-toolbar-total-value {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
