<template>
  <div class="elevation-5" v-if="maintenance && company">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <table id="element-to-convert">
      <tr>
        <td colspan="4" style="text-align: center">
          <div
            style="
              display: inline-flex;
              align-items: center;
              justify-content: space-evenly;
              width: 100%;
            "
          >
            <img src="../assets/restec.png" height="100%" />
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
              "
            >
              <p style="font-weight: bold; font-size: large">
                {{ company.corporate_name }}
              </p>
              <p>
                {{ company.address.street }} -
                {{ company.address.neighborhood }} - {{ company.address.state }}
                <br />
                Telefone: {{ company.phone_number }} Email: {{ company.email }}
              </p>
              <p style="font-weight: bold; font-size: large" class="red--text">
                RAT - RELATÓRIO DE ASSISTÊNCIA TÉCNICA RESTEC - Nº {{ maintenance.number_os_format }}
              </p>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          Nome Fantasia: <br />
          <b>
            {{ maintenance.client.fantasy_name }}
          </b>
        </td>
        <td>
          CNPJ: <br />
          <b>{{ maintenance.client.cnpj }}</b>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          Razão social: <br />
          <b>{{ maintenance.client.corporate_name }}</b>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          Nome Responsavel: <br />
          <b>{{ maintenance.client.responsable_name }}</b>
        </td>
        <td>
          Telefone: <br />
          <b>{{ maintenance.client.phone_number }}</b>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          Endereço: <br />
          <b>{{ maintenance.client.address.street }}</b>
        </td>
      </tr>
      <tr>
        <td width="25%">
          Bairro: <br />
          <b>{{ maintenance.client.address.neighborhood }}</b>
        </td>
        <td width="25%">
          CEP: <br />
          <b>{{ maintenance.client.address.zip_code }}</b>
        </td>
        <td width="25%">
          Cidade: <br />
          <b>{{ maintenance.client.address.city }}</b>
        </td>
        <td width="25%">
          Estado: <br />
          <b>{{ maintenance.client.address.state }}</b>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          Tipo de serviço: <br />
          <v-radio-group
            v-model="maintenance.service_type"
            readonly
            row
            class="justify-center"
          >
            <v-radio label="CORRETIVA" value="CORRETIVA"></v-radio>
            <v-radio label="PREVENTIVA" value="PREVENTIVA"></v-radio>
            <v-radio label="ORÇAMENTO" value="ORÇAMENTO"></v-radio>
            <v-radio label="GARANTIA" value="GARANTIA"></v-radio>
          </v-radio-group>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          Equipamento: <br />
          <b>{{ maintenance.equipment.map((x) => x.name).join(" | ") }}</b>
        </td>
        <td>
          Modelo: <br />
          <b>{{ maintenance.equipment.map((x) => x.model).join(" | ") }}</b>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          Fabricante <br />
          <b>{{ maintenance.equipment.map((x) => x.maker).join(" | ") }}</b>
        </td>
        <td>
          Voltagem <br />
          <b>{{ maintenance.equipment.map((x) => x.voltage).join(" | ") }}</b>
        </td>
        <td>
          Nº Série <br />
          <b>{{
            maintenance.equipment.map((x) => x.serial_number).join(" | ")
          }}</b>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          Problema alegado pelo cliente: <br />
          <b>{{ maintenance.issue_reported }}</b>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          Problema encontrado: <br />
          <b>{{ maintenance.issue_found ?? "Não informado" }}</b>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          Atividades realizadas: <br />
          <b>{{ maintenance.executed_activity ?? "Não informado" }}</b>
        </td>
      </tr>
      <tr v-if="!!maintenance.item?.length">
        <td>Quantidade</td>
        <td>Descrição</td>
        <td>Preço unitário</td>
        <td>Valor total</td>
      </tr>

      <tr v-for="(item, i) in maintenance.item" :key="i">
        <td>
          <b>{{ item?.quantity }}</b>
        </td>
        <td>
          <b>{{ item.name }}</b>
        </td>
        <td>
          <b>{{ item.value | currency }}</b>
        </td>
        <td>
          <b>{{
            (item?.quantity ? item.value * item.quantity : item.value)
              | currency
          }}</b>
        </td>
      </tr>

      <tr>
        <td>Visita</td>
        <td>
          <b>{{ maintenance.values?.visit ?? 0 | currency }}</b>
        </td>
        <td rowspan="2" colspan="3">
          <b
            >Declaramos para os devidos fins, u os services acima descritos
            foram devidamente fetuados.</b
          >
        </td>
      </tr>

      <tr>
        <td>Mão de obra</td>
        <td>
          <b>{{ maintenance.values?.labour ?? 0 | currency }}</b>
        </td>
      </tr>

      <tr>
        <td>Despesas</td>
        <td>
          <b>{{ maintenance.values?.expenses ?? 0 | currency }}</b>
        </td>
        <td colspan="2">
          <div style="width: 100%; display: inline-flex">
            <p>Assinatura do cliente:</p>
            <img
              v-if="maintenance.signature"
              height="100px"
              :src="'data:image/png;base64, ' + this.maintenance.signature"
            />
          </div>
        </td>
      </tr>

      <tr>
        <td>Materiais</td>
        <td>
          <b>{{ maintenance.values?.material ?? 0 | currency }}</b>
        </td>
        <td colspan="2">
          <div style="width: 100%; display: inline-flex">
            <p>Assinatura do Técnico:</p>
            <img
              v-if="maintenance.signature"
              height="100px"
              :src="
                'data:image/png;base64, ' +
                this.maintenance.responsables[0].signature
              "
            />
          </div>
        </td>
      </tr>

      <tr>
        <td>Desconto</td>
        <td>
          <b>{{ maintenance.values?.discount ?? 0 | currency }}</b>
        </td>
        <td style="background-color: #4caf50" class="white--text">
          <b>Total</b>
        </td>
        <td style="background-color: #4caf50" class="white--text">
          <b>{{ maintenance.values?.total ?? 0 | currency }}</b>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { api } from "../api";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      maintenance: null,
      company: null,
      overlay: true,
    };
  },
  methods: {
    async getMaintenance(id) {
      const response = await api.get(`maintenance/${id}`);
      if (response.data) this.maintenance = response.data;
      this.maintenance.signature = this._arrayBufferToBase64(
        this.maintenance.signature.data
      );

      this.maintenance.responsables[0].signature = this._arrayBufferToBase64(
        this.maintenance.responsables[0].signature.data
      );
    },

    async getCompany() {
      const response = await api.get(`company/token`);
      if (response.data) this.company = response.data;
    },

    _arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
  },
  beforeMount() {
    this.getCompany();
    if (this.$route.params.id) this.getMaintenance(this.$route.params.id);
  },

  mounted() {
    setTimeout(() => {
      const element = document.getElementById("element-to-convert");

      html2canvas(element).then((canvas) => {
        const pdf = new jsPDF();
        pdf.addImage(canvas.toDataURL("image/png"), "PNG", 10, 10, 180, 0);
        pdf.save(`${this.maintenance.number_os_format}.pdf`);

        this.overlay = false;

        window.close()
      });
    }, 3000);
  },
};
</script>

<style lang="css" scoped>
table,
td {
  padding: 10px;
  border: 1px solid black !important;
  border-collapse: collapse;
}
</style>
