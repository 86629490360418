<template>
  <div>
    <div class="display-title-page">
      <div class="ml-2">
        <div class="title mb-1">Tabela de cobranças</div>
        <div class="caption mb-3">
          Página que irá conter todas as cobranças geradas dentro do sistema
        </div>
      </div>
    </div>

    <TabelaCobrancas ref="tabelaCobrancas" />
  </div>
</template>

<script lang="ts">
import TabelaCobrancas from "../tables/TabelaCobrancas.vue";
export default {
  name: "IziManTabTabelaCobranca",
  components: { TabelaCobrancas },
  data() {
    return {};
  },
  methods: {},
};
</script>
