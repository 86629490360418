<template>
  <div>
    <v-stepper v-model="steps" vertical>


      <v-stepper-step :complete="steps > 1" step="1">
        Informa os responsáveis pela O.S.
      </v-stepper-step>

      <v-stepper-content step="1">
        <FormularioResponsavel @next="this.next" @before="this.before"/>
      </v-stepper-content>



      <v-stepper-step :complete="steps > 2" step="2">
        Verifica o melhor dia para a realização da O.S.
      </v-stepper-step>

      <v-stepper-content step="2">
        <FormularioDataExecucao @next="this.next" @before="this.before" />
      </v-stepper-content>
    </v-stepper>
    <Notificacao ref="notificacao" />
  </div>
</template>
<script lang="ts">
import EnumStep from "../../../../enums/StepManutencao";
import FormularioDataExecucao from "../forms/FormularioDataExecucao.vue";
import Notificacao from "../../../../components/Notificacao.vue";
import  { api } from "../../../../api";
import FormularioResponsavel from "../forms/FormularioResponsavel.vue";

export default {
  name: "IziManManutencaoCriar",
  emits: ['save'],
  props: ['id'],
  components: { FormularioDataExecucao, Notificacao, FormularioResponsavel },
  data() {
    return {
      steps: 1,
      EnumStep,
      form: {
        fields: {
          responsables: null,  
          expected_maintenance_date: null,
        },
      },
    };
  },
  methods: {
    async next(fields) {
      this.form.fields = Object.assign(this.form.fields, fields);
      if (this.form.fields.expected_maintenance_date != null) {
        try {
          const response = await api.put(`/maintenance/${this.id}`, {
            ...this.form.fields,
            step: EnumStep.PENDENTE_EXECUCAO,
          });
          if (response.data) {
            this.$root.GlobalMessage.success("Operação realizada com sucesso!");
            this.$emit('save');
          } else this.$root.GlobalMessage.error("Erro ao selecionar data!");
        } catch (err) {
          this.$root.GlobalMessage.error(
            "Erro ao realizar requisição, entre em contato com o administrador!"
          );
        }
      }

      if (this.steps !== 2) this.steps++;
    },
    before() {
      this.steps === 1 ? this.$emit('before') : this.steps--;
    },
  },
};
</script>

<style scoped>
.v-stepper {
  box-shadow: none !important;
}
</style>
