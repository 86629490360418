<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Filtrar</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-2">
              <v-col class="py-0" cols="12" sm="6">
                <v-text-field
                  v-model="form.fields.fantasy_name"
                  outlined
                  label="Nome fantasia"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" sm="6">
                <v-text-field
                  v-mask="'##.###.###/####-##'"
                  v-model="form.fields.cnpj"
                  outlined
                  label="CNPJ"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" sm="6">
                <v-text-field
                  v-model="form.fields.corporate_name"
                  outlined
                  label="Razão social"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" sm="6">
                <v-text-field
                  v-model="form.fields.email"
                  outlined
                  label="E-mail"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" sm="8">
                <v-text-field
                  v-model="form.fields.responsable_name"
                  outlined
                  label="Nome do responsável"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" sm="4">
                <v-text-field
                  v-mask="'(##) #####-####'"
                  v-model="form.fields.phone_number"
                  outlined
                  label="Celular"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Fechar </v-btn>
          <v-btn raised color="primary" @click="filtrar">
            Filtrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "IziManDialogFiltroCliente",
  data: () => ({
    dialog: false,
    form: {
      fields: {
        cnpj: "",
        fantasy_name: "",
        corporate_name: "",
        email: "",
        responsable_name: "",
        phone_number: "",
      },
    },
  }),
  methods: {
    show() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    filtrar() {
      this.$emit("filtrar", this.form.fields);
      this.close();
    },
  },
};
</script>
