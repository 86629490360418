<template>
  <div>
    <apexchart
      type="donut"
      :options="chartOptions"
      :series="clients"
    ></apexchart>
  </div>
</template>

<script>
import { api } from "../../../../api";
export default {
  name: "IziManClienteChart",
  data() {
    return {
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
      chartOptions: {
        type: "donut",
      },
      clients: [],
    };
  },
  methods: {
    async getData() {
      const response = await api.get("/maintenanceGroupClient");

      if (response.data) {
        this.chartOptions = {
          type: "donut",
          labels: response.data.map((data) => data.client.fantasy_name),
        };
        this.clients = response.data.map((data) => data.quantity);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
