<template>
  <div>
    <div class="display-title-page">
      <div class="ml-2">
        <div class="title mb-1">Listagem das manutenções finalizadas</div>
        <div class="caption mb-3">
          Listagem com todas as manutenções finalizadas que estão pendente a
          validação dos valores e geração da cobrança
        </div>
      </div>
    </div>

    <v-row no-gutters>
      <v-col
        v-for="(manutencao, index) in list"
        :key="index"
        class="my-1"
        cols="12"
        sm="12"
        md="6"
      >
        <CardManutencaoCobranca
          :manutencao="manutencao"
          class="mx-1"
          @reload="reset"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { api } from "../../../../api";
import CardManutencaoCobranca from "../card/CardManutencaoCobranca.vue";
import EnumStep from "../../../../enums/StepManutencao";

export default {
  name: "IziManTabGerarCobranca",
  components: { CardManutencaoCobranca },
  data() {
    return {
      list: [],
      query: {
        step: [EnumStep.PENDENTE_REVISAO],
        isNotMontlhy: true,
      },
      EnumStep,
    };
  },
  methods: {
    async getItens() {
      const response = await api.get("/maintenance", {
        params: { ...this.query },
      });

      if (response.data) {
        this.list = this.list.concat(response.data);
      } else this.$root.GlobalMessage.error("Erro ao encontrar itens");

      this.disabledLoadMore = response.data.length < 8;
    },

    reset() {
      this.list = [];
      this.query = {
        step: [EnumStep.PENDENTE_REVISAO],
        isNotMontlhy: true,
      };

      this.getItens();
    },
  },
  beforeMount() {
    this.getItens();
  },
};
</script>
