<template>
    <div>
        <v-card>
            <v-card-title v-if="!loading">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
                    hide-details></v-text-field>
            </v-card-title>
            <v-data-table item-key="name" :items="list" :headers="headers" :loading="loading"
                :custom-filter="filterOnlyCapsText" :search="search" loading-text="Carregando... aguarde">
                <template v-slot:[`item.priority`]="{ item }">
                    <v-icon small v-if="item.priority" color="red">mdi-clipboard-alert</v-icon>
                    <v-icon small v-if="!item.priority" color="grey">mdi-clipboard-alert</v-icon>
                </template>
                <template v-slot:[`item.type`]="{ item }">
                    <v-chip v-if="item.type" small :color="item.type == 'ENTRADA' ? 'green' : 'red'" outlined label>
                        {{ item.type }}
                    </v-chip>
                </template>
                <template v-slot:[`item.value`]="{ item }">
                    {{ item.value | currency }}
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                    {{ item.created_at | moment("DD/MM/YYYY hh:mm") }}
                </template>
            </v-data-table>
        </v-card>


    </div>
</template>
  
<script lang="ts">
import { api } from "../../../../api";

export default {
    name: "IziManTabelaLivroCaixa",
    data() {
        return {
            search: "",
            list: [],
            headers: [
                { text: "Tipo registro", value: "type" },
                { text: "Valor", value: "value" },
                { text: "Responsavel", value: "responsable.name" },
                { text: "Observação", value: "observation" },
                { text: "Data", value: "created_at" },
            ],
            loading: false,
        };
    },
    methods: {
        reset() {
            this.list = [];
            this.getItens();
        },
        async getItens() {
            this.loading = true;
            const response = await api.get("/cashBook");
            if (response.data) {
                this.list = this.list.concat(response.data);
            } else this.$root.GlobalMessage.error("Erro ao encontrar itens");
            this.disabledLoadMore = response.data.length < 8;
            this.loading = false;
        },

        filterOnlyCapsText(value, search) {
            return value != null &&
                search != null &&
                typeof value === 'string' &&
                value.toString().toLocaleUpperCase().indexOf(search) !== -1
        },
    },

    beforeMount() {
        this.getItens();
    },
};
</script>
  