<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card v-if="Boolean(orcamento)">
      <v-card-title class="text-h6">
        Deseja realmente aprovar esse orçamento?
      </v-card-title>
      <v-card-text class="pb-0">
        Ao realizar a aprovação do orçamento
        <b>
          {{
            orcamento.maintenance.number_os_format
          }}
        </b>
        do cliente <b>{{ orcamento.maintenance.client.fantasy_name }}</b
        >, será gerados uma nova O.S. com os dados base do orçamento.

      </v-card-text>
      <v-card-actions>
        <v-btn color="green" block text @click="exec"> Realizar aprovação </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import  { api } from "../../../../api";
import moment from "moment-timezone";

export default {
  name: "IziManDialogAprovarOrcamento",
  data() {
    return {
      dialog: false,
      orcamento: null,
    };
  },

  methods: {
    show(orcamento = null) {
      this.orcamento = orcamento;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    async exec() {
      try {
        const response = await api.put(
          `/maintenanceBudget/${this.orcamento._id}/approved`,
          {
            approved: true,
            approved_date: moment.tz("America/Sao_Paulo").toDate(),
          }
        );

        if (response.data) {
          this.$root.GlobalMessage.success("Orçamento recusado com sucesso!");
          this.$emit("approved");
          this.close();
        } else this.$root.GlobalMessage.error("Erro ao recusar orçamento!");
      } catch (err) {
        this.$root.GlobalMessage.error(
          "Erro ao realizar requisição, entre em contato com o administrador!"
        );
      }
    },
  },
};
</script>
