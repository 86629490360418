<template>
  <div>
    <v-card>
      <v-card-title v-if="!loading">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        item-key="name"
        :items="list"
        :headers="headers"
        :loading="loading"
        :custom-filter="filterOnlyCapsText"
        :search="search"
        loading-text="Carregando... aguarde"
      >
        <template v-slot:[`item.priority`]="{ item }">
          <v-icon small v-if="item.priority" color="red"
            >mdi-clipboard-alert</v-icon
          >
          <v-icon small v-if="!item.priority" color="grey"
            >mdi-clipboard-alert</v-icon
          >
        </template>
        <template v-slot:[`item.cancelled`]="{ item }">
          <v-chip v-if="item.cancelled" small color="red" outlined label>
            CANCELADO
          </v-chip>
        </template>
        <template v-slot:[`item.service_type`]="{ item }">
          <v-chip small color="primary" outlined label>
            {{ item.service_type }}
          </v-chip>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | moment("DD/MM/YYYY hh:mm") }}
        </template>
        <template v-slot:[`item.step`]="{ item }">
          <v-chip small outlined :color="color[item.step]" label>
            {{ item.step }}
          </v-chip>
        </template>
        <template v-slot:[`item.responsables`]="{ item }">
          {{ item.responsables ? item?.responsables[0]?.name : "" }}
        </template>
        <template v-slot:[`item.expected_maintenance_date`]="{ item }">
          {{ item.expected_maintenance_date | moment("DD/MM/YYYY hh:mm") }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent="openDetail(item)"
              >
                <v-icon small>mdi-text-box-search</v-icon>
              </v-btn>
            </template>
            Detalhe da O.S.
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                x-small
                overlap
                left
                color="primary"
                :content="
                  item.maintenanceImpeditive.filter((x) => x.active).length
                "
                :value="item.maintenanceImpeditive.some((x) => x.active)"
              >
                <v-btn
                  :disabled="!!item.maintenance_date || !!item.cancelled"
                  icon
                  color="orange"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop.prevent="$refs.drawerManImpeditivo.show(item._id)"
                >
                  <v-icon small>mdi-tag</v-icon>
                </v-btn>
              </v-badge>
            </template>
            Cadastro e listagem de impeditivos
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!!item.maintenance_date || !!item.cancelled"
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent="$refs.dialogCancelarManutencao.show(item)"
              >
                <v-icon small>mdi-cancel</v-icon>
              </v-btn>
            </template>
            Cancelar ordem de serviço
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!item.maintenance_date || item.cancelled"
                icon
                color="blue"
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent="gerarRelatorio(item._id)"
              >
                <v-icon small>mdi-file-document</v-icon>
              </v-btn>
            </template>
            Geração de relatório
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <NavigationDrawerManutencaoImpeditivo
      ref="drawerManImpeditivo"
      @save="getItens"
    />
    <NavigationDrawerManutencaoDetalhe
      ref="drawerManDetalhe"
      @reload="getItens"
    />
    <DialogCancelarManutencao
      ref="dialogCancelarManutencao"
      @cancelled="getItens"
    />
  </div>
</template>

<script lang="ts">
import { api } from "../../../../api";
import DialogCancelarManutencao from "../dialogs/DialogCancelarManutencao.vue";
import NavigationDrawerManutencaoDetalhe from "../navigationDrawer/NavigationDrawerManutencaoDetalhe.vue";
import NavigationDrawerManutencaoImpeditivo from "../navigationDrawer/NavigationDrawerManutencaoImpeditivo.vue";

export default {
  name: "IziManConfigUsuario",
  components: {
    NavigationDrawerManutencaoImpeditivo,
    NavigationDrawerManutencaoDetalhe,
    DialogCancelarManutencao,
  },
  data() {
    return {
      search: "",
      list: [],
      color: {
        FINALIZADO: "blue-grey",
        COBRANÇA: "indigo",
        "A PAGAR": "lime",
        REVISÃO: "amber",
        DATA: "green",
        EXECUÇÃO: "cyan",
      },
      headers: [
        { text: "Prioridade", value: "priority" },
        { text: "Número O.S.", value: "number_os_format" },
        { text: "Cancelamento", value: "cancelled" },
        { text: "Tipo de serviço", value: "service_type" },
        { text: "Cliente", value: "client.fantasy_name" },
        { text: "Criado em", value: "created_at" },
        { text: "Etapa", value: "step" },
        { text: "Responsavel", value: "responsables" },
        { text: "Data Prevista", value: "expected_maintenance_date" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      loading: false,
    };
  },
  methods: {
    async getItens() {
      this.loading = true;
      const response = await api.get("/maintenance");
      if (response.data) {
        this.list = this.list.concat(response.data);
      } else this.$root.GlobalMessage.error("Erro ao encontrar itens");
      this.disabledLoadMore = response.data.length < 8;
      this.loading = false;
    },

    openDetail(manutencao) {
      if (manutencao.maintenanceImpeditive?.filter((x) => x.active).length) {
        this.$root.GlobalMessage.warning(
          "Essa O.S. possui bloqueios e por conta disso não será possivel dar continuidade a mesma"
        );
        return;
      }
      this.$refs.drawerManDetalhe.show(manutencao._id);
    },

    gerarRelatorio(id) {
      const url = `/#/OS/${id}`;
      window.open(url, "_blank");
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
  },

  beforeMount() {
    this.getItens();
  },
};
</script>
