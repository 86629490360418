/* eslint-disable */

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import Routes from './routes'
import Vue2Filters from 'vue2-filters'
import VueMoment from 'vue-moment'
require("moment/min/locales.min");
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'
import device from "vue-device-detector"
import titleMixin from './mixins/titleMixin'
import VueSignaturePad from 'vue-signature-pad';
import VueMask from 'v-mask'
import StoragePlugin from 'vue-web-storage';
import VueCookies from 'vue-cookies'
import VueApexCharts from 'vue-apexcharts'

Vue.component('v-text-field', VTextField)

var Vue2FiltersConfig = {
  capitalize: {
    onlyFirstLetter: false
  },
  number: {
    format: '0',
    thousandsSeparator: ',',
    decimalSeparator: '.'
  },
  bytes: {
    decimalDigits: 2
  },
  percent: {
    decimalDigits: 2,
    multiplier: 100,
    decimalSeparator: '.'
  },
  currency: {
    symbol: 'R$',
    decimalDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    showPlusSign: false
  },
  pluralize: {
    includeNumber: false
  },
  ordinal: {
    includeNumber: false
  }
}

Vue.use(device);
Vue.use(VueRouter);
Vue.use(Vue2Filters, Vue2FiltersConfig);
Vue.use(VueMoment);
Vue.use(VueMask);
Vue.use(StoragePlugin);
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts)

Vue.use(VueSignaturePad);
Vue.use(VCurrencyField, {
  locale: 'pt-BR',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
})
Vue.use(VueCookies, { expires: '365d' })
Vue.mixin(titleMixin)
Vue.filter('number_os', function (number_os, created_at) {
  if (!number_os) return ''
  number_os = number_os.toString()
  number_os = `#${number_os.padStart(5, "0")}`

  if (!created_at) created_at = ''
  else created_at = new Date(created_at).getFullYear()
  return `${number_os}/${created_at}`;
})

const router = new VueRouter({
  routes: Routes
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  render: h => h(App),
  router: router
}).$mount('#app')
