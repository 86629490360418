<template>
  <v-form ref="formAutoCompleteCliente" v-model="valid">
    <div class="text-right">
      <v-btn
        @click="$refs.navigationDrawerGerenciarCliente.show()"
        small
        class="pa-0 mb-1"
        text
        color="primary"
      >
        <v-icon>mdi-plus</v-icon>
        Novo cliente
      </v-btn>
    </div>
    <v-autocomplete
      outlined
      clearable
      v-model="form.fields.client"
      :rules="form.rules.clientRules"
      :loading="isLoading"
      :items="items"
      item-text="fantasy_name"
      item-value="_id"
      label="Selecione o cliente"
      @change="
        $emit('change', $event);
        monthly($event);
      "
      no-data-text="Nenhum dado encontrado"
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-on="on"
                    v-bind="attrs"
                    small
                    v-if="data.item.monthly"
                    class="mr-2"
                    color="blue darken-2"
                    >mdi-calendar-badge</v-icon
                  >
                </template>
                Cliente mensalista
              </v-tooltip>
              {{ data.item.fantasy_name }}</v-list-item-title
            >
            <v-list-item-subtitle
              v-html="data.item.address.street"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>

    <NavigationDrawerGerenciarCliente
      ref="navigationDrawerGerenciarCliente"
      @salvo="this.saveClient"
    />
  </v-form>
</template>

<script lang="ts">
import { api } from "../../../../api";
import NavigationDrawerGerenciarCliente from "../navigationDrawer/NavigationDrawerGerenciarCliente.vue";

export default {
  name: "IziManAutoCompleteCliente",
  components: { NavigationDrawerGerenciarCliente },
  data() {
    return {
      valid: true,
      isLoading: true,
      items: [],
      form: {
        fields: {
          client: null,
        },
        rules: {
          clientRules: [
            (v) => !!v || "Necessário preencher o campo de tipo de cliente",
          ],
        },
      },
    };
  },
  methods: {
    monthly(event) {
      this.$emit(
        "monthly",
        this.items.find((item) => item._id === event)?.monthly || false
      );
    },
    async getClients() {
      const response = await api.get("/client", { params: { active: true } });

      if (response.data) {
        this.items = response.data;
      } else this.$root.GlobalMessage.error("Erro ao encontrar clientes");

      this.isLoading = false;
    },

    async saveClient(event) {
      await this.getClients();
      this.form.fields.client = event._id;
      this.$emit("change", event);
    },
  },
  beforeMount() {
    this.getClients();
  },
};
</script>
