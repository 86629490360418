<template>
  <v-form ref="formAutoCompleteUsuario" v-model="valid">
    <v-autocomplete outlined chips clearable :items="items" v-model="form.fields.user"
      :rules="form.rules.userRules" label="Responsáveis" item-text="name" item-value="_id"
      hint="Só serão exibidos os usuários que possuem assinatura e permissão para realizar o serviço"
      :persistent-hint="true" no-data-text="Nenhum dado encontrado" @change="$emit('change', $event)">

      <template v-slot:selection="data">
        <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
          @click:close="remove(data.item)">
          <Avatar left :item="data.item.name" />

          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-avatar>
            <Avatar :item="data.item.name" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle v-html="data.item.nick_name"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>

  </v-form>
</template>

<script lang="ts">

import { api } from '../api';
import Avatar from './Avatar.vue';

export default {
  name: "IziManAutoCompleteUsuario",
  components: { Avatar },
  data() {
    return {
      valid: true,
      isLoading: true,
      items: [],
      form: {
        fields: {
          user: null,
        },
        rules: {
          userRules: [
            v => !!v || "Necessário preencher o campo de tipo de usuário",
          ],
        }
      },
    };
  },
  methods: {
    async getUsers() {
      const response = await api.get("/user/responsable");
      if (response.data) {
        this.items = response.data;
      }
      else
        this.$root.GlobalMessage.error("Erro ao encontrar usuários");
      this.isLoading = false;
    },

    remove(item) {
      const index = this.form.fields.user.indexOf(item._id)
      if (index >= 0) this.form.fields.user.splice(index, 1)
    },
  },
  beforeMount() {
    this.getUsers();
  }
}
</script>