<template>
  <v-dialog persistent v-model="dialog" max-width="500">
    <v-card>
      <v-card-text class="pb-0">
        <div class="ma-8 pt-8">
          <v-img
            src="../assets/reset_password.svg"
            contain
            height="200"
          ></v-img>
        </div>
        <p>
          Notamos que essa é a primeira vez que você esta acessando com essa
          senha.
        </p>
        <p>Precisamos que você redefina uma senha..</p>
        <v-form ref="form" v-model="valid">
          <v-text-field
            class="mt-2"
            v-model="form.fields.password"
            :rules="form.rules.passwordRules"
            outlined
            type="password"
            label="Senha nova"
          ></v-text-field>
          <v-text-field
            class="mt-2"
            v-model="form.fields.confirm_password"
            :rules="form.rules.confirm_passwordRules"
            outlined
            type="password"
            label="Confirmar senha nova"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" text @click="exec"> Alterar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import  { api } from "../api";

export default {
  name: "IziManResetarSenha",
  data() {
    return {
      valid: true,
      dialog: false,
      form: {
        fields: {
          password: "",
          confirm_password: "",
        },
        rules: {
          passwordRules: [
            (v) => !!v || "Necessário preencher o campo de senha",
          ],
          confirm_passwordRules: [
            (v) => !!v || "Necessário preencher o campo de confirmar senha",
            (v) =>
              v === this.form.fields.password ||
              "Senha e confirmar senha não conferem",
          ],
        },
      },
    };
  },
  methods: {
    show() {
      if (this.$route.path === "/") this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    async exec() {
      try {
        if (this.$refs.form.validate()) {
          const response = await api.post(`/user/firstLogin`, this.form.fields);

          if (response.data) {
            this.$root.GlobalMessage.success('Senha alterada, acessar o sistema com essa nova senha');
            this.close();
          } else this.$root.GlobalMessage.error("Erro ao cadastrar Usuário!");
        }
      } catch (err) {
        this.$root.GlobalMessage.error(
          "Erro ao realizar requisição, entre em contato com o administrador!"
        );
      }
    },
  },
};
</script>
