<template>
  <div>
    <div class="mb-3 display-title-page">
      <div class="ml-2">
        <div class="title mb-1">Listagem de manutenções</div>
        <div class="caption mb-3">
          Listagem com todas as manutenções em exeucução que irão fazer parte do
          seu dia a dia
        </div>
      </div>
      <div class="mr-2" style="display: inline-flex; align-items: center">
        <v-switch
          class="pt-0 mt-0 mr-2"
          v-model="visualizationMode"
          @change="this.changeVisualizationMode"
          :hint="`${
            visualizationMode
              ? 'Você está com a visualização detalhada ativa'
              : 'Você está com a visualização simplificada e agruapada ativa'
          }`"
          label="Modo visualização"
          persistent-hint
        ></v-switch>
        <v-btn
          raised
          color="primary"
          class="ml-1"
          @click="$refs.drawerManCriar.show()"
          ><v-icon left>mdi-plus</v-icon>Gerar O.S.</v-btn
        >
      </div>
    </div>

    <!-- <v-row class="mb-6" no-gutters>
      <v-col cols="12" sm="12"  class="ml-2">
        <div class="subtitle-1 mb-1">Filtros:</div>
        <v-chip-group column>
          <v-chip
            color="purple"
            v-for="filtro in filtros"
            :key="filtro.prop"
            close
            outlined
          >
            <b>{{ filtro.prop }}: </b>
            <span class="ml-1">{{ filtro.value }}</span>
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row> -->

    <v-row no-gutters v-if="visualizationMode">
      <v-col
        v-for="(manutencao, index) in list"
        :key="index"
        class="my-1"
        cols="12"
        sm="12"
        md="6"
      >
        <CardManutencao
          :manutencao="manutencao"
          class="mx-1"
          :detalhado="true"
          @reload="reset"
        />
      </v-col>
    </v-row>

    <div v-else>
      <div class="mt-2 mx-1">
        <p class="subtitle mb-1">
          <v-icon class="mr-2" color="primary">mdi-star-box-multiple</v-icon
          >Backlog
        </p>
        <v-divider class="mb-6"></v-divider>

        <v-row no-gutters>
          <v-col
            v-for="(manutencao, index) in listData"
            :key="index"
            class="my-1"
            cols="12"
            sm="12"
            md="2"
          >
            <CardManutencaoSimplificado
              :manutencao="manutencao"
              class="mx-1"
              :detalhado="true"
              @reload="reset"
            />
          </v-col>
        </v-row>
      </div>
      <div
        class="mt-6 mx-1"
        v-for="(agrupamento, index) in listExecucao"
        :key="index"
      >
        <p class="subtitle mb-1" v-if="agrupamento.isToday">
          <v-icon class="mr-2" color="green">mdi-calendar</v-icon>Previsão para
          execução hoje - {{ agrupamento.date | moment("DD/MM/YYYY") }}
        </p>
        <p class="subtitle mb-1" v-else-if="agrupamento.isLate">
          <v-icon class="mr-2" color="red">mdi-calendar-alert</v-icon>Atrasado
          para execução desde - {{ agrupamento.date | moment("DD/MM/YYYY") }}
        </p>
        <p class="subtitle mb-1" v-else>
          <v-icon class="mr-2" color="blue">mdi-calendar-blank</v-icon>Pendente
          execução - {{ agrupamento.date | moment("DD/MM/YYYY") }}
        </p>
        <v-divider class="mb-6"></v-divider>

        <v-row no-gutters>
          <v-col
            v-for="(manutencao, index) in agrupamento.list"
            :key="index"
            class="my-1"
            cols="12"
            sm="12"
            md="2"
          >
            <CardManutencaoSimplificado
              :manutencao="manutencao"
              class="mx-1"
              :detalhado="true"
              @reload="reset"
            />
          </v-col>
        </v-row>
      </div>
    </div>

    <v-skeleton-loader
      v-if="loading"
      v-bind="attrs"
      type="table-cell, heading, table-row-divider, paragraph, table-row-divider, table-cell"
    ></v-skeleton-loader>

    <div v-if="!loading && !list.length" class="text-center mt-2">
      Nenhum dado encontrado
    </div>

    <!-- <div class="text-center mt-2">
      <v-btn
        color="primary"
        outlined
        :disabled="disabledLoadMore"
        @click="this.loadMore"
        >Carregar mais</v-btn
      >
    </div> -->

    <NavigationDrawerManutencaoCriar ref="drawerManCriar" @reload="reset" />
  </div>
</template>

<script lang="ts">
import moment from "moment-timezone";
import EnumStep from "../../../../enums/StepManutencao";
import CardManutencao from "../../components/card/CardManutencao.vue";
import NavigationDrawerManutencaoCriar from "@/pages/manutencao/components/navigationDrawer/NavigationDrawerManutencaoCriar.vue";
import { api } from "../../../../api";
import CardManutencaoSimplificado from "../card/CardManutencaoSimplificado.vue";

export default {
  name: "IziManTabManutencaoListagem",
  title: "Listagem de manutenções",
  components: {
    CardManutencao,
    NavigationDrawerManutencaoCriar,
    CardManutencaoSimplificado,
  },
  data() {
    return {
      filtros: [
        {
          prop: "Número O.S.",
          value: "#00019/2023",
        },
        {
          prop: "Cancelada",
          value: "Não",
        },
      ],
      list: [],
      listData: [],
      listExecucao: [],
      loading: false,
      disabledLoadMore: false,
      visualizationMode: false,
      attrs: {
        class: "px-4 mx-1",
        elevation: 2,
      },
      query: {
        step: [EnumStep.PENDENTE_DATA, EnumStep.PENDENTE_EXECUCAO],
        notCancelled: true,
      },
      EnumStep,
    };
  },
  methods: {
    async getItens() {
      this.loading = true;
      const response = await api.get("/maintenance", {
        params: { ...this.query },
      });

      if (response.data) {
        this.list = this.list.concat(response.data);
        this.listData = this.listData.concat(
          response.data.filter((data) => data.step === "DATA")
        );
        this.listExecucao = this.group(
          response.data.filter((data) => data.step === "EXECUÇÃO")
        );
        console.log(this.listExecucao);
      } else this.$root.GlobalMessage.error("Erro ao encontrar itens");

      this.disabledLoadMore = response.data.length < 8;
      this.loading = false;
    },

    reset() {
      this.list = [];
      this.listData = [];
      this.listExecucao = [];
      this.query = {
        step: [EnumStep.PENDENTE_DATA, EnumStep.PENDENTE_EXECUCAO],
        notCancelled: true,
      };

      this.getItens();
    },

    loadMore() {
      this.getItens();
    },

    group(items) {
      const groupedItems = [];

      items.forEach((item) => {
        const date = moment(item.expected_maintenance_date).format(
          "YYYY-MM-DD"
        );

        const isLate = moment().isAfter(item.expected_maintenance_date);
        const isToday = this.isToday(item.expected_maintenance_date);

        if (!groupedItems.find((x) => x.date == date)) {
          groupedItems.push({ date, isLate, isToday, list: [] });
        }

        groupedItems.find((x) => x.date == date).list.push(item);
      });

      return this.sortByDate(groupedItems);
    },

    changeVisualizationMode(event) {
      this.$cookies.set("flagCardMaintenance", event);
    },

    isToday(date) {
      const today = moment().startOf("day");
      const dateToCheck = moment(date).startOf("day");

      return today.isSame(dateToCheck);
    },

    sortByDate(items) {
      return items.sort((a, b) => {
        const dateA = moment(a.date);
        const dateB = moment(b.date);

        if (dateA.isBefore(dateB)) {
          return -1;
        }
        if (dateA.isAfter(dateB)) {
          return 1;
        }
        return 0;
      });
    },
  },
  beforeMount() {
    this.getItens();
    this.visualizationMode =
      this.$cookies.get("flagCardMaintenance") === "true";
  },
};
</script>
