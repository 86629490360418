import axios from 'axios';
import https from 'https';

const httpsAgent = new https.Agent({
  rejectUnauthorized: false
});

const api = axios.create({
  baseURL: 'https://api.izi-maintenance.com', httpsAgent
});

const apiViaCep = axios.create({
  baseURL: 'https://viacep.com.br/ws/', httpsAgent, headers: { 'Access-Control-Allow-Origin': '*' }
});

//  baseURL: 'http://192.168.15.174:21001'
//  baseURL: 'http://192.168.15.97:21001'
//  baseURL: 'https://api.izi-maintenance.com'

api.interceptors.request.use(function (config) {
  const appLogged = localStorage.getItem('app_LoggedUser') !== null && localStorage.getItem('app_LoggedUser') !== '';

  if (appLogged) {
    const appLoggedStr = localStorage.getItem('app_LoggedUser').replaceAll('"', '');
    config.headers["x-access-token"] = appLoggedStr;
  }

  return config;
});

export { api, apiViaCep };