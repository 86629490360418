<template>
  <v-form class="pt-2" ref="form" v-model="valid" @submit="salvar()">
    <v-row class="mb-0">
      <v-col class="py-0" cols="12" sm="12" md="6">
        <v-select
          v-model="form.fields.type"
          :rules="form.rules.typeRules"
          outlined
          label="Tipo"
          :items="types"
          required
        >
        </v-select>
      </v-col>
      <v-col class="py-0" cols="12" sm="12" md="6">
        <v-text-field
          v-model="form.fields.name"
          :rules="form.rules.nameRules"
          outlined
          label="Nome"
          required
        >
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="12">
        <v-textarea
          v-model="form.fields.content"
          :rules="form.rules.contentRules"
          outlined
          label="Conteúdo"
        >
        </v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12">
        <v-btn
          :loading="loading"
          :disabled="loading"
          @click="this.salvar"
          color="primary"
          outlined
          >Salvar</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { api } from "../../../../api";
import { EnumTipoAuxiliar } from "../../../../enums/TipoAuxiliares";

export default {
  name: "IziManFormularioGerenciarUsuario",
  props: ["id"],
  data() {
    return {
      valid: true,
      loading: false,
      EnumTipoAuxiliar,
      types: [],
      form: {
        fields: {
          type: "",
          name: "",
          content: "",
        },
        rules: {
          typeRules: [(v) => !!v || "Necessário preencher o campo Tipo"],
          nameRules: [(v) => !!v || "Necessário preencher o campo Nome"],
          contentRules: [(v) => !!v || "Necessário preencher o campo Conteúdo"],
        },
      },
    };
  },
  methods: {
    async getHelper(id) {
      const response = await api.get(`helper/${id}`);
      if (response.data) {
        Object.assign(this.form.fields, response.data);
      } else this.$refs.GlobalMessage.error("Erro ao encontrar Auxiliar");
    },
    salvar() {
      if (this.$refs.form.validate()) {
        this.$emit("salvar", this.form.fields);
      }
    },
    montaComboTipos() {
      this.types = Object.entries(this.EnumTipoAuxiliar).map(
        ([value, text]) => ({
          text,
          value,
        })
      );
    },
  },
  beforeMount() {
    this.montaComboTipos();
  },
  watch: {
    id(value) {
      if (value) this.getHelper(value);
    },
  },
};
</script>

<style scoped>
.signature {
  border: 1px solid black;
}
</style>
