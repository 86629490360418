<template>
  <v-navigation-drawer
    v-model="drawer"
    right
    app
    temporary
    width="700"
    class="pa-4"
    v-if="manutencao?.number_os"
    @input="onClose"
  >
    <div>
      <p class="title" @click="close()">
        Detalhes da ordem de serviço
        <v-btn icon class="float-right">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </p>
      <v-divider class="mb-4"></v-divider>

      <div>
        <span class="text-overline text--primary">
          {{ manutencao.number_os_format }}
        </span>

        <div class="float-right mr-4">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="!manutencao.maintenance_date && !manutencao.cancelled"
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent="
                  $refs.dialogCancelarManutencao.show(manutencao)
                "
              >
                <v-icon small>mdi-cancel</v-icon>
              </v-btn>
            </template>
            Cancelar ordem de serviço
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                overlap
                left
                color="primary"
                :content="
                  manutencao.maintenanceImpeditive.filter((x) => x.active)
                    .length
                "
                :value="manutencao.maintenanceImpeditive.some((x) => x.active)"
              >
                <v-btn
                  v-if="!manutencao.maintenance_date && !manutencao.cancelled"
                  icon
                  color="orange"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop.prevent="
                    $refs.drawerManImpeditivo.show(manutencao._id)
                  "
                >
                  <v-icon small>mdi-tag</v-icon>
                </v-btn>
              </v-badge>
            </template>
            Cadastro e listagem de impeditivos
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="manutencao.maintenance_date"
                icon
                color="blue"
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent="gerarRelatorio(manutencao._id)"
              >
                <v-icon small>mdi-file-document</v-icon>
              </v-btn>
            </template>
            Geração de relatório
          </v-tooltip>
        </div>
        <div>
          <v-chip
            class="my-1"
            small
            color="red"
            outlined
            label
            v-if="manutencao.priority"
          >
            PRIORIDADE
          </v-chip>
          <v-chip class="my-1 ml-1" small color="primary" outlined label>
            {{ manutencao.service_type }}
          </v-chip>
          <v-chip
            class="my-1 ml-1"
            small
            :color="this.color[manutencao.step]"
            outlined
            label
          >
            {{ manutencao.step }}
          </v-chip>
        </div>
      </div>

      <div class="mt-4">
        <h3>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                small
                v-if="manutencao.client.monthly"
                class="mr-2"
                color="blue darken-2"
                >mdi-calendar-badge</v-icon
              >
            </template>
            Cliente mensalista
          </v-tooltip>
          {{ manutencao.client.fantasy_name }}
        </h3>
        <div class="subtitle pb-4">
          <small>
            <v-icon class="mr-2 text--primary" small>mdi-map-marker</v-icon>
            {{ manutencao.client.address?.street }},
            {{ manutencao.client.address?.number }}
          </small>
        </div>

        <div class="body-2 mb-2" v-if="manutencao.ref_os">
          <b>O.S. referência:</b>
          {{
            manutencao.ref_os.number_os |
              number_os(manutencao.ref_os.created_at)
          }}
        </div>

        <p class="body-2 mb-2">
          <b>Data Criação:</b>
          {{ manutencao.created_at | moment("DD/MM/YYYY hh:mm") }}
        </p>
        <p class="body-2 mb-2">
          <b>Problema relatado:</b>
          {{ manutencao.issue_reported }}
        </p>

        <v-chip
          v-for="equipamento in manutencao.equipment"
          :key="equipamento._id"
          class="mt-3 mr-1"
          small
          outlined
          label
        >
          {{ equipamento.name }} - {{ equipamento.model }}
        </v-chip>
      </div>

      <v-switch
        class="mb-5"
        :disabled="Boolean(manutencao.signature) || manutencao.cancelled"
        :messages="[
          'Realizando a marcação será prioritária a execução dessa ordem de serviço',
        ]"
        v-model="manutencao.priority"
        color="red"
        label="Prioridade"
        @change="changePriority"
      ></v-switch>

      <div v-if="Boolean(manutencao?.expected_maintenance_date)">
        <v-divider class="mb-2"></v-divider>

        <p class="mt-3">
          <Avatar :item="manutencao.responsables[0].name" />
          {{ manutencao.responsables[0].name }}
        </p>

        <p class="body-2 mt-3 mb-2">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                left
                color="red"
                v-bind="attrs"
                v-on="on"
                v-if="isLate(manutencao)"
                class="mr-2"
                >mdi-alert-circle</v-icon
              >
            </template>
            <span>Ordem de serviço esta atrasada</span>
          </v-tooltip>

          <b>Data Prevista Manuteção:</b>
          {{
            $moment(manutencao.expected_maintenance_date).format(
              "DD/MM/YYYY hh:mm"
            )
          }}
        </p>
      </div>

      <div v-if="manutencao?.signature">
        <v-divider></v-divider>

        <v-expansion-panels class="my-4" v-if="manutencao.item">
          <v-expansion-panel>
            <v-expansion-panel-header>Peças</v-expansion-panel-header>
            <v-expansion-panel-content>
              <TabelaPecas :pecas="manutencao.item" :hiddenAction="true" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="my-4" v-if="manutencao.item">
          <v-expansion-panel>
            <v-expansion-panel-header>Valores</v-expansion-panel-header>
            <v-expansion-panel-content>
              <FormularioAlteraValores
                ref="formularioAlteraValores"
                :values="manutencao.values"
                :id="manutencao._id"
                :permiteAlterar="manutencao.step === 'REVISÃO'"
                @valoresAlterados="getItem(manutencao._id)"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="my-4" v-if="manutencao.item">
          <v-expansion-panel>
            <v-expansion-panel-header
              >Assinatura cliente</v-expansion-panel-header
            >
            <v-expansion-panel-content class="text-center">
              <img
                height="150px"
                :src="'data:image/png;base64, ' + manutencao.signature"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <v-tabs v-model="tab" class="mt-2">
      <v-tab
        :disabled="
          (manutencao.step !== 'DATA' && manutencao.step !== 'EXECUÇÃO') ||
          manutencao.cancelled
        "
      >
        Etapas
      </v-tab>
      <v-tab> Histórico </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <StepperData
          v-if="manutencao.step == 'DATA'"
          ref="stepperData"
          @before="this.close"
          @save="this.save"
          :id="manutencao._id"
        />

        <StepperExecucao
          v-if="manutencao.step == 'EXECUÇÃO'"
          ref="stepperExecucao"
          @before="this.close"
          @save="this.save"
          :id="manutencao._id"
          :service_type="manutencao.service_type"
        />
      </v-tab-item>
      <v-tab-item>
        <TabHistorico
          ref="tabHistorico"
          :id="manutencao._id"
          :historico="manutencao.comments"
          @comentarioInserido="getItem(manutencao._id)"
        />
      </v-tab-item>
    </v-tabs-items>
    <DialogCancelarManutencao
      ref="dialogCancelarManutencao"
      @cancelled="
        $emit('reload');
        close();
      "
    />
  </v-navigation-drawer>
</template>

<script lang="ts">
import moment from "moment-timezone";
import StepperExecucao from "../stepper/StepperExecucao.vue";
import StepperData from "../stepper/StepperData.vue";
import TabelaPecas from "../../../manutencao/components/tables/TabelaPecas.vue";
import { api } from "../../../../api";
import Avatar from "@/components/Avatar.vue";
import TabHistorico from "../tab/TabHistorico.vue";
import FormularioAlteraValores from "../forms/FormularioAlteraValores.vue";
import DialogCancelarManutencao from "../dialogs/DialogCancelarManutencao.vue";

export default {
  name: "IziManNavigationDrawerManutencaoDetalhe",
  components: {
    StepperExecucao,
    StepperData,
    Avatar,
    TabelaPecas,
    TabHistorico,
    FormularioAlteraValores,
    DialogCancelarManutencao,
  },
  emits: ["reload"],
  data() {
    return {
      drawer: false,
      manutencao: null,
      dirty: false,
      tab: 0,
      color: {
        FINALIZADO: "blue-grey",
        COBRANÇA: "indigo",
        "A PAGAR": "lime",
        REVISÃO: "amber",
        DATA: "green",
        EXECUÇÃO: "cyan",
      },
    };
  },
  methods: {
    show(id) {
      this.manutencao = null;
      this.getItem(id);
      this.drawer = true;
    },

    close() {
      this.drawer = false;
    },

    onClose() {
      if (this.dirty) this.$emit("reload");

      this.dirty = false;
    },

    save() {
      this.$emit("reload");
      this.close();
    },

    async getItem(id) {
      const response = await api.get(`maintenance/${id}`);

      if (response.data) {
        this.manutencao = response.data;

        if (
          !["DATA", "EXECUÇÃO"].includes(this.manutencao.step) ||
          this.manutencao.cancelled
        )
          this.tab = 2;

        if (this.manutencao.signature)
          this.manutencao.signature = this._arrayBufferToBase64(
            this.manutencao.signature.data
          );
      } else this.$refs.GlobalMessage.error("Erro ao encontrar item");
    },

    async changePriority() {
      const response = await api.put(`maintenance/${this.manutencao._id}`, {
        priority: this.manutencao.priority,
      });

      if (response.data) {
        this.dirty = true;
        this.$root.GlobalMessage.success(
          `Manutencao foi ${
            this.manutencao.priority ? "PRIORIZADA" : "DESPRIORIZADA"
          } com sucesso!`
        );
      } else this.$root.GlobalMessage.error("Erro ao realizar operação!");
    },

    _arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },

    isLate(manutencao) {
      const executouServico = Boolean(manutencao?.executed_activity);
      const atrasado = moment().isAfter(manutencao?.expected_maintenance_date);
      if (!executouServico && atrasado) return true;
      return false;
    },

    gerarRelatorio(id) {
      const url = `/#/OS/${id}`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.v-stepper {
  box-shadow: none !important;
}

.my-text-button.v-btn {
  height: 17px !important;
}
</style>
