<template>
  <v-app :style="!$vuetify.theme.dark ? 'background-color: #f0f4f7' : ''">
    <BarraSuperior @drawer="abrirMenuLateral" v-if="this.$route.path !== '/'" />
    <BarraLateral v-if="this.$route.path !== '/'" ref="barraLateral" />

    <v-main app>
      <!-- <v-breadcrumbs class="mt-3" :items="items" divider="/"></v-breadcrumbs> -->
      <v-container fluid :class="{ 'pa-0': this.$route.path === '/usuario' }">
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <!-- <v-footer app v-if="this.$device.mobile">
      <BarraInferior v-if="(this.$route.path !== '/')" />
    </v-footer> -->

    <v-footer app v-if="this.$route.path !== '/'" :padless="true" fixed>
      <v-card flat tile width="100%" class="text-center">
        <v-card-text>
          {{ new Date().getFullYear() }} — <strong>IziCompany</strong>
        </v-card-text>
      </v-card>
    </v-footer>

    <LockScreen ref="lockScreen" />
    <GlobalMessage ref="GlobalMessage" />
  </v-app>
</template>

<script lang="ts">
import LockScreen from "./components/LockScreen.vue";
import BarraSuperior from "./components/BarraSuperior.vue";
import BarraLateral from "./components/BarraLateral.vue";
// import BarraInferior from "./components/BarraInferior.vue";
import GlobalMessage from "./components/GlobalMessage.vue";

export default {
  name: "App",
  components: { LockScreen, BarraSuperior, BarraLateral, GlobalMessage },
  data() {
    return {};
  },
  methods: {
    abrirMenuLateral() {
      this.$refs.barraLateral.open();
    },

    validaDadosUsuario() {
      if (
        !this.$localStorage.hasKey("user") &&
        !!this.$localStorage.hasKey("LoggedUser")
      ) {
        this.$refs.lockScreen.show();
      }
    },
  },
  mounted() {
    this.validaDadosUsuario();
    this.$root.GlobalMessage = this.$refs.GlobalMessage;
    if (this.$cookies.isKey("theme"))
      this.$vuetify.theme.dark = this.$cookies.get("theme") === "true";
  },
};
</script>

<style>
.v-main {
  margin-bottom: 56px;
}

.v-breadcrumbs {
  padding: 0;
}

.display-title-page {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
}
</style>
