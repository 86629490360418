<template>
    <v-navigation-drawer v-model="drawer" @input="onClose" right app temporary width="600" class="pa-4">
        <p class="title" @click="close()">
            Inserir lançamento no livro caixa
            <v-btn icon class="float-right">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </p>
        <v-divider class="mb-4"></v-divider>

        <v-form class="pt-2" ref="form" v-model="valid" @submit="salvar()">
            <v-row>
                <v-col class="py-0" cols="12" sm="12" md="8">

                    <v-select :value="form.fields.type" v-model="form.fields.type" :rules="form.rules.typeRules"
                        :items="types" outlined item-text="label" item-value="value" prepend-inner-icon="mdi-text-box"
                        label="Tipo de lançamento" single-line></v-select>

                </v-col>
                <v-col class="py-0" cols="12" sm="12" md="4">
                    <v-currency-field v-model="form.fields.value" :rules="form.rules.valueRules" outlined label="Valor"
                        required>
                    </v-currency-field>

                </v-col>

                <v-col cols="12">


                    <v-textarea clearable outlined label="Observações" v-model="form.fields.observation"
                        :rules="form.rules.observationRules">
                    </v-textarea>
                </v-col>

                <v-col class="py-0" cols="12">
                    <v-btn :loading="loading" :disabled="loading" @click="salvar()" color="primary" outlined>Salvar</v-btn>
                </v-col>
            </v-row>


        </v-form>
    </v-navigation-drawer>
</template>
  
<script>
import { api } from "../../../../api";

export default {
    name: "IziManNavigationDrawerLancamento",
    emits: ['salvo'],
    data() {
        return {
            id: null,
            drawer: false,
            valid: true,
            loading: false,
            types: [
                { label: 'Entrada', value: 'ENTRADA' },
                { label: 'Saída', value: 'SAIDA' }
            ],
            form: {
                fields: {
                    type: "",
                    value: "",
                    observation: "",
                },
                rules: {
                    typeRules: [(v) => !!v || "Necessário preencher o campo tipo"],
                    valueRules: [(v) => !!v || "Necessário preencher o campo de valor"],
                    observationRules: [(v) => !!v || "Necessário preencher o campo de observação"],
                },
            },
        };
    },
    methods: {
        show(id = null) {
            this.id = id;
            this.drawer = true;
        },
        close() {
            this.drawer = false;
        },

        onClose(event) {
            !event && this.$refs.form.reset();
        },
        async salvar() {
            if (this.form.fields.value <= 0) {
                this.$root.GlobalMessage.error("Não é possivel salvar sem informar um valor maior que 0");
                return;
            }
            if (this.$refs.form.validate()) {
                try {
                    const response = await api.post("/cashBook", this.form.fields)
                    if (response.data) {
                        this.$root.GlobalMessage.success("Operação realizada com sucesso!");
                        this.$refs.form.reset();
                        this.$emit("salvo", response.data);
                        this.close();
                    } else this.$root.GlobalMessage.error("Erro ao cadastrar Lançamento!");
                } catch (err) {
                    this.$root.GlobalMessage.error(
                        "Erro ao realizar requisição, entre em contato com o administrador!"
                    );
                }
            }
        },
    },
};
</script>
  