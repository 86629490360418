<template>
  <v-form ref="formAutoCompletePeca" v-model="valid">
    <div class="text-right">
      <v-btn
        @click="$refs.navigationDrawerGerenciarPeca.show()"
        small
        class="pa-0 mb-1"
        text
        color="primary"
      >
        <v-icon>mdi-plus</v-icon>
        Nova peça
      </v-btn>
    </div>
    <v-autocomplete
      outlined
      clearable
      multiple
      small-chips
      v-model="form.fields.item"
      :items="items"
      item-text="name"
      :item-value="this.select"
      @change="$emit('change', $event)"
      label="Selecione a(s) peça(s)"
      no-data-text="Nenhum dado encontrado"
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.value | currency }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>

    <NavigationDrawerGerenciarPeca
      ref="navigationDrawerGerenciarPeca"
      @salvo="this.saveItem"
    />
  </v-form>
</template>

<script lang="ts">
import { api } from "../../../../api";
import NavigationDrawerGerenciarPeca from "../navigationDrawer/NavigationDrawerGerenciarPeca.vue";

export default {
  name: "IziManAutoCompletePeca",
  components: { NavigationDrawerGerenciarPeca },
  emits: ["change"],
  data() {
    return {
      valid: true,
      items: [],
      form: {
        fields: {
          item: null,
        },
      },
    };
  },
  methods: {
    async getItens() {
      const response = await api.get("/item");

      if (response.data) {
        this.items = response.data;
      } else this.$root.GlobalMessage.error("Erro ao encontrar itens");
    },

    select(item) {
      item.quantity = 1;
      return item;
    },

    async saveItem(event) {
      await this.getItens();
      event.quantity = 1;
      const items = this.form.fields.item || [];
      this.form.fields.item = items.concat([event]);
      this.$emit("change", this.form.fields.item);
    },
  },
  beforeMount() {
    this.getItens();
  },
};
</script>
