<template>
  <v-form class="pt-2" ref="form" v-model="valid" @submit="salvar()">
    <v-row class="mb-0">
      <v-col class="py-0" cols="12" sm="12" md="7">
        <v-text-field v-model="form.fields.name" :rules="form.rules.nameRules" outlined label="Nome Completo" required>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="12" md="5">
        <v-text-field v-model="form.fields.nick_name" :rules="form.rules.nick_nameRules" outlined label="Apelido" required>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="12" md="4">
        <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field outlined v-model="form.fields.birthday" label="Data de nascimento"
              prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="form.fields.birthday" :rules="form.rules.birthdayRules" locale="pt-BR" no-title
            scrollable @input="form.fields.birthday = $event">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="py-0" cols="12" sm="12" md="8">
        <v-text-field v-model="form.fields.email" :rules="form.rules.emailRules" outlined label="E-mail" required>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="12">
        <v-radio-group class="mt-0" v-model="form.fields.gender" :rules="form.rules.genderRules">
          <template v-slot:label>
            <div>Gênero</div>
          </template>
          <v-radio label="Feminino" value="Feminino"></v-radio>
          <v-radio label="Masculino" value="Masculino"></v-radio>
          <v-radio label="Outro" value="Outro"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col class="py-0" cols="12" sm="12" md="6">
        <v-text-field v-mask="'(##) #####-####'" v-model="form.fields.phone_number" :rules="form.rules.phone_numberRules"
          outlined label="Celular" required>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="12" md="6">
        <v-select :value="form.fields.profile" v-model="form.fields.profile" :rules="form.rules.profileRules"
          :items="profiles" outlined item-text="name" item-value="_id" prepend-inner-icon="mdi-face-man-profile"
          label="Perfil" single-line></v-select>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col class="pt-0 mb-2" cols="12">
        <v-subheader>Endereço</v-subheader>

        <v-divider></v-divider>
      </v-col>

      <v-col class="py-0" cols="12" sm="4">
        <TextFieldCep @findAddress="alteraEndereco" :zip_code="form.fields.address.zip_code" />
      </v-col>
      <v-col class="py-0" cols="12" sm="8">
        <v-text-field v-model="form.fields.address.street" :rules="form.rules.streetRules" outlined label="Logradouro"
          required disabled>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="3">
        <v-text-field v-model="form.fields.address.number" :rules="form.rules.numberRules" outlined label="Número"
          required>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="3">
        <v-text-field v-model="form.fields.address.neighborhood" :rules="form.rules.neighborhoodRules" outlined
          label="Bairro" required disabled>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="3">
        <v-text-field v-model="form.fields.address.city" :rules="form.rules.cityRules" outlined label="Cidade" required
          disabled>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="3">
        <v-text-field v-model="form.fields.address.state" :rules="form.rules.stateRules" outlined label="Estado" required
          disabled>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12">
        <v-textarea v-model="form.fields.address.complement" outlined label="Complemento">
        </v-textarea>
      </v-col>
      <v-col cols="12" sm="12">
        <v-btn :loading="loading" :disabled="loading" @click="this.salvar" color="primary" outlined>Salvar</v-btn>
      </v-col>
    </v-row>

  </v-form>
</template>

<script>
import TextFieldCep from "@/components/TextFieldCep.vue";
import { api } from "../../../../api";

export default {
  name: "IziManFormularioGerenciarUsuario",
  components: { TextFieldCep },
  props: ["id"],
  data() {
    return {
      valid: true,
      loading: false,
      profiles: [],
      form: {
        fields: {
          name: "",
          nick_name: "",
          email: "",
          birthday: null,
          gender: null,
          profile: null,
          phone_number: "",

          address: {
            street: "",
            neighborhood: "",
            city: "",
            state: "",
            zip_code: "",
            number: "",
            complement: ""
          },
        },
        rules: {
          nameRules: [(v) => !!v || "Necessário preencher o campo nome"],
          nick_nameRules: [
            (v) => !!v || "Necessário preencher o campo apelido",
          ],
          emailRules: [(v) => !!v || "Necessário preencher o campo e-mail"],
          birthdayRules: [
            (v) => !!v || "Necessário preencher o campo data de nascimento",
          ],
          profileRules: [(v) => !!v || "Necessário preencher o campo perfil"],
          genderRules: [(v) => !!v || "Necessário preencher o campo gênero"],
          phone_numberRules: [
            (v) => !!v || "Necessário preencher o campo celular",
          ],
        },
      },
    };
  },
  methods: {
    async getUser(id) {
      console.log('id', id)
      const response = await api.get(`user/${id}`);
      if (response.data) {
        Object.assign(this.form.fields, response.data);

        console.log(this.form.fields);
      } else this.$refs.GlobalMessage.error("Erro ao encontrar usuário");
    },
    async getProfiles() {
      const response = await api.get(`profile`);
      if (response.data) {
        this.profiles = response.data;
      } else this.$refs.GlobalMessage.error("Erro ao encontrar perfis");
    },
    salvar() {
      if (this.$refs.form.validate()) {
        this.$emit("salvar", this.form.fields);
      }
    },
    alteraEndereco(value) {
      this.form.fields.address = Object.assign({}, value);
    },
  },
  beforeMount() {
    this.getProfiles();
  },
  watch: {
    id(value) {
      if (value) this.getUser(value);
    },
  },
};
</script>

<style scoped>
.signature {
  border: 1px solid black;
}
</style>
