<template>
  <div>
    <v-form ref="form" v-model="valid">
      <AutoCompleteUsuario
        ref="autoCompleteUsuario"
        @change="form.fields.responsables = $event"
      />

      <v-btn color="primary" outlined @click="this.next" :show-current="false">
        Avançar
      </v-btn>
      <v-btn text @click="$emit('before')"> Cancelar </v-btn>
    </v-form>
  </div>
</template>

<script lang="ts">
import AutoCompleteUsuario from "@/components/AutoCompleteUsuario.vue";

export default {
  name: "ResFormularioExecucao",
  components: { AutoCompleteUsuario },
  data() {
    return {
      valid: true,
      form: {
        fields: {
          responsables: null,
        },
      },
    };
  },
  methods: {
    next() {
      if (
        this.$refs.autoCompleteUsuario.$refs.formAutoCompleteUsuario.validate()
      ) {
        this.$emit("next", this.form.fields);
      }
    },
  },
};
</script>
