<template>
  <div>
    <div class="display-title-page">
      <div class="ml-2">
        <div class="title mb-1">Listagem de peças</div>
        <div class="caption mb-3">
          Listagem com todas as peças onde você pode colocar o valor que será
          usado no momento da geração da O.S.
        </div>
      </div>
      <div class="mr-2" style="display: inline-flex; align-items: center">
        <v-text-field
          label="Pesquisa rápida"
          outlined
          dense
          clearable
          :hide-details="true"
        >
        </v-text-field>
        <v-btn
          raised
          color="primary"
          class="ml-1"
          @click="$refs.navigationDrawerGerenciarPeca.show()"
          ><v-icon left>mdi-plus</v-icon>Cadastrar peça</v-btn
        >

        <v-btn raised color="info" class="ml-1"
          ><v-icon>mdi-filter</v-icon></v-btn
        >
      </div>
    </div>

    <div v-if="loading">
      <v-skeleton-loader
        v-for="(item, index) in [1, 2, 3, 4, 5]"
        v-bind="attrs"
        :key="index"
        type="list-item-two-line"
      ></v-skeleton-loader>
    </div>

    <div v-if="!loading && !list.length" class="text-center mt-2">
      Nenhum dado encontrado
    </div>

    <v-row no-gutters>
      <v-col
        v-for="(item, index) in list"
        :key="index"
        class="my-1"
        cols="12"
        sm="12"
        md="4"
        lg="2"
      >
        <v-card
          hover
          class="pa-2 mx-1"
          @click="$refs.navigationDrawerGerenciarPeca.show(item._id)"
        >
          <div class="item-avatar my-2">
            <v-icon>mdi-cog</v-icon>
            <span class="overline mt-2 text-center">
              {{ item.name }}
            </span>
            {{ item.value | currency }}
          </div>
        </v-card>
      </v-col>
    </v-row>

    <NavigationDrawerGerenciarPeca
      ref="navigationDrawerGerenciarPeca"
      @salvo="this.getItens"
    />
  </div>
</template>

<script lang="ts">
import { api } from "../../api";
import NavigationDrawerGerenciarPeca from "./components/navigationDrawer/NavigationDrawerGerenciarPeca.vue";

export default {
  name: "IziManPeca",

  title: "Peças",
  components: { NavigationDrawerGerenciarPeca },
  data() {
    return {
      fab: false,
      loading: false,
      attrs: {
        class: "ma-1",
        elevation: 2,
      },
      list: [],
    };
  },
  methods: {
    async getItens() {
      this.loading = true;
      const response = await api.get("/item");
      if (response.data) {
        this.list = response.data;
      } else this.$root.GlobalMessage.error("Erro ao encontrar itens");
      this.loading = false;
    },
  },
  beforeMount() {
    this.getItens();
  },
};
</script>

<style scoped>
/* This is for documentation purposes and will not be needed in your application */
.custom-floating.v-speed-dial {
  position: fixed;
  bottom: 64px;
}

.valores {
  width: 100%;
  display: inline-flex;
  justify-content: space-evenly;
}

.inside-valores {
  display: inline-flex;
}

.v-card__title {
  justify-content: center;
}

.v-list {
  padding: 0;
}

.item-avatar {
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.display-title-page {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>
