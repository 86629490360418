<template>
  <div>
    <apexchart
      type="bar"
      :options="chartOptions"
      :series="equipments"
    ></apexchart>
  </div>
</template>

<script>
import { api } from "../../../../api";

export default {
  name: "IziManEquipamentoChart",
  data() {
    return {
      chartOptions: {
        chart: {
          id: "vuechart-example",
        },
      },
      equipments: [],
    };
  },
  methods: {
    async getData() {
      const response = await api.get("/maintenanceGroupEquipment");

      if (response.data) {
        const data = response.data.map((data) => ({
          x: `${data.equipment.name} - ${data.equipment.model}`,
          y: data.quantity,
        }));

        this.equipments = this.equipments.concat([{ name: 'Quantidade', data: data }]);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
