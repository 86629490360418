<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title class="text-h5 text-center mb-1">
          Deseja realmente cancelar a parcela?
        </v-card-title>
        <v-card-text>
          <p>
            Data prevista pagamento:
            <b>{{ charge?.payment_date | moment("DD/MM/YYYY") }}</b>
          </p>
          <p>
            Valor pagamento:
            <b>{{ charge?.payment_value | currency }}</b>
          </p>
          <p class="mt-2">
            <v-textarea
              outlined
              label="Motivo do cancelamento"
              v-model="form.fields.cancelled_observation"
              :rules="form.rules.cancelled_observationRules"
              required
            ></v-textarea>
          </p>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-btn
            color="red darken-1"
            outlined
            block
            @click="cancelCharge(charge, false)"
            class="text-center"
          >
            Realizar o cancelamento
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { api } from "../../../../api";

export default {
  name: "IziManDialogCancelarCobranca",
  data() {
    return {
      valid: true,
      dialog: false,
      charge: null,
      form: {
        fields: {
          cancelled_observation: null,
        },
        rules: {
          cancelled_observationRules: [
            (v) =>
              !!v ||
              "Necessário preencher o campo de informar o motivo de cancelamento",
          ],
        },
      },
    };
  },

  methods: {
    show(charge = null) {
      this.charge = charge;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    async cancelCharge(charge) {
      if (this.$refs.form.validate()) {
        const body = { cancelled_observation: this.cancelled_observation };

        const response = await api.put(`/charge/${charge._id}/cancel`, body);

        if (response.data) {
          this.$root.GlobalMessage.success("Operação realizada com sucesso!");

          this.dialog = false;
          this.$emit("cancel");
        }
      }
    },
  },
};
</script>
