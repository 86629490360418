<template>
  <div>
    <TabManutencaoListagem v-if="tabs === 0" ref="tabManutencaoListagem" />
    <TabManutencaoTabela v-if="tabs === 1" ref="tabManutencaoTabela" />
  </div>
</template>

<script lang="ts">
import { EventBus } from "@/event-bus.js";
import TabManutencaoListagem from "./components/tab/TabManutencaoListagem.vue";
import TabManutencaoTabela from "./components/tab/TabManutencaoTabela.vue";
export default {
  name: "IziManManutencaoListagem",
  title: "Listagem de manutenções",
  components: { TabManutencaoListagem, TabManutencaoTabela },
  data() {
    return {
      tabs: 0,
    };
  },
  created() {
    EventBus.$on("tab-manutencao-mudou", (tab) => {
      this.tabs = tab;
    });
  },
};
</script>
