<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-menu
        ref="menu"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            v-model="dateFormat"
            label="Data da parcela"
            prepend-inner-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.fields.expected_maintenance_date"
          :rules="form.rules.expected_maintenance_dateRules"
          locale="pt-BR"
          :min="min"
          no-title
          scrollable
          @input="form.fields.expected_maintenance_date = $event"
          @change="formatDate()"
        >
        </v-date-picker>
      </v-menu>

      <v-text-field
        outlined
        label="Hora"
        type="time"
        v-model="form.fields.expected_maintenance_hour"
        :rules="form.rules.expected_maintenance_hourRules"
      ></v-text-field>

      <v-btn color="primary" outlined @click="this.next" :show-current="false">
        Selecionar Data
      </v-btn>
      <v-btn text @click="$emit('before')"> Anterior </v-btn>
    </v-form>
  </div>
</template>

<script lang="ts">
import moment from "moment-timezone";

export default {
  name: "IziManFormularioDataExecucao",
  data() {
    return {
      valid: true,
      dateFormat: new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
      form: {
        fields: {
          expected_maintenance_date: new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10),
          expected_maintenance_hour: "12:00",
        },
        rules: {
          expected_maintenance_dateRules: [
            (v) => !!v || "Necessário preencher o campo de data",
          ],
          expected_maintenance_hourRules: [
            (v) => !!v || "Necessário preencher o campo de data",
          ],
        },
      },
      min: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  methods: {
    next() {
      const data = {
        expected_maintenance_date: moment
          .tz(
            this.form.fields.expected_maintenance_date +
              " " +
              this.form.fields.expected_maintenance_hour,
            "America/Sao_Paulo"
          )
          .toDate(),
      };
      this.$emit("next", data);
    },
    formatDate() {
      const [year, month, day] =
        this.form.fields.expected_maintenance_date.split("-");
      this.dateFormat = `${day}/${month}/${year}`;
    },
  },
  beforeMount() {
    this.formatDate();
  },
};
</script>
