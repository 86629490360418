<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card v-if="Boolean(manutencao)">
      <v-card-title class="text-h6">
        Deseja realmente cancelar essa manutenção?
      </v-card-title>
      <v-card-text class="pb-0">
        Ao resetar o cancelamento da O.S.
        <b> {{ manutencao.number_os_format }}Leon </b>
        do cliente <b>{{ manutencao.client.fantasy_name }}</b
        >, não sera possivel reverter o processo e nem dar prosseguimento a
        manutenção.

        <v-form ref="form" v-model="valid" class="mt-4">
          <v-textarea
            clearable
            outlined
            name="input-7-4"
            label="Informe o motivo do cancelamento"
            v-model="form.fields.cancelled_observation"
            :rules="form.rules.cancelled_observationRules"
          >
          </v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" block text @click="exec">
          Realizar cancelamento
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { api } from "../../../../api";
import moment from "moment-timezone";

export default {
  name: "IziManDialogCancelarManutencao",
  data() {
    return {
      valid: true,
      dialog: false,
      manutencao: null,
      form: {
        fields: {
          cancelled_observation: null,
        },
        rules: {
          cancelled_observationRules: [
            (v) =>
              !!v ||
              "Necessário preencher o campo de informar o motivo de cancelamento",
          ],
        },
      },
    };
  },

  methods: {
    show(manutencao = null) {
      this.manutencao = manutencao;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    async exec() {
      try {
        if (this.$refs.form.validate()) {
          const response = await api.put(
            `/maintenance/${this.manutencao._id}`,
            {
              cancelled: true,
              cancelled_by: this.$localStorage.get("user")._id,
              cancelled_date: moment.tz("America/Sao_Paulo").toDate(),
              cancelled_observation: this.form.fields.cancelled_observation,
            }
          );

          if (response.data) {
            this.$root.GlobalMessage.success(
              "Manutenção cancelada com sucesso!"
            );
            this.$emit("cancelled");
            this.close();
          } else this.$root.GlobalMessage.error("Erro ao cancelar manutenção!");
        }
      } catch (err) {
        this.$root.GlobalMessage.error(
          "Erro ao realizar requisição, entre em contato com o administrador!"
        );
      }
    },
  },
};
</script>
