<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card v-if="user">
      <v-card-title class="text-h6">
        Deseja realmente {{ user.active ? "Inativar" : "Ativar" }} o usuário.
      </v-card-title>
      <v-card-text>
        Caso usuário <b>{{ user.nick_name }}</b> seja
        {{ user.active ? "inativo" : "ativo" }}, então ele
        {{
          user.active
            ? "perderá todos os acessos ao sistema"
            : "voltará a acessar todo conteúdo dentro do sistema"
        }}.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="user.active ? 'red' : 'green'" text @click="exec">
          {{ user.active ? "Inativar" : "Ativar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import  { api } from "../../../../api";

export default {
  name: "IziManDialogAtivaInativa",
  data() {
    return {
      user: null,
      dialog: false,
    };
  },
  methods: {
    show(user = null) {
      this.user = user;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    async exec() {
      try {
        const body = { active: !this.user.active };

        const response = await api.put(
          `/user/${this.user._id}`,
          body
        );

        if (response.data) {
          this.$root.GlobalMessage.success("Operação realizada com sucesso!");
          this.$emit("salvo");
          this.close();
        } else this.$root.GlobalMessage.error("Erro ao cadastrar Usuário!");
      } catch (err) {
        this.$root.GlobalMessage.error(
          "Erro ao realizar requisição, entre em contato com o administrador!"
        );
      }
    },
  },
};
</script>
