<template>
  <v-form ref="formAutoCompleteManutencao" v-model="valid">
    <v-autocomplete
      outlined
      clearable
      v-model="form.fields.manutencao"
      :rules="form.rules.manutencaoRules"
      :loading="isLoading"
      :items="items"
      item-text="number_os"
      item-value="_id"
      label="Selecione a O.S."
      @change="change"
      no-data-text="Nenhum dado encontrado"
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title>{{
              data.item.number_os_format
            }}</v-list-item-title>
            <v-list-item-subtitle
              v-html="data.item.client.fantasy_name"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
      <template v-slot:selection="data">
        <template
          >{{ data.item.number_os_format }}
          <b
            ><small class="ml-2">{{ data.item.client.fantasy_name }}</small></b
          >
        </template>
      </template>
    </v-autocomplete>
  </v-form>
</template>

<script lang="ts">
import { api } from "../../../../api";

export default {
  name: "IziManAutoCompleteManutencao",
  props: ["query"],
  data() {
    return {
      valid: true,
      isLoading: true,
      items: [],
      form: {
        fields: {
          manutencao: null,
        },
        rules: {
          manutencaoRules: [
            (v) => !!v || "Necessário preencher o campo de O.S.",
          ],
        },
      },
    };
  },
  methods: {
    async getManutencao() {
      const response = await api.get("/maintenance", { params: this.query });

      if (response.data) {
        this.items = response.data;
      } else this.$root.GlobalMessage.error("Erro ao encontrar manutencoes");

      this.isLoading = false;
    },

    change(event) {
      this.$emit(
        "change",
        this.items.find((item) => item._id === event)
      );
    },
  },
  beforeMount() {
    this.getManutencao();
  },
};
</script>
