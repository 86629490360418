<template>
  <div>
    <div class="display-title-page">
      <div class="ml-2">
        <div class="title mb-1">Relatórios de manutenção</div>
        <div class="caption mb-3">
          Aqui será possivel gerar os relatórios referentes a manutenções
          criadas dentro do sistema
        </div>
      </div>
    </div>

    <v-row>
      <v-col sm="12" md="3">
        <v-card>
          <v-list dense>
            <v-subheader>OPÇÕES DE RELATÓRIOS</v-subheader>
            <v-list-item-group v-model="selectedReport" color="primary">
              <v-list-item v-for="(item, i) in reports" :key="i">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col sm="12" md="9">
        <v-card>
          <v-card-text>
            <FormularioFiltroRelatorioManutencao
              ref="formularioFiltroRelatorioManutencao"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import FormularioFiltroRelatorioManutencao from "./components/forms/FormularioFiltroRelatorioManutencao.vue";

export default {
  name: "IziManRelatorioManutencao",
  title: "Relatório de manutenções",
  components: { FormularioFiltroRelatorioManutencao },
  data() {
    return {
      valid: true,
      selectedReport: 0,
      reports: [
        { text: "Tabela manutenções", icon: "mdi-clock", exec: () => {} },
        { text: "Agrupado por cliente", icon: "mdi-account", exec: () => {} },
        { text: "Conversions", icon: "mdi-flag", exec: () => {} },
      ],
    };
  },
};
</script>
