<template>
  <div>
    <div class="display-title-page">
      <div class="ml-2">
        <div class="title mb-1">Listagem de Perfis</div>
        <div class="caption mb-3">
          Listagem com todos os os perfis que serão atribuidos aos usuários,
          <br />aqui você também pode configurar as regras de cada perfil, o que
          cada um pode fazer
        </div>
      </div>
      <div class="mr-2">
        <v-btn
          raised
          color="primary"
          class="ml-1"
          @click="$refs.drawerManCriar.show()"
          ><v-icon left>mdi-plus</v-icon>Cadastrar perfil</v-btn
        >
        <v-btn raised color="info" class="ml-1"
          ><v-icon>mdi-filter</v-icon></v-btn
        >
      </div>
    </div>

    <v-card>
      <v-card-title v-if="!loading">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        item-key="name"
        :items="list"
        :headers="headers"
        :loading="loading"
        loading-text="Carregando... aguarde"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="blue"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            Editar informações de perfil
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
                @click="deleteItem(item)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
            Deletar perfil
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="orange"
                v-bind="attrs"
                v-on="on"
                @click="ruleItem(item)"
              >
                <v-icon small>mdi-book-cog</v-icon>
              </v-btn>
            </template>
            Editar permissões de acesso
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { api } from "../../api";

export default {
  name: "IziManConfigPerfil",

  title: "Configuração perfil",
  data() {
    return {
      search: "",
      list: [],
      headers: [
        { text: "Nome", value: "name" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      loading: false,
    };
  },
  methods: {
    async getItens() {
      this.loading = true;
      const response = await api.get("/profile");

      if (response.data) {
        this.list = response.data;
      } else this.$root.GlobalMessage.error("Erro ao encontrar itens");

      this.loading = false;
    },

    async editItem(item) {
      console.log(item);
    },

    async deleteItem(item) {
      console.log(item);
    },

    async ruleItem(item) {
      console.log(item);
    },
  },

  beforeMount() {
    this.getItens();
  },
};
</script>
