<template>
  <v-text-field
    v-mask="'#####-###'"
    @change="getAddress"
    v-model="form.fields.address.zip_code"
    :rules="form.rules.zip_codeRules"
    outlined
    label="CEP"
    required
    :loading="loading"
  >
  </v-text-field>
</template>

<script>
import { apiViaCep } from "../api";

export default {
  name: "IziManTextFieldCep",
  props: ["zip_code"],
  data() {
    return {
      valid: true,
      loading: false,
      form: {
        fields: {
          address: {
            zip_code: "",
          },
        },
        rules: {
          zip_codeRules: [(v) => !!v || "Necessário preencher o campo de CEP"],
        },
      },
    };
  },
  methods: {
    async getAddress() {
      if (this.form.fields.address.zip_code.length != 9) return;

      this.loading = true;

      const resp = await apiViaCep.get(
        `${this.form.fields.address.zip_code.replace("-", "")}/json/`
      );

      this.loading = false;

      if (resp?.data?.erro) {
        this.$emit("findAddress", {});
        this.$root.GlobalMessage.error("Endereço não encontrado!");
        return;
      }

      resp.data.zip_code = this.form.fields.address.zip_code;

      const address = {
        street: resp.data.logradouro,
        neighborhood: resp.data.bairro,
        city: resp.data.localidade,
        state: resp.data.uf,
        zip_code: resp.data.zip_code,
      };

      this.$emit("findAddress", address);
    },
  },
  watch: {
    zip_code(value) {
      this.form.fields.address.zip_code = value;
    },
  },
};
</script>
