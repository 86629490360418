import { render, staticRenderFns } from "./cliente-detalhe.vue?vue&type=template&id=875cf75c&scoped=true&"
import script from "./cliente-detalhe.vue?vue&type=script&lang=ts&"
export * from "./cliente-detalhe.vue?vue&type=script&lang=ts&"
import style0 from "./cliente-detalhe.vue?vue&type=style&index=0&id=875cf75c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "875cf75c",
  null
  
)

export default component.exports