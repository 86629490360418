<template>
  <v-form ref="formAutoCompleteEquipamento" v-model="valid">
    <div class="text-right">
      <v-btn @click="$refs.navigationDrawerGerenciarEquipamento.show()" small class="pa-0 mb-1" text color="primary">
        <v-icon>mdi-plus</v-icon>
        Novo equipamento
      </v-btn>
    </div>
    <v-autocomplete
      outlined
      clearable
      multiple
      small-chips
      v-model="form.fields.equipment"
      :rules="form.rules.equipmentRules"
      :items="items"
      item-text="name"
      item-value="_id"
      @change="$emit('change', $event)"
      label="Selecione o(s) equipamento(s)"
      no-data-text="Nenhum dado encontrado"
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle>
              Modelo: {{ data.item.model }} | Fabricante: {{ data.item.maker }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              Voltagem: {{ data.item.voltage }} | N° Série:
              {{ data.item.serial_number }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>

    <NavigationDrawerGerenciarEquipamento
      ref="navigationDrawerGerenciarEquipamento"
      @salvo="this.saveEquipment"
    />
  </v-form>
</template>

<script lang="ts">
import { api } from "../../../../api";
import NavigationDrawerGerenciarEquipamento from "../navigationDrawer/NavigationDrawerGerenciarEquipamento.vue";

export default {
  name: "IziManAutoCompleteEquipamento",
  components: { NavigationDrawerGerenciarEquipamento },
  emits: ["change"],
  data() {
    return {
      valid: true,
      items: [],
      form: {
        fields: {
          equipment: null,
        },
        rules: {
          equipmentRules: [
            (v) => !!v || "Necessário preencher o campo de tipo de equipamento",
          ],
        },
      },
    };
  },
  methods: {
    async getItens() {
      const response = await api.get("/equipment");

      if (response.data) {
        this.items = response.data;
      } else this.$root.GlobalMessage.error("Erro ao encontrar itens");
    },

    async saveEquipment(event) {
      await this.getItens();
      const equipments = this.form.fields.equipment || [];
      this.form.fields.equipment = equipments.concat([event._id]);
    },
  },
  beforeMount() {
    this.getItens();
  },
};
</script>
