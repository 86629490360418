import { render, staticRenderFns } from "./manutencao-calendario.vue?vue&type=template&id=0d2bb989&scoped=true&"
import script from "./manutencao-calendario.vue?vue&type=script&lang=ts&"
export * from "./manutencao-calendario.vue?vue&type=script&lang=ts&"
import style0 from "./manutencao-calendario.vue?vue&type=style&index=0&id=0d2bb989&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d2bb989",
  null
  
)

export default component.exports