<template>
  <div>
    <TabGerarCobranca v-if="tabs === 0" ref="tabGerarCobranca" />
    <TabMensalista v-if="tabs === 1" ref="tabMensalista" />
    <TabCobrancaGerada v-if="tabs === 2" ref="tabCobrancaGerada" />
    <TabTabelaCobranca v-if="tabs === 3" ref="tabTabelaCobranca" />
  </div>
</template>

<script lang="ts">
import TabCobrancaGerada from "./components/tabs/TabCobrancaGerada.vue";
import TabGerarCobranca from "./components/tabs/TabGerarCobranca.vue";
import TabMensalista from "./components/tabs/TabMensalista.vue";
import { EventBus } from "@/event-bus.js";
import TabTabelaCobranca from "./components/tabs/TabTabelaCobranca.vue";

export default {
  name: "IziManCobrancas",
  title: "Cobrancas",
  components: { TabCobrancaGerada, TabGerarCobranca, TabMensalista, TabTabelaCobranca },
  data() {
    return {
      tabs: 0,
    };
  },
  created() {
    EventBus.$on("tab-cobranca-mudou", (tab) => {
      this.tabs = tab;
    });
  },
};
</script>
