<template lang="">
  <div>
    <v-bottom-sheet v-model="sheet" inset>
      <v-list three-line>
        <v-subheader>Manutenções</v-subheader>
        <v-list-item
          v-for="maintenance in maintenances"
          :key="maintenance.title"
          @click="
            $refs.navigationDrawerManutencaoDetalhe.show(maintenance._id);
            sheet = false;
          "
        >
          <v-list-item-avatar>
            <v-icon class="primary" dark> mdi-hammer-screwdriver </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{
              maintenance.number_os_format
            }}</v-list-item-title>

            <v-list-item-subtitle
              >Finalizado em:
              {{
                maintenance.maintenance_date | moment("DD/MM/YYYY hh:mm")
              }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              >Valor total:
              {{ maintenance.values.total | currency }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>

    <NavigationDrawerManutencaoDetalhe
      ref="navigationDrawerManutencaoDetalhe"
    />
  </div>
</template>

<script>
import NavigationDrawerManutencaoDetalhe from "../../../manutencao/components/navigationDrawer/NavigationDrawerManutencaoDetalhe.vue";

export default {
  name: "IziManBottomSheetDetalheManutencoes",
  components: { NavigationDrawerManutencaoDetalhe },
  props: ["maintenances"],
  data() {
    return {
      sheet: false,
    };
  },
  methods: {
    async show() {
      this.sheet = true;
    },
  },
};
</script>
<style lang=""></style>
