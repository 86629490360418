import login from './pages/login/login.vue';

import paginaInicial from './pages/home/pagina-inicial.vue';


import manutencaoDashboard from './pages/manutencao/manutencao-dashboard.vue'
import manutencaoListagem from './pages/manutencao/manutencao-listagem.vue'
import manutencaoOrcamento from './pages/manutencao/manutencao-orcamento.vue'
import manutencaoCalendario from './pages/manutencao/manutencao-calendario.vue'

import financeiro from './pages/financeiro/financeiro.vue'
import cobrancas from './pages/financeiro/cobrancas.vue'
import livroCaixa from './pages/financeiro/livro-caixa.vue'

import cliente from './pages/cliente/cliente.vue'
import clienteGerenciar from './pages/cliente/cliente-gerenciar.vue'
import clienteDetalhe from './pages/cliente/cliente-detalhe.vue'

import peca from './pages/peca/peca.vue'

import equipamento from './pages/equipamento/equipamento.vue'

import os from './pages/relatorio/os.vue'
import relatorio from './pages/relatorio/relatorio.vue'
import relatorioManutencao from './pages/relatorio/relatorio-manutencao.vue'

import configUsuario from './pages/configuracao/usuario.vue';
import configPerfil from './pages/configuracao/perfil.vue';
import configAuxiliar from './pages/configuracao/auxiliar.vue';

import usuario from './pages/usuario/usuario.vue'

function guardMyroute(to, from, next) {
  var isAuthenticated = false;

  if (localStorage.getItem('app_LoggedUser'))
    isAuthenticated = true;

  if (isAuthenticated) next();
  else next('/');
}

export default [
  { name: 'Login', path: '/', component: login },

  { name: 'PaginaInicial', path: '/pagina-inicial', component: paginaInicial, beforeEnter: guardMyroute },

  { name: 'ManutencaoDashboard', path: '/manutencao-dashboard', component: manutencaoDashboard, beforeEnter: guardMyroute },
  { name: 'ManutencaoListagem', path: '/manutencao-listagem', component: manutencaoListagem, beforeEnter: guardMyroute },
  { name: 'ManutencaoOrcamento', path: '/manutencao-orcamento', component: manutencaoOrcamento, beforeEnter: guardMyroute },
  { name: 'ManutencaoCalendario', path: '/manutencao-calendario', component: manutencaoCalendario, beforeEnter: guardMyroute },


  { name: 'Financeiro', path: '/financeiro', component: financeiro, beforeEnter: guardMyroute },
  { name: 'Cobrancas', path: '/cobrancas', component: cobrancas, beforeEnter: guardMyroute },
  { name: 'LivroCaixa', path: '/livro-caixa', component: livroCaixa, beforeEnter: guardMyroute },


  { name: 'Cliente', path: '/cliente', component: cliente, beforeEnter: guardMyroute },
  { name: 'ClienteGerenciar', path: '/cliente-gerenciar', component: clienteGerenciar, beforeEnter: guardMyroute },
  { name: 'ClienteDetalhe', path: '/cliente-detalhe/:id', component: clienteDetalhe, beforeEnter: guardMyroute },


  { name: 'Peca', path: '/peca', component: peca, beforeEnter: guardMyroute },


  { name: 'Equipamento', path: '/equipamento', component: equipamento, beforeEnter: guardMyroute },


  { name: 'OS', path: '/os/:id', component: os, beforeEnter: guardMyroute },
  { name: 'Relatorio', path: '/relatorio', component: relatorio, beforeEnter: guardMyroute },
  { name: 'RelatorioManutencao', path: '/relatorio/manutencao', component: relatorioManutencao, beforeEnter: guardMyroute },


  { name: 'ConfiguracaoUsuario', path: '/config/usuario', component: configUsuario, beforeEnter: guardMyroute },
  { name: 'ConfiguracaoPerfil', path: '/config/perfil', component: configPerfil, beforeEnter: guardMyroute },
  { name: 'ConfiguracaoAuxiliar', path: '/config/auxiliar', component: configAuxiliar, beforeEnter: guardMyroute },
  


  { name: 'Usuario', path: '/usuario', component: usuario, beforeEnter: guardMyroute },


]