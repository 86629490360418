<template>
  <div>
    <div class="display-title-page">
      <div class="ml-2">
        <div class="title mb-1">Listagem de auxiliares</div>
        <div class="caption mb-3">
          Listagem com todos os auxiliares cadastrado pelo sistema.<br />
          Esses auxiliares servem para ajudar no momento da criação de alguns
          processos.
        </div>
      </div>
      <div class="mr-2">
        <v-btn
          raised
          color="primary"
          class="ml-1"
          @click="$refs.navigationDrawerAuxiliar.show()"
          ><v-icon left>mdi-plus</v-icon>Cadastrar Auxiliar</v-btn
        >
      </div>
    </div>

    <v-card>
      <v-card-title v-if="!loading">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        item-key="name"
        :items="list"
        :headers="headers"
        :loading="loading"
        loading-text="Carregando... aguarde"
      >
        <template v-slot:[`item.type`]="{ item }">
          {{ EnumTipoAuxiliar[item.type] }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="blue"
                v-bind="attrs"
                v-on="on"
                @click="$refs.navigationDrawerAuxiliar.show(item._id)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            Editar auxiliar
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
                @click="deleteItem(item)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
            Deletar auxiliar
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <NavigationDrawerAuxiliar
      ref="navigationDrawerAuxiliar"
      @salvo="this.getItens"
    />
  </div>
</template>

<script lang="ts">
import { api } from "../../api";
import { EnumTipoAuxiliar } from "../../enums/TipoAuxiliares";
import NavigationDrawerAuxiliar from "./components/navigationDrawer/NavigationDrawerAuxiliar.vue";

export default {
  name: "IziManConfigAuxiliar",
  title: "Configuração dos auxiliares",
  components: { NavigationDrawerAuxiliar },
  data() {
    return {
      search: "",
      list: [],
      EnumTipoAuxiliar,
      headers: [
        { text: "Tipo", value: "type" },
        { text: "Nome", value: "name" },
        { text: "Conteúdo", value: "content" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      loading: false,
    };
  },
  methods: {
    async getItens() {
      this.loading = true;
      try {
        const response = await api.get("/helper");

        if (response.data) {
          this.list = response.data;
        } else
          this.$root.GlobalMessage.error("Erro ao encontrar os auxiliares");
      } catch (error) {
        this.$root.GlobalMessage.error("Erro ao encontrar os auxiliares");
      }
      this.loading = false;
    },
  },

  beforeMount() {
    this.getItens();
  },
};
</script>
