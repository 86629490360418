import { render, staticRenderFns } from "./CardCobranca.vue?vue&type=template&id=e5c5ef20&scoped=true&"
import script from "./CardCobranca.vue?vue&type=script&lang=ts&"
export * from "./CardCobranca.vue?vue&type=script&lang=ts&"
import style0 from "./CardCobranca.vue?vue&type=style&index=0&id=e5c5ef20&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5c5ef20",
  null
  
)

export default component.exports