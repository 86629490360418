<template>
  <div>
    <v-form ref="form" v-model="valid">

      <v-item-group @change="this.changeType">
        <v-container>
          <v-row>
            <v-col v-for="n in options" :key="n.title" cols="12" md="6">
              <v-item v-slot="{ active, toggle }">
                <v-card :color="active ? 'primary' : ''" class="d-flex align-center pa-2" height="150" :dark="active"
                  @click="toggle">
                  <v-scroll-y-transition>
                    <div class="flex-grow-1 text-center">
                      <div class="text-h6">{{ n.title }}</div>
                      <span class="body-2">{{ n.text }}</span>
                    </div>
                  </v-scroll-y-transition>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
      <span v-if="dirty && Boolean(!form.fields.service_type)" class="red--text">Necessário preencher o campo de tipo de
        serviço</span>

      <div class="mt-3">
        <v-btn color="primary" outlined @click="next()">
          Avançar
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script lang="ts">
export default {
  name: 'IziManFormularioTipoServico',
  data() {
    return {
      valid: true,
      dirty: false,
      options: [
        { title: 'CORRETIVA', text: 'Tem ciência do problema existente no cliente' },
        { title: 'PREVENTIVA', text: 'Previnir que o equipamento dê defeito' },
        { title: 'ORÇAMENTO', text: 'Solicitado uma avaliação pois ainda não se sabe o que será feito' },
        { title: 'GARANTIA', text: 'Já foi feito algum tipo de serviço porém foi acionado a garantia da manutenção' },
      ],
      form: {
        fields: {
          service_type: null,
        },
      }

    }
  },
  methods: {
    next() {
      this.dirty = true;
      if (this.form.fields.service_type)
        this.$emit('next', this.form.fields)
    },

    changeType(index) {
      if (index !== undefined) this.form.fields.service_type = this.options[index].title
      else this.form.fields.service_type = null;
    }
  }
}
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
