<template>
  <v-form class="pt-2" ref="form" v-model="valid" @submit="salvar()">
    <v-row>
      <v-col class="py-0" cols="12" sm="12" md="7">
        <v-text-field v-model="form.fields.name" :rules="form.rules.nameRules" outlined label="Nome do equipamento"
          required>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="12" md="5">
        <v-text-field v-model="form.fields.model" :rules="form.rules.modelRules" outlined label="Modelo" required>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="12" md="4">
        <v-text-field v-model="form.fields.maker" :rules="form.rules.makerRules" outlined label="Fabricante" required>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="12" md="4">
        <v-text-field v-model="form.fields.voltage" :rules="form.rules.voltageRules" outlined label="Voltagem" required>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="12" md="4">
        <v-text-field v-model="form.fields.serial_number" :rules="form.rules.serial_numberRules" outlined label="N° Série"
          required>
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="12">
        <v-btn :loading="loading" :disabled="loading" @click="salvar()" color="primary" outlined>Salvar</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { api } from "../../../../api";

export default {
  name: "ResEquipamentoGerenciar",
  props: ["id"],
  data() {
    return {
      valid: true,
      loading: false,
      form: {
        fields: {
          name: "",
          model: "",
          maker: "",
          voltage: "",
          serial_number: "",
        },
        rules: {
          nameRules: [(v) => !!v || "Necessário preencher o campo Nome"],
          modelRules: [(v) => !!v || "Necessário preencher o campo Modelo"],
          makerRules: [(v) => !!v || "Necessário preencher o campo Fabricante"],
          voltageRules: [(v) => !!v || "Necessário preencher o campo Voltagem"],
          serial_numberRules: [
            (v) => !!v || "Necessário preencher o campo N° Série",
          ],
        },
      },
    };
  },
  methods: {
    async getItem(id) {
      const response = await api.get(`/equipment/${id}`);

      if (response.data) {
        this.form.fields = response.data;
      } else this.$root.GlobalMessage.error("Erro ao encontrar Equipamento");
    },

    salvar() {
      if (this.$refs.form.validate()) {
        this.$emit("salvar", this.form.fields);
      }
    },
  },

  watch: {
    id(value) {
      if (value) this.getItem(value);
    },
  },
};
</script>
