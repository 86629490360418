<template>
  <div class="pa-4">
    <h1 class="text-h4">Bem vindo ao IziMaintenance</h1>
    <div class="text-subtitle-1 mt-2">Aqui você poderá agilizar o seu processo de serviço de uma forma simples, rapida e
      organizada</div>
    <div class="text-subtitle-2 mt-6 mb-4">Segue abaixo alguns links para agilizar o seu processo dentro do
      sistema</div>

    <v-item-group @change="this.selectItem">
      <div v-for="n in options" :key="n.title" class="mx-1 my-1" style="display: inline-flex;">
        <v-item v-slot="{ active, toggle }">
          <v-card :color="active ? 'primary' : ''" class="d-flex align-center pa-4 max-w-350" :dark="active"
            @click="toggle()">
            <v-scroll-y-transition>
              <div class="flex-grow-1 text-center">
                <div class="text-h6 mb-4">{{ n.title }}</div>
                <span class="body-2">{{ n.text }}</span>
              </div>
            </v-scroll-y-transition>
          </v-card>
        </v-item>
      </div>
    </v-item-group>

    <NavigationDrawerManutencaoCriar ref="navigationDrawerManutencaoCriar"
      @reload="$root.GlobalMessage.success('Cadastro realizado com sucesso')" />
    <NavigationDrawerGerenciarEquipamento ref="navigationDrawerGerenciarEquipamento"
      @salvo="$root.GlobalMessage.success('Cadastro realizado com sucesso')" />
    <NavigationDrawerGerenciarCliente ref="navigationDrawerGerenciarCliente"
      @salvo="$root.GlobalMessage.success('Cadastro realizado com sucesso')" />
    <NavigationDrawerGerenciarPeca ref="navigationDrawerGerenciarPeca"
      @salvo="$root.GlobalMessage.success('Cadastro realizado com sucesso')" />

  </div>
</template>

<script lang="ts">
import NavigationDrawerGerenciarCliente from '../cliente/components/navigationDrawer/NavigationDrawerGerenciarCliente.vue';
import NavigationDrawerGerenciarEquipamento from '../equipamento/components/navigationDrawer/NavigationDrawerGerenciarEquipamento.vue';
import NavigationDrawerManutencaoCriar from '../manutencao/components/navigationDrawer/NavigationDrawerManutencaoCriar.vue';
import NavigationDrawerGerenciarPeca from '../peca/components/navigationDrawer/NavigationDrawerGerenciarPeca.vue';

export default {
  name: "IziManPaginaInicial",
  title: "Página inicial",
  components: { NavigationDrawerManutencaoCriar, NavigationDrawerGerenciarEquipamento, NavigationDrawerGerenciarCliente, NavigationDrawerGerenciarPeca },
  data: () => ({
    options: [
      { title: "Criação de manutencao", text: "Ao clicar será aberto uma página lateral que irá solicitar alguns dados como o tipo de manutenção, qual o cliente que será feito a manutenção, qual o equipamento que esta apresentando algum tipo de problema e qual o problema que foi mencionado pelo cliente, além de dizer se a mesma é prioritário ou não." },
      { title: "Criação de cliente", text: "Ao clicar será aberto uma página lateral que irá solicitar alguns dados para o cadastro de cliente que será utilizado na criação da O.S." },
      { title: "Criação de equipamento", text: "Ao clicar será aberto uma página lateral que irá solicitar alguns dados para o cadastro de equipamentos, que poderão ser selecionado na O.S., para informar qual os equipamentos que estão apresentando problemas" },
      { title: "Criação de peça", text: "Ao clicar será aberto uma página lateral que irá solicitar alguns dados para o cadastro de peça, que foram utilizados para informar quais peças foram utilizadas para que o concerto/correção do problema fosse concretizado" },
    ],
  }),
  methods: {
    selectItem(event) {
      switch (event) {
        case 0: this.$refs.navigationDrawerManutencaoCriar.show();
          break;
        case 1: this.$refs.navigationDrawerGerenciarCliente.show();
          break;
        case 2: this.$refs.navigationDrawerGerenciarEquipamento.show();
          break;
        case 3: this.$refs.navigationDrawerGerenciarPeca.show();
          break;

        default: this.$root.GlobalMessage.error('Não encontramos a sua operação solicitada')
          break;
      }
    }
  },
};
</script>

<style scoped>
.max-w-350 {
  max-width: 350px
}
</style>