<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="9">
        <div class="title mb-3">Dashboard Financeiro</div>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <div>
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="dates"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Período"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" range scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancelar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.dialog.save(dates);
                  getAllData();
                "
              >
                Selecionar
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" sm="12" md="3">
        <v-card class="mx-1 mt-1" :loading="pageData.valores.recebido === null">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">Recebidos</div>
              <v-list-item-title class="text-h5 mb-1">
                {{ (pageData.valores.recebido ?? 0) | currency }}
              </v-list-item-title>
              <v-list-item-subtitle>Pago no perído</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar size="100" color="success" rounded>
              <v-avatar size="100" rounded>
                <v-icon dark size="50"> mdi-cog-counterclockwise </v-icon>
              </v-avatar>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card
          class="mx-1 mt-1"
          :loading="pageData.valores.pendenteEmDia === null"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">Pendente pagamento</div>
              <v-list-item-title class="text-h5 mb-1">
                {{ (pageData.valores.pendenteEmDia ?? 0) | currency }}
              </v-list-item-title>
              <v-list-item-subtitle>Em dia</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar size="100" color="orange" rounded>
              <v-avatar size="100">
                <v-icon dark size="50"> mdi-cog-clockwise </v-icon>
              </v-avatar>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card
          class="mx-1 mt-1"
          :loading="pageData.valores.pendenteAtrasado === null"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">Atrasados</div>
              <v-list-item-title class="text-h5 mb-1">
                {{ (pageData.valores.pendenteAtrasado ?? 0) | currency }}
              </v-list-item-title>
              <v-list-item-subtitle>Não pago na data</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar size="100" color="red" rounded>
              <v-avatar size="100" rounded>
                <v-icon dark size="50"> mdi-cash-clock </v-icon>
              </v-avatar>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card class="mx-1 mt-1" :loading="pageData.valores.projecao === null">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">Projeção</div>
              <v-list-item-title class="text-h5 mb-1">
                {{ (pageData.valores.projecao ?? 0) | currency }}
              </v-list-item-title>
              <v-list-item-subtitle>Previsto para receber</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar size="100" color="primary" rounded>
              <v-avatar size="100">
                <v-icon dark size="50"> mdi-cash-remove </v-icon>
              </v-avatar>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-card>
          <v-card-text>
            <span class="subtitle-2"
              >Resumo <small>no período - <b>PAGOS</b></small></span
            >

            <div class="resumeList">
              <v-subheader class="pa-0"
                >Listagem referente as manuteções</v-subheader
              >

              <v-list two-line subheader>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title>Visita</v-list-item-title>
                    <v-list-item-subtitle class="green--text">{{
                      pageData.resume.visit | currency
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title>Mão de obra</v-list-item-title>
                    <v-list-item-subtitle class="green--text">{{
                      pageData.resume.labour | currency
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title>Materiais</v-list-item-title>
                    <v-list-item-subtitle class="green--text">{{
                      pageData.resume.material | currency
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title>Despesas</v-list-item-title>
                    <v-list-item-subtitle class="green--text">{{
                      pageData.resume.expenses | currency
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title>Descontos</v-list-item-title>
                    <v-list-item-subtitle class="red--text">{{
                      pageData.resume.discount | currency
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title>Total</v-list-item-title>
                    <v-list-item-subtitle class="blue--text">{{
                      pageData.resume.total | currency
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-card>
          <v-card-text>
            <span class="subtitle-2"
              >Pagamentos por clientes
              <small>no período - <b>PAGOS</b></small></span
            >

            <div>
              <v-list subheader two-line>
                <v-list-item
                  v-for="(client, index) in pageData.client"
                  :key="index"
                >
                  <v-list-item-avatar>
                    <v-icon class="yellow lighten-1" dark v-if="index === 0">
                      mdi-trophy
                    </v-icon>
                    <span
                      v-else
                      class="grey white--text"
                      style="
                        width: 40px;
                        height: 40px;
                        font-size: 24px;
                        line-height: 40px;
                      "
                      >{{ index + 1 }}</span
                    >
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="client.client[0].fantasy_name"
                    ></v-list-item-title>

                    <v-list-item-subtitle>{{
                      client.total | currency
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon color="blue lighten-1">mdi-information</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <p class="text-center mt-3" v-if="!pageData.client?.length">
                Nenhum pagamento no período selecionado
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-card>
          <v-card-text>
            <span class="subtitle-2">Demonstrativo financeiro</span>

            <div class="mt-4">
              <p>
                Previsto para receber no mês:
                <b>{{ (pageData.valores.projecao ?? 0) | currency }}</b>
              </p>

              <v-divider class="mb-3"></v-divider>

              <p>
                Pagos no período <small>(Com atraso)</small>:
                <b>{{ (pageData.valores.recebidoAtrasado ?? 0) | currency }}</b>
              </p>
              <p>
                Pagos no período <small>(Em dia)</small>:
                <b>{{ (pageData.valores.recebidoEmDia ?? 0) | currency }}</b>
              </p>
              <p>
                Pagos no período:
                <b>{{ (pageData.valores.recebido ?? 0) | currency }}</b>
              </p>

              <v-divider class="mb-3"></v-divider>

              <p>
                Pendente pagamento no período <small>(Com atraso)</small>:
                <b>{{ (pageData.valores.pendenteAtrasado ?? 0) | currency }}</b>
              </p>
              <p>
                Pendente pagamento no período <small>(Em dia)</small>:
                <b>{{ (pageData.valores.pendenteEmDia ?? 0) | currency }}</b>
              </p>
              <p>
                Pendente pagamento no período:
                <b>{{ (pageData.valores.pendente ?? 0) | currency }}</b>
              </p>

              <v-divider class="mb-3"></v-divider>

              <p>
                Pagamentos cancelados no período:
                <b>{{ (pageData.valores.cancelado ?? 0) | currency }}</b>
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { api } from "../../api";
import moment from "moment-timezone";

export default {
  name: "IziManFinanceiro",
  title: "Dashboard Financeiro",
  data() {
    return {
      modal: false,
      dates: [
        moment(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        ).format("YYYY-MM-DD"),
        moment(
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        ).format("YYYY-MM-DD"),
      ],
      pageData: {
        valores: {
          recebido: null,
          recebidoEmDia: null,
          recebidoAtrasado: null,
          pendente: null,
          pendenteEmDia: null,
          pendenteAtrasado: null,
          projecao: null,
          cancelado: null,
        },
        resume: {
          visit: null,
          labour: null,
          expenses: null,
          material: null,
          discount: null,
          total: null,
        },
        client: null,
      },
    };
  },
  methods: {
    getAllData() {
      this.getReciveInDay();
      this.getReciveLate();
      this.getRecive();

      this.getPendingInDay();
      this.getPendingLate();
      this.getPending();

      this.getProjecao();

      this.getCancelled();

      this.getResume();

      this.getClientCharge();
    },

    async getReciveInDay() {
      const query = {
        dateBegin: this.dates[0],
        dateEnd: this.dates[1],
      };

      const recive = await api.get("/chargeSum", {
        params: {
          ...query,
          payed: true,
          cancelled: false,
          payedRange: true,
          payedInDay: true,
        },
      });
      this.pageData.valores.recebidoEmDia = recive.data;
    },

    async getReciveLate() {
      const query = {
        dateBegin: this.dates[0],
        dateEnd: this.dates[1],
      };

      const recive = await api.get("/chargeSum", {
        params: {
          ...query,
          payed: true,
          cancelled: false,
          payedRange: true,
          payedLate: true,
        },
      });
      this.pageData.valores.recebidoAtrasado = recive.data;
    },

    async getRecive() {
      const query = {
        dateBegin: this.dates[0],
        dateEnd: this.dates[1],
      };

      const recive = await api.get("/chargeSum", {
        params: { ...query, payed: true, cancelled: false, payedRange: true },
      });
      this.pageData.valores.recebido = recive.data;
    },

    async getPendingInDay() {
      const query = {
        dateBegin: this.dates[0],
        dateEnd: this.dates[1],
      };

      const pendingInDay = await api.get("/chargeSum", {
        params: {
          ...query,
          payed: false,
          cancelled: false,
          pendingInDayRange: true,
        },
      });
      this.pageData.valores.pendenteEmDia = pendingInDay.data;
    },

    async getPendingLate() {
      const query = {
        dateBegin: this.dates[0],
        dateEnd: this.dates[1],
      };

      const recebidoAtrasado = await api.get("/chargeSum", {
        params: { ...query, payed: false, cancelled: false, lateRange: true },
      });
      this.pageData.valores.pendenteAtrasado = recebidoAtrasado.data;
    },

    async getPending() {
      const query = {
        dateBegin: this.dates[0],
        dateEnd: this.dates[1],
      };

      const pending = await api.get("/chargeSum", {
        params: {
          ...query,
          payed: false,
          cancelled: false,
          pendingRange: true,
        },
      });
      this.pageData.valores.pendente = pending.data;
    },

    async getProjecao() {
      const query = {
        dateBegin: this.dates[0],
        dateEnd: this.dates[1],
      };

      const projecao = await api.get("/chargeSum", {
        params: { ...query, cancelled: false, projectionRange: true },
      });
      this.pageData.valores.projecao = projecao.data;
    },

    async getCancelled() {
      const query = {
        dateBegin: this.dates[0],
        dateEnd: this.dates[1],
      };

      const cancelado = await api.get("/chargeSum", {
        params: { ...query, cancelled: true, cancelledRange: true },
      });
      this.pageData.valores.cancelado = cancelado.data;
    },

    async getResume() {
      const query = {
        dateBegin: this.dates[0],
        dateEnd: this.dates[1],
      };

      const resume = await api.get("/chargeResumeMaintenance", {
        params: { ...query },
      });
      this.pageData.resume = Object.assign({}, resume.data);
    },

    async getClientCharge() {
      const query = {
        dateBegin: this.dates[0],
        dateEnd: this.dates[1],
      };

      const client = await api.get("/chargeClient", { params: { ...query } });
      this.pageData.client = client.data;
    },
  },
  computed: {
    dateRangeText() {
      return `${moment(this.dates[0]).format("DD/MM/YYYY")} - ${moment(
        this.dates[1]
      ).format("DD/MM/YYYY")}
      `;
    },
  },
  beforeMount() {
    this.getAllData();
  },
};
</script>
