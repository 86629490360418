<template>
  <div>
    <div class="title mb-3">Cadastro de peça</div>

    <v-form class="pt-2" ref="form" v-model="valid" @submit="cadastrar()">
      <v-text-field v-model="form.fields.fantasy_name" :rules="form.rules.fantasy_nameRules" outlined
        label="Nome fantasia" required>
      </v-text-field>

      <v-text-field v-model="form.fields.cnpj" :rules="form.rules.cnpjRules" outlined label="CNPJ" required>
      </v-text-field>

      <v-text-field v-model="form.fields.corporate_name" :rules="form.rules.corporate_nameRules" outlined
        label="Razão social" required>
      </v-text-field>

      <v-text-field v-model="form.fields.email" :rules="form.rules.emailRules" outlined label="E-mail" required>
      </v-text-field>

      <v-text-field v-model="form.fields.responsable_name" :rules="form.rules.responsable_nameRules" outlined
        label="Nome do responsável" required>
      </v-text-field>

      <v-text-field v-model="form.fields.phone_number" :rules="form.rules.phone_numberRules" outlined label="Telefone"
        required>
      </v-text-field>

      <v-text-field v-model="form.fields.address.street" :rules="form.rules.streetRules" outlined label="Logradouro"
        required>
      </v-text-field>

      <v-text-field v-model="form.fields.address.neighborhood" :rules="form.rules.neighborhoodRules" outlined
        label="Bairro" required>
      </v-text-field>

      <v-text-field v-model="form.fields.address.city" :rules="form.rules.cityRules" outlined label="Cidade" required>
      </v-text-field>

      <v-text-field v-model="form.fields.address.state" :rules="form.rules.stateRules" outlined label="Estado" required>
      </v-text-field>

      <v-text-field v-model="form.fields.address.zip_code" :rules="form.rules.zip_codeRules" outlined label="CEP"
        required>
      </v-text-field>


      <v-btn :loading="loading" :disabled="loading" @click="cadastrar()" color="primary" outlined>Salvar</v-btn>
    </v-form>

    <!-- <v-text-field v-model="form.name" outlined label="Nome da peça"></v-text-field>
    <v-text-field v-model="form.value" outlined label="Valor"></v-text-field>

    <v-btn @click="acessar()" color="primary" outlined right>Cadastrar</v-btn> -->

    <Notificacao ref="notificacao" />
  </div>
</template>

<script>

import Notificacao from '../../components/Notificacao.vue';
import  { api } from '../../api';

export default {
  name: "ResClienteGerenciar",
  components: { Notificacao },
  data() {
    return {
      valid: true,
      loading: false,
      form: {
        fields: {
          _id: null,
          cnpj: '',
          fantasy_name: '',
          corporate_name: '',
          email: '',
          responsable_name: '',
          phone_number: '',
          address: {
            street: '',
            neighborhood: '',
            city: '',
            state: '',
            zip_code: '',
          },
        },
        rules: {
          nameRules: [
            v => !!v || 'Necessário preencher o campo de nome',
          ],
          valueRules: [
            v => !!v || 'Necessário preencher o campo de valor',
          ],
          cnpjRules: [
            v => !!v || 'Necessário preencher o campo de valor',
          ],
          fantasy_nameRules: [
            v => !!v || 'Necessário preencher o campo de valor',
          ],
          corporate_nameRules: [
            v => !!v || 'Necessário preencher o campo de valor',
          ],
          emailRules: [
            v => !!v || 'Necessário preencher o campo de valor',
          ],
          responsable_nameRules: [
            v => !!v || 'Necessário preencher o campo de valor',
          ],
          phone_numberRules: [
            v => !!v || 'Necessário preencher o campo de valor',
          ],
          streetRules: [
            v => !!v || 'Necessário preencher o campo de valor',
          ],
          neighborhoodRules: [
            v => !!v || 'Necessário preencher o campo de valor',
          ],
          cityRules: [
            v => !!v || 'Necessário preencher o campo de valor',
          ],
          stateRules: [
            v => !!v || 'Necessário preencher o campo de valor',
          ],
          zip_codeRules: [
            v => !!v || 'Necessário preencher o campo de valor',
          ],

        }
      }
    };
  },
  methods: {
    async cadastrar() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          const response = !this.form.fields._id
            ? await api.post("/client", this.form.fields)
            : await api.put(`/client/${this.form.fields._id}`, this.form.fields);

          if (response.data) {
            this.$root.GlobalMessage.success('Operação realizada com sucesso!')
            this.$refs.form.reset()
          }
          else
            this.$root.GlobalMessage.error('Erro ao cadastrar Cliente!')
          this.loading = false;
        } catch (err) {
          this.$root.GlobalMessage.error('Erro ao realizar requisição, entre em contato com o administrador!')
          this.loading = false;
        }
      }
    },
    async getClient(id) {
      const response = await api.get(`client/${id}`);

      if (response.data) {
        this.form.fields = response.data;
      }
      else
        this.$root.GlobalMessage.error('Erro ao encontrar clientes');

    }
  },
  beforeMount() {
    if (this.$route.params.id)
      this.getClient(this.$route.params.id)
  },
}
</script>