<template>
  <div>
    <div class="display-title-page">
      <div class="ml-2">
        <div class="title mb-1">Listagem de usuários</div>
        <div class="caption mb-3">
          Listagem com todos os usuários que irão fazer parte do sistema
        </div>
      </div>
      <div class="mr-2">
        <v-btn
          raised
          color="primary"
          class="ml-1"
          @click="$refs.navigationDrawerGerenciarUsuario.show()"
          ><v-icon left>mdi-plus</v-icon>Cadastrar usuário</v-btn
        >
        <v-btn raised color="info" class="ml-1"
          ><v-icon>mdi-filter</v-icon></v-btn
        >
      </div>
    </div>

    <v-card>
      <v-card-title v-if="!loading">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        item-key="name"
        :items="list"
        :headers="headers"
        :loading="loading"
        loading-text="Carregando... aguarde"
      >
        <template v-slot:[`item.signature`]="{ item }">
          <v-chip :color="item?.signature ? 'primary' : 'red'" outlined small>
            <v-icon left small>
              {{ item?.signature ? "mdi-check" : "mdi-close" }}
            </v-icon>
            {{ item?.signature ? "Assinatura Digital" : "Sem assinatura" }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="indigo"
                v-bind="attrs"
                v-on="on"
                :disabled="item._id !== $localStorage.get('user')._id"
                @click="$refs.navigationDrawerAssinatura.show(item)"
              >
                <v-icon small>mdi-draw</v-icon>
              </v-btn>
            </template>
            Cadastro da assinatura digital
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="blue"
                v-bind="attrs"
                v-on="on"
                @click="$refs.navigationDrawerGerenciarUsuario.show(item._id)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            Editar informações do usuário
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :color="item.active ? 'green' : 'red'"
                v-bind="attrs"
                v-on="on"
                @click="$refs.dialogAtivaInativa.show(item)"
              >
                <v-icon small>mdi-account-badge</v-icon>
              </v-btn>
            </template>
            {{ item.active ? "Inativar usuário" : "Ativar usuário" }}
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="orange"
                v-bind="attrs"
                v-on="on"
                @click="$refs.dialogResetaSenha.show(item)"
              >
                <v-icon small>mdi-lock-reset</v-icon>
              </v-btn>
            </template>
            Resetar senha
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <NavigationDrawerGerenciarUsuario
      ref="navigationDrawerGerenciarUsuario"
      @salvo="this.getItens"
    />
    <NavigationDrawerAssinatura
      ref="navigationDrawerAssinatura"
      @salvo="this.getItens"
    />
    <DialogResetaSenha ref="dialogResetaSenha" @salvo="this.getItens" />
    <DialogAtivaInativa ref="dialogAtivaInativa" @salvo="this.getItens" />
  </div>
</template>

<script lang="ts">
import { api } from "../../api";
import NavigationDrawerAssinatura from "./components/navigationDrawer/NavigationDrawerAssinatura.vue";
import DialogResetaSenha from "./components/dialogs/DialogResetaSenha.vue";
import DialogAtivaInativa from "./components/dialogs/DialogAtivaInativa.vue";
import NavigationDrawerGerenciarUsuario from "./components/navigationDrawer/NavigationDrawerGerenciarUsuario.vue";

export default {
  name: "IziManConfigUsuario",
  title: "Configuração usuário",
  components: {
    NavigationDrawerGerenciarUsuario,
    DialogAtivaInativa,
    DialogResetaSenha,
    NavigationDrawerAssinatura,
  },
  data() {
    return {
      search: "",
      list: [],
      headers: [
        { text: "Nome", value: "name" },
        { text: "Apelido", value: "nick_name" },
        { text: "Perfil", value: "profile.name" },
        { text: "Assinatura", value: "signature" },
        { text: "E-mail", value: "email" },
        { text: "Celular", value: "phone_number" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      loading: false,
    };
  },
  methods: {
    async getItens() {
      console.log(this.$localStorage.get("user"));
      this.loading = true;
      const response = await api.get("/user");

      if (response.data) {
        this.list = response.data;
      } else this.$root.GlobalMessage.error("Erro ao encontrar itens");

      this.loading = false;
    },
  },

  beforeMount() {
    this.getItens();
  },
};
</script>
