<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="3" class="mb-2">
        <v-card class="mr-2 pa-2">
          <div class="float-left ml-4">
            <v-btn
              icon
              :color="cliente.active ? 'green' : 'red'"
              @click="this.activeInactive"
            >
              <v-icon>mdi-circle-slice-8</v-icon>
            </v-btn>
          </div>
          <div class="float-right mr-4">
            <v-btn
              icon
              color="blue darken-3"
              @click.stop.prevent="
                $refs.navigationDrawerGerenciarCliente.show(cliente._id)
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
          <div class="item-avatar my-2">
            <v-img
              src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745"
              max-height="80"
              max-width="80"
            >
            </v-img>
            <span class="overline text-center">
              {{ cliente.fantasy_name }}
            </span>
          </div>
          <div class="mt-2">
            <p>{{ cliente.corporate_name }}</p>
            <p>{{ cliente.responsable_name }}</p>
          </div>
          <v-divider></v-divider>
          <div class="subtitle mt-3 mb-3">
            <v-icon class="mr-2 text--primary" small>mdi-map-marker</v-icon>
            {{ cliente.address?.street }}
          </div>
          <div
            class="text--darken-3 blue--text subtitle mt-3 mb-3"
            v-if="cliente.email"
          >
            <v-icon class="mr-2 text--darken-3 blue--text" small
              >mdi-email</v-icon
            >
            {{ cliente.email }}
          </div>
          <div class="text--darken-3 green--text subtitle mt-3 mb-3">
            <v-icon class="mr-2 text--darken-3 green--text" small
              >mdi-whatsapp</v-icon
            >
            {{ cliente.phone_number }}
          </div>
          <v-divider></v-divider>
          <div>
            <v-switch
              @change="this.activeInactiveMonthly"
              :messages="[
                'Ao selecionar, quando finalizar um O.S., ele não irá para a parte de gerar cobraça e sim sera agrupada na parte de mensalitas no qual no fim do mês será possivel gerar a cobrança de todas as O.S. finalizadas juntas',
              ]"
              v-model="cliente.monthly"
              label="Cliente mensalista"
            ></v-switch>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="9">
        <v-card>
          <v-tabs-items v-model="tabs">
            <v-tab-item class="px-2 py-2">
              <div v-if="manutencoes.length">
                <div class="title mt-3 mb-3">Listagem de manutenção</div>

                <v-timeline align-top dense>
                  <v-timeline-item
                    v-for="manutencao in manutencoes"
                    :key="manutencao.identificacao"
                    color="green"
                    small
                  >
                    <CardManutencao
                      :manutencao="manutencao"
                      :detalhado="true"
                    />
                  </v-timeline-item>
                </v-timeline>
              </div>

              <div v-if="!manutencoes.length" class="text-center mt-2">
                Nenhuma manutenção encontrada
              </div>
            </v-tab-item>
            <v-tab-item class="px-2 py-2">
              <div v-if="cobrancas.length">
                <div class="title mt-3 mb-3">Listagem de cobranças</div>

                <div
                  v-for="manutencao in cobrancas"
                  :key="manutencao.number_os_format"
                >
                  <v-row class="mb-4">
                    <v-col cols="12">
                      <p class="subtitle mb-1">
                        {{ manutencao.number_os_format }}
                      </p>
                      <v-divider></v-divider>
                    </v-col>

                    <v-col
                      v-for="charge in manutencao.charges"
                      :key="charge._id"
                      cols="12"
                      sm="12"
                      md="3"
                    >
                      <CardCobranca
                        ref="cardCobrancaManutencao"
                        @reload="reload()"
                        :cobranca="charge"
                      />
                    </v-col>
                  </v-row>
                </div>
              </div>

              <div v-if="!cobrancas.length" class="text-center mt-2">
                Nenhuma cobrança encontrada
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <NavigationDrawerGerenciarCliente
      ref="navigationDrawerGerenciarCliente"
      @salvo="this.reload"
    />
  </div>
</template>

<script lang="ts">
import { EventBus } from "@/event-bus.js";
import CardManutencao from "@/pages/manutencao/components/card/CardManutencao.vue";
import CardCobranca from "@/pages/financeiro/components/card/CardCobranca.vue";
import NavigationDrawerGerenciarCliente from "@/pages/cliente/components/navigationDrawer/NavigationDrawerGerenciarCliente.vue";
import { api } from "../../api";

export default {
  name: "ResClienteCriar",
  title: "Detalhe cliente",
  components: {
    CardManutencao,
    NavigationDrawerGerenciarCliente,
    CardCobranca,
  },
  data() {
    return {
      tabs: 0,
      cliente: {},
      manutencoes: [],
      cobrancas: [],
    };
  },
  methods: {
    async getClient(id) {
      const response = await api.get(`client/${id}`);

      if (response.data) {
        this.cliente = response.data;
      } else this.$root.GlobalMessage.error("Erro ao encontrar clientes");
    },
    async getMaintenance(client) {
      const query = { client };

      const response = await api.get(`/maintenance`, { params: { ...query } });

      if (response.data) {
        this.manutencoes = response.data;
      } else this.$root.GlobalMessage.error("Erro ao encontrar manutenções");
    },
    async getCharges(client) {
      const query = { client };

      const response = await api.get(`/charge`, { params: { ...query } });

      if (response.data) {
        // this.cobrancas = response.data;

        this.groupCharges(response.data);
      } else this.$root.GlobalMessage.error("Erro ao encontrar cobranças");
    },

    async activeInactive() {
      const response = await api.put(`client/${this.cliente._id}`, {
        active: !this.cliente.active,
      });

      if (response.data) {
        this.cliente.active = !this.cliente.active;
        this.$root.GlobalMessage.success(
          `Cliente foi ${
            this.cliente.active ? "ATIVO" : "INATIVO"
          } com sucesso!`
        );
      } else this.$root.GlobalMessage.error("Erro ao realizar operação!");
    },
    async activeInactiveMonthly(event) {
      const response = await api.put(`client/${this.cliente._id}`, {
        monthly: event,
      });

      if (response.data) {
        this.$root.GlobalMessage.success(
          `Mensalidade do cliente foi ${
            this.cliente.monthly ? "ATIVO" : "INATIVO"
          } com sucesso!`
        );
      } else this.$root.GlobalMessage.error("Erro ao realizar operação!");
    },

    async reload() {
      if (this.$route.params.id) {
        this.getClient(this.$route.params.id);
        this.getMaintenance(this.$route.params.id);
        this.getCharges(this.$route.params.id);
      }
    },

    groupCharges(cobrancas) {
      const cobrancasAgrupadas = {};

      // Percorre as cobranças e as agrupa pelo número de ordem de serviço
      cobrancas.forEach((cobranca) => {
        const numeroOS = cobranca.maintenance.number_os_format;
        if (!cobrancasAgrupadas[numeroOS]) {
          cobrancasAgrupadas[numeroOS] = {
            number_os_format: numeroOS,
            charges: [],
          };
        }
        cobrancasAgrupadas[numeroOS].charges.push(cobranca);
      });

      // Transforma o objeto em um array
      const resultado = Object.values(cobrancasAgrupadas);

      // Exibe as cobranças agrupadas no formato desejado
      console.log("resultado", resultado);
      this.cobrancas = resultado;
      console.log("this.cobrancas", this.cobrancas);
    },
  },
  beforeMount() {
    this.reload();
  },

  created() {
    EventBus.$on("tab-cliente-mudou", (tab) => {
      this.tabs = tab;
    });
  },
};
</script>

<style scoped>
.item-avatar {
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
</style>
