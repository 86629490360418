<!-- eslint-disable no-prototype-builtins -->
<template>
  <div>
    <div class="header white--text">
      <div class="first-row">
        <div class="item-avatar ml-4 mt-5">
          <v-avatar class="mr-2" size="56">
            <img
              src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745"
              alt="John"
            />
          </v-avatar>

          <p class="white--text" style="margin: 0">
            {{ user.name }} <br />
            <small>{{ user.profile.name }}</small>
          </p>
        </div>
        <div class="data mr-3 mt-5">
          <div class="text-center">
            {{ moreInformation.maintenanceFinished }}
            <br />
            <small class="overline">Manutenções</small>
          </div>
        </div>
      </div>
      <div class="second-row">
        <div class="float-right mr-2">
          <v-btn
            color="blue"
            class="white--text"
            @click="$refs.navigationDrawerGerenciarUsuario.show(user._id)"
            ><v-icon left>mdi-square-edit-outline</v-icon>Editar
            informações</v-btn
          >
        </div>
      </div>
    </div>
    <v-row class="px-2 custom-margin-top">
      <v-col cols="12" sm="12" md="3" class="mt-2">
        <v-card class="pa-2 mb-2">
          <v-card-text class="pb-8">
            <p class="subtitle-1 mb-1">Perfil completo</p>
            <v-divider class="mb-6"></v-divider>

            <v-progress-linear
              color="primary"
              height="3"
              :value="progress"
            ></v-progress-linear>
            <small class="float-right mt-1"
              >{{ progress }}% do preenchimento concluido</small
            >
          </v-card-text>
        </v-card>
        <v-card class="pa-2 mb-2">
          <v-card-text>
            <p class="subtitle-1 mb-1">Informações</p>
            <v-divider class="mb-4 mb-1"></v-divider>

            <p>
              Nome completo: <b>{{ user.name }}</b>
            </p>
            <p>
              Endereço:
              <b>{{ user.address?.street }} , {{ user.address?.number }}</b>
            </p>
            <p>
              Gênero: <b>{{ user.gender }}</b>
            </p>
            <p>
              Telefone: <b>{{ user.phone_number }}</b>
            </p>
            <p>
              E-mail: <b>{{ user.email }}</b>
            </p>
          </v-card-text>
        </v-card>
        <v-card class="pa-2 mb-2">
          <v-card-text>
            <p class="subtitle-1 mb-1">Assinatura</p>
            <v-divider class="mb-4 mb-1"></v-divider>

            <img
              v-if="this.user.signature"
              width="100%"
              :src="'data:image/png;base64, ' + this.user.signature"
            />
            <p v-else>Nenhuma assinatura informada</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="9" class="mt-2">
        <v-card class="pa-2 mb-2">
          <v-card-text>
            <p class="subtitle-1 mb-1">Atividades recentes</p>
            <v-divider class="mb-4 mb-1"> </v-divider>
            <div v-if="activities?.length">
              <v-timeline
                align-top
                dense
                v-for="(activity, index) in activities"
                :key="index"
              >
                <v-timeline-item color="primary" small>
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong>{{
                        activity.created_at | moment("DD/MM/YYYY hh:mm")
                      }}</strong>
                    </v-col>
                    <v-col>
                      <strong>{{ activity.title }}</strong>
                      <div class="text-caption" v-html="activity.content"></div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </div>
            <p v-if="!activities?.length">Nenhuma atividade encontrada</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <NavigationDrawerGerenciarUsuario ref="navigationDrawerGerenciarUsuario" />
  </div>
</template>

<script>
import { api } from "../../api";
import NavigationDrawerGerenciarUsuario from "../configuracao/components/navigationDrawer/NavigationDrawerGerenciarUsuario.vue";
export default {
  name: "IziManUsuario",
  title: "Perfil",
  components: { NavigationDrawerGerenciarUsuario },
  data() {
    return {
      user: null,
      activities: [],
      progress: 0,
      calc: {
        totalAtributos: 0,
        atributosPreenchidos: 0,
      },
      moreInformation: {
        maintenanceFinished: 0,
      },
    };
  },
  methods: {
    async maintenanceFinished() {
      const maintenanceFinishedCount = await api.get("/maintenanceCount", {
        params: {
          user: this.user._id,
          finished: true,
        },
      });
      this.moreInformation.maintenanceFinished = maintenanceFinishedCount.data;
    },
    async getActivities() {
      const activities = await api.get("/activity");
      this.activities = activities.data.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA; // Ordene do mais recente para o mais antigo
      });
      console.log(this.activities);
    },
    _arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
    calcProgress() {
      this.percorrerAtributos(this.user);
      this.progress = (
        (this.calc.atributosPreenchidos / this.calc.totalAtributos) *
        100
      ).toFixed(2);
    },
    percorrerAtributos(obj) {
      this.totalAtributos = 0;
      this.atributosPreenchidos = 0;
      for (var chave in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(chave)) {
          this.calc.totalAtributos++;
          if (
            typeof obj[chave] !== "object" ||
            obj[chave] === null ||
            Object.keys(obj[chave]).length === 0
          ) {
            if (obj[chave] !== "") {
              this.calc.atributosPreenchidos++;
            }
          } else {
            this.calc.atributosPreenchidos++;
            this.percorrerAtributos(obj[chave]);
          }
        }
      }
    },
  },
  beforeMount() {
    this.user = this.$localStorage.get("user");
    if (this.user.signature) {
      this.user.signature = this._arrayBufferToBase64(this.user.signature.data);
    }
    this.maintenanceFinished();
    this.getActivities();
  },
  mounted() {
    this.calcProgress();
  },
};
</script>

<style scoped>
.first-row {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.item-avatar {
  display: inline-flex;
  align-items: center;
}

.item-avatar .v-avatar img {
  border: 2px solid white;
}

.header {
  position: relative; /* Garante que o pseudo-elemento se posicione corretamente */
  padding: 0 !important;
  min-height: 250px;
  width: 100%;
  background-image: url("../../assets/bg-user.jpg");
  background-size: cover;
  display: inline-grid;
  align-content: space-between;
  padding-bottom: 50px !important;
}

.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 250px;
  background-color: rgba(0, 0, 0, 0.5); /* Cor preta com 50% de opacidade */
}

.custom-margin-top {
  top: -40px;
  position: relative;
}
</style>
