<template>
  <div>
    <v-card
      :hover="true"
      :disabled="manutencao.cancelled"
      v-if="manutencao"
      :class="[manutencao.step, manutencao.cancelled ? 'CANCELADO' : '']"
      @click="onClick"
    >
      <div class="ml-4 pt-2 bloco-identificacao">
        <span class="text-overline">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                v-if="manutencao.priority"
                color="red"
                >mdi-clipboard-alert</v-icon
              >
            </template>
            Está O.S. tem prioridade
          </v-tooltip>
          {{ manutencao.number_os_format }}
        </span>

        <div class="float-right mr-4" v-if="!manutencao.cancelled">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="!manutencao.maintenance_date"
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent="
                  $refs.dialogCancelarManutencao.show(manutencao)
                "
              >
                <v-icon small>mdi-cancel</v-icon>
              </v-btn>
            </template>
            Cancelar ordem de serviço
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                overlap
                left
                color="primary"
                :content="
                  manutencao.maintenanceImpeditive.filter((x) => x.active)
                    .length
                "
                :value="manutencao.maintenanceImpeditive.some((x) => x.active)"
              >
                <v-btn
                  v-if="!manutencao.maintenance_date"
                  icon
                  color="orange"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop.prevent="
                    $refs.drawerManImpeditivo.show(manutencao._id)
                  "
                >
                  <v-icon small>mdi-tag</v-icon>
                </v-btn>
              </v-badge>
            </template>
            Cadastro e listagem de impeditivos
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="manutencao.maintenance_date"
                icon
                color="blue"
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent="gerarRelatorio(manutencao._id)"
              >
                <v-icon small>mdi-file-document</v-icon>
              </v-btn>
            </template>
            Geração de relatório
          </v-tooltip>
        </div>
      </div>

      <v-card-title
        v-if="detalhado"
        class="text-truncate pt-0 pb-0 display-title"
      >
        <span>
          {{ manutencao.client.fantasy_name }}
        </span>
        <div>
          <v-chip
            class="my-1"
            small
            color="red"
            outlined
            label
            v-if="manutencao.priority"
          >
            PRIORIDADE
          </v-chip>
          <v-chip
            class="my-1"
            small
            color="red"
            outlined
            label
            v-if="manutencao.cancelled"
          >
            CANCELADO
          </v-chip>
          <v-chip class="my-1 ml-1" small color="primary" outlined label>
            {{ manutencao.service_type }}
          </v-chip>
          <v-chip
            class="my-1 ml-1"
            small
            :color="this.color[manutencao.step]"
            outlined
            label
          >
            {{ manutencao.step }}
          </v-chip>
        </div>
      </v-card-title>

      <v-card-text>
        <p class="mb-1 mt-2" v-if="manutencao?.responsables?.length">
          <Avatar :item="manutencao.responsables[0].name" />
          {{ manutencao.responsables[0].name }}
        </p>
        <p class="mt-2 mb-2 display-dates">
          <span>
            <b>Data Criação:</b><br />
            {{ manutencao.created_at | moment("DD/MM/YYYY hh:mm") }}
          </span>
          <span v-if="Boolean(manutencao?.expected_maintenance_date)">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  left
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  v-if="isLate(manutencao)"
                  class="mr-2"
                  >mdi-alert-circle</v-icon
                >
              </template>
              <span>Ordem de serviço esta atrasada</span>
            </v-tooltip>

            <b>Data Prevista Manuteção:</b><br />
            {{
              $moment(manutencao.expected_maintenance_date).format(
                "DD/MM/YYYY hh:mm"
              )
            }}
          </span>

          <span v-if="Boolean(manutencao?.maintenance_date)">
            <b class="mr-1">Manuteção Realizada:</b><br />
            {{ manutencao.maintenance_date | moment("DD/MM/YYYY hh:mm") }}
          </span>
        </p>

        <p class="mb-0 display-problems">
          <span>
            <b>Problema relatado:</b><br />
            {{ manutencao.issue_reported }}
          </span>

          <span v-if="Boolean(manutencao?.issue_found)">
            <b>Problema encontrado:</b><br />
            {{ manutencao.issue_found }}
          </span>
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <div class="ml-4 pb-4">
        <v-chip
          v-for="equipamento in manutencao.equipment"
          :key="equipamento._id"
          class="mt-3 mr-1"
          x-small
          outlined
          label
        >
          {{ equipamento.name }} - {{ equipamento.model }}
        </v-chip>
      </div>

      <div v-if="manutencao.cancelled">
        <v-divider></v-divider>

        <v-card-text>
          <p class="mb-1">
            Cancelada por:
            <b>{{ manutencao.cancelled_by.name }}</b>
          </p>
          <p class="mb-1">
            Data do cancelamento:
            <b> {{ manutencao.cancelled_date | moment("DD/MM/YYYY hh:mm") }}</b>
          </p>
          <p class="mb-0">
            Motivo do cancelamento:
            <b>{{ manutencao.cancelled_observation }}</b>
          </p>
        </v-card-text>
      </div>
    </v-card>
    <NavigationDrawerManutencaoImpeditivo
      ref="drawerManImpeditivo"
      @save="$emit('reload')"
    />
    <NavigationDrawerManutencaoDetalhe
      ref="drawerManDetalhe"
      @reload="$emit('reload')"
    />
    <DialogCancelarManutencao
      ref="dialogCancelarManutencao"
      @cancelled="$emit('reload')"
    />
  </div>
</template>

<script lang="ts">
import moment from "moment-timezone";
import EnumStep from "../../../../enums/StepManutencao";
import NavigationDrawerManutencaoImpeditivo from "../navigationDrawer/NavigationDrawerManutencaoImpeditivo.vue";
import NavigationDrawerManutencaoDetalhe from "@/pages/manutencao/components/navigationDrawer/NavigationDrawerManutencaoDetalhe.vue";
import Avatar from "../../../../components/Avatar.vue";
import DialogCancelarManutencao from "@/pages/manutencao/components/dialogs/DialogCancelarManutencao.vue";

export default {
  name: "IziManCardManutenca",
  components: {
    NavigationDrawerManutencaoImpeditivo,
    NavigationDrawerManutencaoDetalhe,
    Avatar,
    DialogCancelarManutencao,
  },
  emits: ["reload"],
  props: ["manutencao", "detalhado"],
  data() {
    return {
      EnumStep,
      color: {
        FINALIZADO: "blue-grey",
        COBRANÇA: "indigo",
        "A PAGAR": "lime",
        REVISÃO: "amber",
        DATA: "green",
        EXECUÇÃO: "cyan",
      },
    };
  },
  methods: {
    gerarRelatorio(id) {
      const url = `/#/OS/${id}`;
      window.open(url, "_blank");
    },
    isLate(manutencao) {
      const executouServico = Boolean(manutencao?.executed_activity);
      const atrasado = moment().isAfter(manutencao?.expected_maintenance_date);
      if (!executouServico && atrasado) return true;
      return false;
    },
    onClick() {
      if (
        this.manutencao.maintenanceImpeditive?.filter((x) => x.active).length
      ) {
        this.$root.GlobalMessage.warning(
          "Essa O.S. possui bloqueios e por conta disso não será possivel dar continuidade a mesma"
        );
        return;
      }
      this.$refs.drawerManDetalhe.show(this.manutencao._id);
    },

    cancelShow() {
      return true;
    },
  },
};
</script>

<style scoped>
.FINALIZADO {
  border-left: 4px solid #607d8b !important;
}

.COBRANÇA {
  border-left: 4px solid #3f51b5 !important;
}

.PAGAR {
  border-left: 4px solid #cddc39 !important;
}

.REVISÃO {
  border-left: 4px solid #ffc107 !important;
}

.EXECUÇÃO {
  border-left: 4px solid #00bcd4 !important;
}

.DATA {
  border-left: 4px solid #4caf50 !important;
}

.CANCELADO {
  border-left: 4px solid #f44336 !important;
}

.display-dates {
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 24px;
  width: 100%;
}

.display-problems {
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 24px;
  width: 100%;
}

.display-title {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}
</style>
