<template>
  <div>
    <div class="display-title-page">
      <div class="ml-2">
        <div class="title mb-1">Listagem dos clientes mensalistas</div>
        <div class="caption mb-3">
          Listagem com todas os dados de manutenções dos clientes mensalistas.
        </div>
      </div>
      <div class="mr-2">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFormat"
              label="Selecione o mês"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker v-model="date" type="month" scrollable locale="br">
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="
                $refs.dialog.save(date);
                changeMonth(date);
              "
            >
              Selecionar
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
    </div>

    <v-row no-gutters>
      <v-col
        v-for="(cliente, index) in list"
        :key="index"
        class="my-1"
        cols="12"
        sm="12"
        md="3"
      >
        <CardMensalista
          class="mx-1"
          ref="cardMensalista"
          :clienteMensal="cliente"
          :desabilitaGerarCobranca="desabilitaGerarCobranca"
          @reload="reset"
        /> </v-col
    ></v-row>
  </div>
</template>

<script>
import { api } from "../../../../api";
import CardMensalista from "../card/CardMensalista.vue";

export default {
  name: "IziManTabMensalista",
  components: { CardMensalista },
  data() {
    return {
      date: new Date().toISOString().substr(0, 7),
      dateFormat: new Date().toISOString().substr(0, 7),
      modal: false,
      list: [],
      desabilitaGerarCobranca: true,
    };
  },
  methods: {
    async getItens() {
      const response = await api.get("/maintenanceMonthlyCustomer", {
        params: {
          dataFiltroAgrupamento: this.date,
        },
      });
      if (response.data) {
        this.list = this.list.concat(response.data);
      } else this.$root.GlobalMessage.error("Erro ao encontrar itens");
      this.disabledLoadMore = response.data.length < 8;
    },
    reset() {
      this.list = [];
      this.getItens();
    },
    changeMonth(date) {
      const dataAtual = new Date().toISOString().substr(0, 7);
      this.desabilitaGerarCobranca = date == dataAtual;
      this.formatDate();
      this.reset();
    },
    formatDate() {
      const [year, month] = this.date.split("-");
      this.dateFormat = `${month}/${year}`;
    },
  },
  beforeMount() {
    this.formatDate();
    this.getItens();
  },
};
</script>
