<template>
    <v-dialog v-model="dialog" max-width="500">
      <v-card v-if="Boolean(orcamento)">
        <v-card-title class="text-h6">
          Deseja realmente recusar esse orçamento?
        </v-card-title>
        <v-card-text class="pb-0">
          Ao realizar a recusa do orçamento
          <b>
            {{ orcamento.maintenance.number_os_format }}
          </b>
          do cliente <b>{{ orcamento.maintenance.client.fantasy_name }}</b
          >, não sera possivel reverter o processo.
  
          <v-form ref="form" v-model="valid" class="mt-4">
            <v-textarea
              clearable
              outlined
              name="input-7-4"
              label="Informe o motivo da recusa"
              v-model="form.fields.refused_motive"
              :rules="form.rules.refused_motiveRules"
            >
            </v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" block text @click="exec">
            Realizar recusa
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import  { api } from "../../../../api";
  import moment from "moment-timezone";
  
  export default {
    name: "IziManDialogRecusarOrcamento",
    data() {
      return {
        valid: true,
        dialog: false,
        orcamento: null,
        form: {
          fields: {
            refused_motive: null,
          },
          rules: {
            refused_motiveRules: [
              (v) =>
                !!v ||
                "Necessário preencher o campo de informar o motivo de recusar o orçamento",
            ],
          },
        },
      };
    },
  
    methods: {
      show(orcamento = null) {
        this.orcamento = orcamento;
        this.dialog = true;
      },
  
      close() {
        this.dialog = false;
      },
  
      async exec() {
        try {
          if (this.$refs.form.validate()) {
            const response = await api.put(
              `/maintenanceBudget/${this.orcamento._id}/refused`,
              {
                approved: false,
                refused_date: moment.tz("America/Sao_Paulo").toDate(),
                refused_motive: this.form.fields.refused_motive,
              }
            );
  
            if (response.data) {
              this.$root.GlobalMessage.success(
                "Orçamento recusado com sucesso!"
              );
              this.$emit("refused");
              this.close();
            } else this.$root.GlobalMessage.error("Erro ao recusar orçamento!");
          }
        } catch (err) {
          this.$root.GlobalMessage.error(
            "Erro ao realizar requisição, entre em contato com o administrador!"
          );
        }
      },
    },
  };
  </script>
  