<template>
    <div>{{ this.format(item) }}</div>
</template>
  
<script lang="ts">

export default {
    name: 'IziManAvatar',
    props: ['item'],
    data() {
        return {
            avatar: null
        }
    },
    methods: {
        format(value){
            const arr = value.split(' ');
            return arr[0][0] + arr[arr.length - 1][0];
        }
    }
}
</script>

<style scoped>
div{
    text-transform: uppercase;
    background-color: #ECEFF1;
    border-radius: 50%;
    padding: 4px;
    margin-right: 8px;
    width: 32px;
    height: 32px;
    line-height: 26px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
}
</style>