<template>
  <div class="mt-2">
    <v-form ref="form" v-model="valid">
      <v-timeline dense clipped>
        <v-timeline-item fill-dot class="white--text mb-8" large>
          <template v-slot:icon>
            <v-icon class="white--text">mdi-comment</v-icon>
          </template>
          <v-text-field
            v-model="form.fields.text"
            :rules="form.rules.textRules"
            hide-details
            flat
            label="Deixe um comentário"
            solo
            @keydown.enter="comment"
          >
            <template v-slot:append>
              <v-btn class="mx-0" depressed @click="comment"> Enviar </v-btn>
            </template>
          </v-text-field>
        </v-timeline-item>

        <v-slide-x-transition group>
          <v-timeline-item
            v-for="(event, index) in historico"
            :key="index"
            class="mb-4"
            color="grey"
            small
          >
            <div class="font-weight-normal">
              <strong>{{ event.system ? "Sistema" : event.user.name }}</strong>
              <small>
                - {{ event.created_at | moment("DD/MM/YYYY hh:mm") }}</small
              >
            </div>
            <div v-html="event.text"></div>
          </v-timeline-item>
        </v-slide-x-transition>
      </v-timeline>
    </v-form>
  </div>
</template>

<script>
import { api } from "../../../../api";
import moment from "moment-timezone";

export default {
  name: "IziManTabHistorico",
  props: ["id", "historico"],
  emits: ["comentarioInserido"],
  data() {
    return {
      valid: true,
      form: {
        fields: {
          text: null,
        },
        rules: {
          textRules: [
            (v) => !!v || "Necessário preencher o campo de comentário",
          ],
        },
      },
    };
  },
  methods: {
    async comment() {
      if (this.$refs.form.validate()) {
        const body = {
          ...this.form.fields,
          created_at: moment.tz("America/Sao_Paulo").toDate(),
        };

        const response = await api.put(`/maintenance/${this.id}/comment`, body);

        if (response.data) {
          this.$root.GlobalMessage.success("Comentário adicionado com sucesso");
          this.$refs.form.reset();
          this.$emit("comentarioInserido");
          // this.historico.push(response.data);
        } else this.$root.GlobalMessage.error("Erro ao cadastrar Usuário!");
      }
    },
  },
};
</script>
