<template>
  <v-form class="pt-2" ref="form" v-model="valid" @submit="cadastrar()">
    <v-row class="mt-2">
      <v-col class="py-0" cols="12" sm="6">
        <v-text-field
          v-model="form.fields.fantasy_name"
          :rules="form.rules.fantasy_nameRules"
          outlined
          label="Nome fantasia"
          required
        >
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="6">
        <v-text-field
          v-mask="'##.###.###/####-##'"
          v-model="form.fields.cnpj"
          :rules="form.rules.cnpjRules"
          outlined
          label="CNPJ"
          required
        >
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="6">
        <v-text-field
          v-model="form.fields.corporate_name"
          :rules="form.rules.corporate_nameRules"
          outlined
          label="Razão social"
          required
        >
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="6">
        <v-text-field
          v-model="form.fields.email"
          :rules="form.rules.emailRules"
          outlined
          label="E-mail"
          required
        >
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="8">
        <v-text-field
          v-model="form.fields.responsable_name"
          :rules="form.rules.responsable_nameRules"
          outlined
          label="Nome do responsável"
          required
        >
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="4">
        <v-text-field
          v-mask="'(##) #####-####'"
          v-model="form.fields.phone_number"
          :rules="form.rules.phone_numberRules"
          outlined
          label="Celular"
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="pt-0 mb-2" cols="12">
        <v-subheader>Endereço</v-subheader>

        <v-divider></v-divider>
      </v-col>

      <v-col class="py-0" cols="12" sm="4">
        <TextFieldCep
          ref="textFieldCep"
          @findAddress="alteraEndereco"
          :zip_code="form.fields.address.zip_code"
        />
      </v-col>
      <v-col class="py-0" cols="12" sm="8">
        <v-text-field
          v-model="form.fields.address.street"
          :rules="form.rules.streetRules"
          outlined
          label="Logradouro"
          required
          disabled
        >
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="3">
        <v-text-field
          v-model="form.fields.address.number"
          :rules="form.rules.numberRules"
          outlined
          label="Número"
          required
        >
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="3">
        <v-text-field
          v-model="form.fields.address.neighborhood"
          :rules="form.rules.neighborhoodRules"
          outlined
          label="Bairro"
          required
          disabled
        >
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="3">
        <v-text-field
          v-model="form.fields.address.city"
          :rules="form.rules.cityRules"
          outlined
          label="Cidade"
          required
          disabled
        >
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" sm="3">
        <v-text-field
          v-model="form.fields.address.state"
          :rules="form.rules.stateRules"
          outlined
          label="Estado"
          required
          disabled
        >
        </v-text-field>
      </v-col>
      <v-col class="py-0" cols="12">
        <v-textarea
          v-model="form.fields.address.complement"
          outlined
          label="Complemento"
        >
        </v-textarea>
      </v-col>
      <v-col cols="12" sm="12">
        <v-btn
          :loading="loading"
          :disabled="loading"
          @click="this.salvar"
          color="primary"
          outlined
          >Salvar</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import TextFieldCep from "@/components/TextFieldCep.vue";
import { api } from "../../../../api";

export default {
  name: "IziManFormularioGerenciarCliente",
  props: ["id"],
  data() {
    return {
      valid: true,
      loading: false,
      form: {
        fields: {
          _id: null,
          cnpj: "",
          fantasy_name: "",
          corporate_name: "",
          email: "",
          responsable_name: "",
          phone_number: "",
          address: {
            street: "",
            neighborhood: "",
            city: "",
            state: "",
            zip_code: "",
            number: "",
            complement: "",
          },
        },
        rules: {
          fantasy_nameRules: [
            (v) => !!v || "Necessário preencher o campo de nome fantasia",
          ],
          responsable_nameRules: [
            (v) => !!v || "Necessário preencher o campo de responsável",
          ],
          phone_numberRules: [
            (v) =>
              //eslint-disable-next-line
              !!/^\([1-9]\d\)\s9?\d{4}-\d{4}$/.test(v) || "Celular inválido",
          ],
          streetRules: [
            (v) => !!v || "Necessário preencher o campo de logradouro",
          ],
          neighborhoodRules: [
            (v) => !!v || "Necessário preencher o campo de bairro",
          ],
          cityRules: [(v) => !!v || "Necessário preencher o campo de cidade"],
          stateRules: [(v) => !!v || "Necessário preencher o campo de estado"],
          zip_codeRules: [(v) => !!v || "Necessário preencher o campo de CEP"],
          numberRules: [(v) => !!v || "Número requerido"],
        },
      },
    };
  },
  methods: {
    async getClient(id) {
      const response = await api.get(`client/${id}`);
      if (response.data) {
        this.form.fields = response.data;
      } else this.$refs.GlobalMessage.error("Erro ao encontrar clientes");
    },
    salvar() {
      if (this.$refs.form.validate()) {
        this.$emit("salvar", this.form.fields);
      }
    },
    alteraEndereco(value) {
      this.form.fields.address = Object.assign({}, value);
    },
    resetaZipCode() {
      this.form.fields.address.zip_code = null;
    },
  },
  watch: {
    id(value) {
      if (value) this.getClient(value);
    },
  },
  components: { TextFieldCep },
};
</script>
