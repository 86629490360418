<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-currency-field
        outlined
        label="Visita"
        prepend-inner-icon="mdi-currency-usd"
        v-model="form.fields.values.visit"
        :rules="form.rules.visitRules"
      ></v-currency-field>
      <v-currency-field
        outlined
        label="Mão de Obra"
        prepend-inner-icon="mdi-currency-usd"
        v-model="form.fields.values.labour"
        :rules="form.rules.labourRules"
      ></v-currency-field>
      <v-currency-field
        outlined
        label="Despesas"
        prepend-inner-icon="mdi-currency-usd"
        v-model="form.fields.values.expenses"
        :rules="form.rules.expensesRules"
      ></v-currency-field>
      <v-currency-field
        outlined
        label="Materiais"
        prepend-inner-icon="mdi-currency-usd"
        v-model="form.fields.values.material"
        :rules="form.rules.materialRules"
      ></v-currency-field>
      <v-currency-field
        outlined
        label="Desconto"
        prepend-inner-icon="mdi-currency-usd"
        v-model="form.fields.values.discount"
        :rules="form.rules.discountRules"
      ></v-currency-field>
      <v-currency-field
        outlined
        label="Total"
        prepend-inner-icon="mdi-currency-usd"
        v-model="form.fields.values.total"
        :rules="form.rules.totalRules"
        disabled
      ></v-currency-field>

      <v-btn color="primary" outlined @click="this.next">
        Avançar
      </v-btn>
      <v-btn text @click="$emit('before')"> Voltar </v-btn>
    </v-form>
  </div>
</template>

<script lang="ts">
export default {
  name: "IziManFormularioValores",
  data() {
    return {
      valid: true,
      form: {
        fields: {
          values: {
            visit: 0,
            labour: 0,
            expenses: 0,
            material: 0,
            discount: 0,
            total: 0,
          },
        },
        rules: {
          visitRules: [(v) => !!v || "Necessário preencher o campo de visita"],
          labourRules: [
            (v) => !!v || "Necessário preencher o campo de mão de obra",
          ],
          expensesRules: [
            (v) => !!v || "Necessário preencher o campo de despesas",
          ],
          materialRules: [
            (v) => !!v || "Necessário preencher o campo de material",
          ],
          discountRules: [
            (v) => !!v || "Necessário preencher o campo de desconto",
          ],
          totalRules: [(v) => !!v || "Necessário preencher o campo de total"],
        },
      },
    };
  },
  methods: {
    calc() {
      this.form.fields.values.total =
        this.form.fields.values.visit +
        this.form.fields.values.labour +
        this.form.fields.values.expenses +
        this.form.fields.values.material -
        this.form.fields.values.discount;
    },
    next() {
      if (this.$refs.form.validate()) {
        this.$emit("next", this.form.fields);
      }
    },

    getItemValues(item) {
      const valor = item?.reduce((accumalator, currentValue) => {
        const valor = currentValue.value * currentValue.quantity;
        return accumalator + valor;
      }, 0);

      this.form.fields.values.material = valor;
    },
  },
  mounted() {
    this.$watch(
      (vm) => [
        vm.form.fields.values.visit,
        vm.form.fields.values.labour,
        vm.form.fields.values.expenses,
        vm.form.fields.values.material,
        vm.form.fields.values.discount,
      ],
      this.calc
    );
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
