<template>
  <div>
    <v-card>
      <div class="pt-2 text-center">
        <div class="item-avatar my-2">
          <v-img
            src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745"
            max-height="60"
            max-width="60"
          >
          </v-img>
        </div>

        <span class="text-overline">
          {{ clienteMensal.client.fantasy_name }}
        </span>
      </div>
      <v-divider></v-divider>
      <v-card-text class="pb-0">
        <p>
          Quantidade de manutenções:
          <v-btn
            class="ml-2 pa-0"
            small
            color="blue"
            @click="$refs.bottomSheetDetalheManutencoes.show()"
          >
            {{ clienteMensal.maintenances.length }}
          </v-btn>
        </p>
        <p>
          Valor total das manutenções:
          <b> {{ formatValue(clienteMensal.maintenances) | currency }}</b>
        </p>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions class="mt-1 pa-2" v-if="!clienteMensal.generated_charge">
        <v-row>
          <v-col cols="12" sm="12">
            <v-btn
              :disabled="desabilitaGerarCobranca"
              outlined
              block
              color="success"
              @click="
                $refs.navigationDrawerCobrancaMensalista.show(
                  clienteMensal,
                  formatValue(clienteMensal.maintenances)
                )
              "
              >Gerar cobrança</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>

      <v-card-text class="pb-1" v-else>
        <p class="mb-0">
          Cobrança gerada por:
          <b>{{ clienteMensal.generated_charge_by.name }}</b>
        </p>
        <p>
          Cobrança em:
          <b>{{
            clienteMensal.generated_charge_date | moment("DD/MM/YYYY hh:mm")
          }}</b>
        </p>
      </v-card-text>
    </v-card>

    <BottomSheetDetalheManutencoes
      ref="bottomSheetDetalheManutencoes"
      :maintenances="clienteMensal.maintenances"
    />

    <NavigationDrawerCobrancaMensalista
      ref="navigationDrawerCobrancaMensalista"
      @charge="$emit('reload')"
    />
  </div>
</template>

<script lang="ts">
import BottomSheetDetalheManutencoes from "../bottomSheet/BottomSheetDetalheManutencoes.vue";
import NavigationDrawerCobrancaMensalista from "../navigationDrawer/NavigationDrawerCobrancaMensalista.vue";

export default {
  name: "IziManCardMensalista",
  components: {
    BottomSheetDetalheManutencoes,
    NavigationDrawerCobrancaMensalista,
  },
  emits: ["reload"],
  props: ["clienteMensal", "desabilitaGerarCobranca"],
  data() {
    return {};
  },
  methods: {
    formatValue(maintenances) {
      return maintenances.reduce(
        (accumulator, maintenance) => accumulator + maintenance.values.total,
        0
      );
    },
  },
};
</script>

<style scoped>
.item-avatar {
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
</style>
