<template>
    <div>
        <v-card :hover="true" v-if="manutencao" :class="[manutencao.step]"
        @click="$refs.navigationDrawerCobranca.show(manutencao._id)">
            <div class="ml-4 pt-2 bloco-identificacao">
                <span class="text-overline">
                    {{ manutencao.number_os_format }}
                </span>

                <div class="float-right mr-4" v-if="!manutencao.cancelled">

                </div>
            </div>

            <v-card-title class="text-truncate pt-0 pb-0 display-title">
                <span>
                    {{ manutencao.client.fantasy_name }}
                </span>
                <div>
                    <v-chip class="my-1 ml-1" small color="primary" outlined label>
                        {{ manutencao.service_type }}
                    </v-chip>
                    <v-chip class="my-1 ml-1" small :color="this.color[manutencao.step]" outlined label>
                        {{ manutencao.step }}
                    </v-chip>
                </div>
            </v-card-title>

            <v-card-text>

                <p class="mt-4 mb-2 display-dates">
                    <span>
                        <b>Data Criação:</b><br />
                        {{ manutencao.created_at | moment("DD/MM/YYYY hh:mm") }}
                    </span>
                    <span v-if="Boolean(manutencao?.expected_maintenance_date)">

                        <b>Data Prevista Manuteção:</b><br />
                        {{
                            $moment(manutencao.expected_maintenance_date).format(
                                "DD/MM/YYYY hh:mm"
                            )
                        }}
                    </span>

                    <span>
                        <b class="mr-1">Manuteção Realizada:</b><br />
                        {{ manutencao.maintenance_date | moment("DD/MM/YYYY hh:mm") }}
                    </span>
                </p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="pb-0">
                <p class="display-dates">
                    <span>
                        <b>Visita</b><br />
                        {{ manutencao.values.visit | currency }}
                    </span>
                    <span>
                        <b>Mão de obra</b><br />
                        {{ manutencao.values.labour | currency }}
                    </span>
                    <span>
                        <b>Despesas</b><br />
                        {{ manutencao.values.expenses | currency }}
                    </span>
                    <span>
                        <b>Materiais</b><br />
                        {{ manutencao.values.material | currency }}
                    </span>
                    <span>
                        <b>Desconto</b><br />
                        {{ manutencao.values.discount | currency }}
                    </span>
                    <span>
                        <b>Total</b><br />
                        {{ manutencao.values.total | currency }}
                    </span>
                </p>
            </v-card-text>


        </v-card>

        <NavigationDrawerCobranca ref="navigationDrawerCobranca" @charge="$emit('reload')" />
    </div>
</template>
  
<script lang="ts">
import EnumStep from "../../../../enums/StepManutencao";
import NavigationDrawerCobranca from "../navigationDrawer/NavigationDrawerCobranca.vue";

export default {
    name: "IziManCardManutencaoCobranca",
    components: {
        NavigationDrawerCobranca
    },
    emits: ["reload"],
    props: ["manutencao"],
    data() {
        return {
            EnumStep,
            color: {
                FINALIZADO: "blue-grey",
                COBRANÇA: "indigo",
                "A PAGAR": "lime",
                REVISÃO: "amber",
                DATA: "green",
                EXECUÇÃO: "cyan",
            },
        };
    },
    methods: {

    },
};
</script>
  
<style scoped>
.FINALIZADO {
    border-left: 4px solid #607d8b !important;
}

.COBRANÇA {
    border-left: 4px solid #3f51b5 !important;
}

.PAGAR {
    border-left: 4px solid #cddc39 !important;
}

.REVISÃO {
    border-left: 4px solid #ffc107 !important;
}

.EXECUÇÃO {
    border-left: 4px solid #00bcd4 !important;
}

.DATA {
    border-left: 4px solid #4caf50 !important;
}

.CANCELADO {
    border-left: 4px solid #f44336 !important;
}

.display-dates {
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 24px;
    width: 100%;
}

.display-values {
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
}

.display-title {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}
</style>
  