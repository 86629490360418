<template>
  <div>
    <div class="display-title-page">
      <div class="ml-2">
        <div class="title mb-1">Calendario de manutenções</div>
        <div class="caption mb-3">
          Listagem com todas as manutenções que irão fazer parte do seu dia a
          dia
        </div>
      </div>
      <div class="mr-2">
        <v-btn
          raised
          color="primary"
          class="ml-1"
          @click="$refs.navigationDrawerManutencaoCriar.show()"
          ><v-icon left>mdi-plus</v-icon>Gerar O.S.</v-btn
        >
      </div>
    </div>
    <v-row>
      <v-col cols="12" sm="12" md="3">
        <!-- <v-card class="pa-2 mb-2">
          <div class="subtitle mb-4">Filtros</div>

          <span class="body-2">Etapa</span>
          <v-chip-group
            v-model="stepsValue"
            active-class="primary--text"
            multiple
            column
          >
            <v-chip small v-for="step in steps" :key="step">
              {{ step }}
            </v-chip>
          </v-chip-group>
        </v-card> -->
        <v-card class="pa-2">
          <div class="subtitle mb-4">Legendas</div>
          <v-divider class="mb-4"></v-divider>
          <div>
            <p v-for="(data, name) in color" :key="name" class="body-2">
              <v-icon small :color="data">mdi-circle</v-icon> {{ name }}
            </p>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="9">
        <v-card>
          <v-sheet tile height="54" class="d-flex">
            <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon class="ma-2" @click="$refs.calendar.next()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn outlined class="ma-2" color="primary"> Hoje </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title v-if="$refs.calendar" class="mt-2">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn-toggle
              v-model="type"
              tile
              color="deep-purple accent-3"
              group
            >
              <v-btn v-for="(data, i) in types" :key="i" :value="data.value">
                {{ data.label }}
              </v-btn>
            </v-btn-toggle>
          </v-sheet>
          <v-sheet height="700">
            <v-calendar
              ref="calendar"
              v-model="value"
              :weekdays="weekday"
              :type="type"
              :events="events"
              :interval-height="70"
              :key="key"
              color="primary"
              :event-color="getEventColor"
              @change="getEvents"
              @click:event="openMaintenanceDetail"
            >
              <template v-slot:event="{ event }">
                <div class="pa-1">
                  <div class="text-truncate font-weight-bold">
                    {{ event.number_os }}
                  </div>
                  <div class="text-truncate font-weight-bold mb-1">
                    {{ event.name }}
                  </div>
                  <div class="text-truncate">{{ event.detail }}</div>
                </div>
              </template>
            </v-calendar>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <NavigationDrawerManutencaoDetalhe
      ref="navigationDrawerManutencaoDetalhe"
      @reload="key++"
    />
    <NavigationDrawerManutencaoCriar
      ref="navigationDrawerManutencaoCriar"
      @reload="key++"
    />
  </div>
</template>

<script lang="ts">
import { api } from "../../api";
import moment from "moment-timezone";
import NavigationDrawerManutencaoDetalhe from "./components/navigationDrawer/NavigationDrawerManutencaoDetalhe.vue";
import NavigationDrawerManutencaoCriar from "./components/navigationDrawer/NavigationDrawerManutencaoCriar.vue";

export default {
  name: "IziManManutencaoCalendario",
  title: "Calendário de manutenções",
  data: () => ({
    value: "",
    type: "week",
    steps: ["A PAGAR", "COBRANÇA", "DATA", "EXECUÇÃO", "FINALIZADO", "REVISÃO"],
    stepsValue: [
      "A PAGAR",
      "COBRANÇA",
      "DATA",
      "EXECUÇÃO",
      "FINALIZADO",
      "REVISÃO",
    ],
    color: {
      "A PAGAR": "lime",
      COBRANÇA: "indigo",
      DATA: "green",
      EXECUÇÃO: "cyan",
      FINALIZADO: "blue-grey",
      REVISÃO: "amber",
      CANCELADO: "red",
    },
    types: [
      {
        label: "Dia",
        value: "day",
      },
      {
        label: "Semana",
        value: "week",
      },
      {
        label: "Mês",
        value: "month",
      },
      {
        label: "4 Dias",
        value: "4day",
      },
    ],
    mode: "column",
    weekday: [0, 1, 2, 3, 4, 5, 6],
    events: [],
    key: 0,
  }),
  methods: {
    async getEvents({ start, end }) {
      const startDate = `${start.date} 00:00:00`;
      const endDate = `${end.date} 23:59:59`;
      const response = await api.get("/maintenance", {
        params: { start: new Date(startDate), end: new Date(endDate) },
      });
      if (response.data) this.events = this.mapResponse(response.data);
    },
    mapResponse(maintenances) {
      return maintenances.map((maintenance) => ({
        number_os: maintenance.number_os_format,
        name: maintenance.client.fantasy_name,
        detail: maintenance.issue_reported,
        start: new Date(maintenance.expected_maintenance_date),
        end: new Date(
          moment(maintenance.expected_maintenance_date).add(1, "hours")
        ),
        timed: true,
        color: maintenance.cancelled ? "red" : this.color[maintenance.step],
        id: maintenance._id,
      }));
    },
    getEventColor(event) {
      return event.color;
    },
    openMaintenanceDetail(card) {
      this.$refs.navigationDrawerManutencaoDetalhe.show(card.event.id);
    },
  },
  mounted() {
    this.$refs.calendar.scrollToTime("07:00");
  },
  components: {
    NavigationDrawerManutencaoDetalhe,
    NavigationDrawerManutencaoCriar,
  },
};
</script>

<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}

.display-title-page {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>
