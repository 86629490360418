<template>
  <v-navigation-drawer
    v-model="drawer"
    right
    app
    temporary
    width="600"
    class="pa-4"
    @input="onClose"
  >
    <p class="title" @click="close()">
      Listagem de impeditivos
      <v-btn icon class="float-right">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </p>
    <v-divider class="mb-4"></v-divider>

    <v-form ref="form" v-model="valid" class="mb-4">
      <v-textarea
        clearable
        outlined
        label="Informe o que está impedindo essa manutenção de prosseguir"
        v-model="form.fields.description"
        :rules="form.rules.descriptionRules"
      >
      </v-textarea>

      <v-btn color="primary" outlined @click="save"> Salvar </v-btn>
    </v-form>

    <v-card class="mt-4" v-if="!!impeditives?.length">
      <v-list subheader>
        <v-list-item
          v-for="impeditive in impeditives"
          :key="impeditive._id"
          class="mt-2"
        >
          <v-list-item-content>
            <v-list-item-title
              v-text="impeditive.active ? 'Bloqueado' : 'Liberado'"
            ></v-list-item-title>
            <v-list-item-subtitle>
              {{ impeditive.description }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-icon>
            <v-btn
              icon
              v-if="impeditive.active"
              color="red"
              @click="release(impeditive._id)"
            >
              <v-icon> mdi-lock </v-icon>
            </v-btn>
            <v-btn icon v-else color="green" @click="block(impeditive._id)">
              <v-icon> mdi-lock-open </v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card>
  </v-navigation-drawer>
</template>

<script lang="ts">
import  { api } from "../../../../api";

export default {
  name: "IziManNavigationDrawerManutencaoImpeditivo",
  data() {
    return {
      valid: false,
      dirty: false,
      drawer: false,
      maintenanceId: null,
      impeditives: null,
      form: {
        fields: {
          description: null,
          active: true,
        },
        rules: {
          descriptionRules: [
            (v) => !!v || "Necessário preencher o campo de Impedimento",
          ],
        },
      },
    };
  },
  methods: {
    show(id) {
      this.maintenanceId = id;
      this.drawer = true;
      this.getImpeditives();
    },

    close() {
      this.drawer = false;
    },

    onClose(event) {
      !event && this.$refs.form.reset();
      this.dirty && this.$emit('save');
    },

    async getImpeditives() {
      const response = await api.get(`/maintenanceImpeditive`, {
        params: { maintenanceId: this.maintenanceId },
      });

      this.impeditives = response.data;
    },

    async save() {
      if (this.$refs.form.validate()) {
        const response = await api.post("/maintenanceImpeditive", {
          ...this.form.fields,
          maintenance: this.maintenanceId,
        });

        if (response.data) {
          this.$root.GlobalMessage.success("Operação realizada com sucesso!");
          this.$emit('save');
          this.$refs.form.reset();

          this.getImpeditives();
        }
      }
    },

    async release(id) {
      const response = await api.put(`/maintenanceImpeditive/${id}/release`);

      if (response.data) {
        this.dirty = true;
        this.$root.GlobalMessage.success("Operação realizada com sucesso!");

        this.getImpeditives();
      }
    },

    async block(id) {
      const response = await api.put(`/maintenanceImpeditive/${id}/block`);

      if (response.data) {
        this.dirty = true;
        this.$root.GlobalMessage.success("Operação realizada com sucesso!");

        this.getImpeditives();
      }
    },
  },
};
</script>

<style scoped></style>
