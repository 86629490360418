<template>
  <div>
    <div class="display-title-page">
      <div class="ml-2">
        <div class="title mb-1">
          Listagem dos orçamentos pendente de aprovação
        </div>
        <div class="caption mb-3">
          Listagem com todos os orçamentos, aqui será possivel a partir da
          aprovação de um orçamento gerar uma O.S. pré definida do que irá ser
          feito.
        </div>
      </div>
      <!-- <div class="mr-2">
        <v-btn raised color="primary" class="ml-1" @click="$refs.drawerManCriar.show()"><v-icon
            left>mdi-plus</v-icon>Gerar O.S.</v-btn>
      </div> -->
    </div>

    <v-row no-gutters>
      <v-col
        v-for="(orcamento, index) in list"
        :key="index"
        class="my-1"
        cols="12"
        sm="12"
        md="4"
      >
        <CardOrcamento :orcamento="orcamento" @reload="reset" class="mx-1" />
      </v-col>
    </v-row>

    <!-- <v-skeleton-loader
      v-if="loading"
      v-bind="attrs"
      type="table-cell, heading, table-row-divider, paragraph, table-row-divider, table-cell"
    ></v-skeleton-loader> -->

    <div v-if="!loading && !list.length" class="text-center mt-2">
      Nenhum dado encontrado
    </div>

    <!-- 
    <div class="text-center mt-2">
      <v-btn color="primary" outlined :disabled="disabledLoadMore" @click="this.loadMore">Carregar mais</v-btn>
    </div> -->
  </div>
</template>

<script>
import CardOrcamento from "./components/card/CardOrcamento.vue";
import  { api } from "../../api";

export default {
  name: "IziManManutencaoOrcamento",
  title: "Listagem de orçamentos",
  components: { CardOrcamento },
  data() {
    return {
      list: [],
      loading: false,
    };
  },
  methods: {
    async getItens() {
      this.loading = true;
      const response = await api.get("/maintenanceBudget");

      if (response.data) {
        this.list = this.list.concat(response.data);
      } else this.$root.GlobalMessage.error("Erro ao encontrar itens");

      this.disabledLoadMore = response.data.length < 8;
      this.loading = false;
    },

    reset() {
      this.list = [];

      this.getItens();
    },
  },
  beforeMount() {
    this.getItens();
  },
};
</script>
