<template>
  <v-navigation-drawer
    v-model="drawer"
    :absolute="this.$device.mobile"
    :temporary="this.$device.mobile"
    :fixed="!this.$device.mobile"
    app
  >
    <v-list style="padding-top: 0">
      <v-list-item>
        <v-list-item-content>
          <img src="../assets/restec.png" width="100%" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <v-subheader>MENU</v-subheader>
      <v-list-item-group v-model="selectedItem" color="primary">
        <template v-for="(item, i) in items">
          <v-list-item :key="i" :to="item.route" v-if="!item.children">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            :value="false"
            :key="i"
            :prepend-icon="item.icon"
            v-if="item.children"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </template>

            <v-list-item
              class="ml-4"
              v-for="(child, childIndex) in item.children"
              :key="childIndex"
              :to="child.route"
            >
              <v-list-item-icon>
                <v-icon v-text="child.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="child.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
export default {
  name: "IziManBarraLateral",
  data: () => ({
    drawer: false,
    group: null,
    selectedItem: 0,
    items: [
      { text: "Página inicial", icon: "mdi-home", route: "/pagina-inicial" },
      {
        text: "Manutenção",
        icon: "mdi-hammer-screwdriver",
        route: "",
        children: [
          {
            text: "Dashboard",
            icon: "mdi-view-dashboard",
            route: "/manutencao-dashboard",
          },
          {
            text: "Calendário",
            icon: "mdi-calendar",
            route: "/manutencao-calendario",
          },
          {
            text: "Listagem",
            icon: "mdi-list-box",
            route: "/manutencao-listagem",
          },
          {
            text: "Orçamento",
            icon: "mdi-archive-clock-outline",
            route: "/manutencao-orcamento",
          },
        ],
      },
      {
        text: "Financeiro",
        icon: "mdi-currency-usd",
        route: "",
        children: [
          { text: "Dashboard", icon: "mdi-chart-bar", route: "/financeiro" },
          { text: "Cobrança", icon: "mdi-cash-multiple", route: "/cobrancas" },
          {
            text: "Livro Caixa",
            icon: "mdi-cash-register",
            route: "/livro-caixa",
          },
        ],
      },
      { text: "Cliente", icon: "mdi-account-group", route: "/cliente" },
      { text: "Peça", icon: "mdi-briefcase-variant", route: "/peca" },
      {
        text: "Equipamento",
        icon: "mdi-file-table-box",
        route: "/equipamento",
      },
      {
        text: "Relatório",
        icon: "mdi-chart-bar-stacked",
        route: "",
        children: [
          {
            text: "Manutenção",
            icon: "mdi-hammer-screwdriver",
            route: "/relatorio/manutencao",
          },
          {
            text: "Financeiro",
            icon: "mdi-currency-usd",
            route: "/relatorio/financeiro",
          },
        ],
      },
      {
        text: "Configuração",
        icon: "mdi-cogs",
        route: "",
        children: [
          { text: "Usuário", icon: "mdi-account", route: "/config/usuario" },
          {
            text: "Perfil",
            icon: "mdi-face-man-profile",
            route: "/config/perfil",
          },
          {
            text: "Auxiliar",
            icon: "mdi-credit-card-chip",
            route: "/config/auxiliar",
          },
        ],
      },
    ],
  }),
  methods: {
    open() {
      this.drawer = true;
    },
  },
  mounted() {
    this.drawer = !this.$device.mobile;
  },
};
</script>
