<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="3">
        <v-card
          class="mx-1 mt-1"
          :loading="pageData.quantidade.preventiva === null"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">Preventiva</div>
              <v-list-item-title class="text-h5 mb-1">
                {{ pageData.quantidade.preventiva }}
              </v-list-item-title>
              <v-list-item-subtitle>Quantidade pendente</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar size="100" color="primary" rounded>
              <v-avatar size="100" rounded>
                <v-icon dark size="50"> mdi-cog-counterclockwise </v-icon>
              </v-avatar>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card
          class="mx-1 mt-1"
          :loading="pageData.quantidade.corretiva === null"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">Corretiva</div>
              <v-list-item-title class="text-h5 mb-1">
                {{ pageData.quantidade.corretiva }}
              </v-list-item-title>
              <v-list-item-subtitle>Quantidade pendente</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar size="100" color="primary" rounded>
              <v-avatar size="100">
                <v-icon dark size="50"> mdi-cog-clockwise </v-icon>
              </v-avatar>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card
          class="mx-1 mt-1"
          :loading="pageData.quantidade.orcamento === null"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">Orçamento</div>
              <v-list-item-title class="text-h5 mb-1">
                {{ pageData.quantidade.orcamento ?? 0 }}
              </v-list-item-title>
              <v-list-item-subtitle>Quantidade pendente</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar size="100" color="primary" rounded>
              <v-avatar size="100" rounded>
                <v-icon dark size="50"> mdi-cash-clock </v-icon>
              </v-avatar>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card
          class="mx-1 mt-1"
          :loading="pageData.quantidade.garantia === null"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">Garantia</div>
              <v-list-item-title class="text-h5 mb-1">
                {{ pageData.quantidade.garantia ?? 0 }}
              </v-list-item-title>
              <v-list-item-subtitle>Quantidade pendente</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar size="100" color="primary" rounded>
              <v-avatar size="100">
                <v-icon dark size="50"> mdi-cash-remove </v-icon>
              </v-avatar>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" sm="12" md="4">
        <v-card class="pa-2 mt-2 mx-1">
          <EquipamentoChart />
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-card class="pa-2 mt-2 mx-1">
          <TipoServicoChart />
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card class="mx-1 mt-2" :loading="pageData.manutencoes === null">
          <v-img
            height="200px"
            src="https://www.mmtec.com.br/wp-content/uploads/2018/03/mmtec0703-1080x675.jpg"
            gradient="to top right, rgba(0,0,0, 1), rgba(25,32,72,.1)"
          >
            <v-app-bar flat color="rgba(0, 0, 0, 0)">
              <v-toolbar-title class="text-h6 white--text pl-0">
                Manutenções
              </v-toolbar-title>

              <v-spacer></v-spacer>
            </v-app-bar>

            <v-card-title class="white--text mt-8">
              <v-avatar size="56" color="primary" rounded>
                <v-icon dark> mdi-calendar </v-icon>
              </v-avatar>
              <p class="ml-3">Marcelo de Freitas</p>
            </v-card-title>
          </v-img>

          <v-card-text v-if="!!pageData.manutencoes?.length">
            <div class="font-weight-bold ml-8 mb-2">Hoje</div>

            <v-timeline align-top dense>
              <v-timeline-item
                v-for="manutencao in pageData.manutencoes"
                :key="manutencao._id"
                :color="findColor(manutencao)"
                small
              >
                <div>
                  <div class="font-weight-normal">
                    <strong>{{ manutencao.client.fantasy_name }}</strong> -
                    {{ manutencao.client.address.street }}
                  </div>
                  <div>{{ manutencao.issue_reported }}</div>
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>

          <v-card-text v-if="!pageData.manutencoes?.length">
            <div class="text-center">Nenhuma manutenção para hoje</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <TabelaManutencao class="mx-1 mt-2" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import EnumStep from "../../enums/StepManutencao";
import { api } from "../../api";
import moment from "moment-timezone";
// import ListaCobranca from "@/components/ListaCobranca.vue";
import EquipamentoChart from "./components/charts/EquipamentoChart.vue";
import TipoServicoChart from "./components/charts/ClienteChart.vue";
import TabelaManutencao from "./components/tables/TabelaManutencao.vue";

export default {
  name: "IziManManutencaoDashboard",
  title: "Dashboard de manutenção",
  components: { EquipamentoChart, TipoServicoChart, TabelaManutencao },
  data: () => ({
    queries: {
      queryMaintenance: {
        step: [
          EnumStep.PENDENTE_EXECUCAO,
          EnumStep.FINALIZADO,
          EnumStep.PENDENTE_REVISAO,
          EnumStep.PENDENTE_PAGAMENTO,
        ],
        expected_maintenance_date: new Date(),
      },
    },
    pageData: {
      quantidade: {
        preventiva: null,
        corretiva: null,
        orcamento: null,
        garantia: null,
      },
      manutencoes: null,
      charges: null,
    },
  }),
  methods: {
    async populateAllFields() {
      this.countPreventiva();
      this.countCorretiva();
      this.countOrcamento();
      this.countGarantia();
      this.manutencoes();
      this.charges();
    },

    findColor(manutencao) {
      if (manutencao.cancelled) return "red";

      return manutencao.step === EnumStep.PENDENTE_EXECUCAO ? "grey" : "green";
    },

    async countPreventiva() {
      const preventivaCount = await api.get("/maintenanceCount", {
        params: {
          service_type: "PREVENTIVA",
          pending: true,
          notCancelled: true,
        },
      });
      this.pageData.quantidade.preventiva = preventivaCount.data;
    },

    async countCorretiva() {
      const corretivaCount = await api.get("/maintenanceCount", {
        params: {
          service_type: "CORRETIVA",
          pending: true,
          notCancelled: true,
        },
      });
      this.pageData.quantidade.corretiva = corretivaCount.data;
    },

    async countOrcamento() {
      const corretivaCount = await api.get("/maintenanceCount", {
        params: {
          service_type: "ORÇAMENTO",
          pending: true,
          notCancelled: true,
        },
      });
      this.pageData.quantidade.orcamento = corretivaCount.data;
    },

    async countGarantia() {
      const corretivaCount = await api.get("/maintenanceCount", {
        params: {
          service_type: "GARANTIA",
          pending: true,
          notCancelled: true,
        },
      });
      this.pageData.quantidade.garantia = corretivaCount.data;
    },

    async manutencoes() {
      const manutencoes = await api.get("/maintenance", {
        params: { ...this.queries.queryMaintenance },
      });
      this.pageData.manutencoes = manutencoes.data;
    },

    async charges() {
      const charges = await api.get("/charge", {
        params: {
          payed: false,
          cancelled: false,
        },
      });

      this.pageData.charges = charges?.data
        .map((charge) => {
          const isLate = moment().isAfter(charge.payment_date);
          return { ...charge, isLate };
        })
        .sort((a, b) => Number(b.isLate) - Number(a.isLate));
    },

    reset() {
      this.pageData = {
        quantidade: {
          preventiva: null,
          corretiva: null,
          haPagar: null,
          atrasado: null,
        },
        manutencoes: null,
        charges: null,
      };
      this.populateAllFields();
    },

    resetFinanceiro() {
      this.pageData.quantidade.haPagar = null;
      this.pageData.quantidade.atrasado = null;
      this.pageData.charges = null;

      this.countHaPagar();
      this.countAtrasado();
      this.charges();
    },

    resetManutencao() {
      this.pageData.quantidade.preventiva = null;
      this.pageData.quantidade.corretiva = null;
      this.pageData.manutencoes = null;

      this.countPreventiva();
      this.countCorretiva();
      this.manutencoes();
    },
  },
  beforeMount() {
    this.populateAllFields();
  },
};
</script>
